
import * as React from "react";
import {Table} from "react-bootstrap";

export const AbdomentexaminationPreview = (props: any)=>{
  const {record, updateCb, fieldIndex, recordIndex} = props;

  const {
    feel,
    superficialPalpation,
    superficialPalpationComments,
    deepPalpation,
    deepPalpationComments,
    hernialOrifices,
    bowelSounds,
    bowelSoundsType,
    abnormalSounds,
    abnormalSoundsComments,
    comments,
  } = record;

  return <div className={'general-examination general-examination-preview'}>
      <div className={'field-row-preview-row'}>
        <table>
          <tr>
            <td colSpan={2}>
              <span>Superficial palpation</span>
              <br/>
              {feel || '-'}
            </td>
            <td colSpan={2}>
              <span>Superficial palpation comments</span>
              <br/>
             {superficialPalpationComments || '-'}
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <span>Deep palpation :  organomegaly</span>
              <br/>
             {deepPalpation || '-'}
            </td>
            <td colSpan={2}>
              <span>Deep palpation comments</span>
              <br/>
             {deepPalpationComments || '-'}
            </td>
          </tr>
          <tr>

            <td colSpan={2}>
              <span>Abnormal sounds </span>
              <br/>
             {abnormalSounds || '-'}
            </td >
              <td colSpan={2}>
              <span>Abnormal sounds comments</span>
              <br/>
             {abnormalSoundsComments || '-'}
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <span>Bowel sounds</span>
              <br/>
             {bowelSounds || '-'} {bowelSoundsType ? <>{' :' + bowelSoundsType }</> : null}
            </td>
            <td colSpan={2}>
              <span>Comments</span>
              <br/>
             {comments || '-'}
            </td>
          </tr>
        </table>
      </div>

  </div>
}
