import moment from 'moment';

export const ServiceGrouping  =  (bill?: any) => {
  const grouping: any = {};
  let results: any = [];
  let counter = 1;
  bill.invoices.forEach((invoice: any, invoiceIndex: number) => {
    invoice.items.forEach((el: any, index: number) => {
      const totalAmountAfterDiscount =
        validateNumber(el?.cost) * validateNumber(el?.unit) -
        validateNumber(el?.discount);
      const totalAmountWithTax =
        totalAmountAfterDiscount +
        (validateNumber(el?.tax) / 100) * totalAmountAfterDiscount;
      if (!grouping[el?.type]) {
        grouping['NA'] = 0;
      }
      grouping[el?.type || 'NA'] +=  Number(totalAmountWithTax);
    })

  });

  Object.keys(grouping).map((key: string) => {
    results.push({
      sno: counter,
      type: key.toUpperCase(),
      cost: grouping[key],
    });
    counter +=1;
  });

  return results;
}


export const TransactionDateWiseBill = (bill: any) => {
  let results: any = [];
  let counter = 1;
  bill.invoices.forEach((invoice: any, invoiceIndex: number) => {
    if (invoice.isOnlyPayment || invoice.isDiscount) {
      results.push({
        sno: counter,
        date:  moment((invoice?.date)).format('DD-MM-YYYY & HH:mm a') || '-',
        group: invoice.isOnlyPayment ? 'Payment' : 'Discount',
        amount: invoice.isDiscount ? bill.discount?.discountValue  :  '-',
        paid: invoice.isOnlyPayment ? invoice.totalPaid : invoice.discount?.discountValue,
      });
    } else {
      invoice.items.forEach((el: any, index: number) => {
        const totalAmountAfterDiscount =
          validateNumber(el?.cost) * validateNumber(el?.unit) -
          validateNumber(el?.discount);
        const totalAmountWithTax =
          totalAmountAfterDiscount +
          (validateNumber(el?.tax) / 100) * totalAmountAfterDiscount;
        results.push({
          sno: counter,
          date:  moment((invoice?.date)).format('DD-MM-YYYY & HH:mm a') || '-',
          group: el?.type || '-',
          amount: totalAmountAfterDiscount,
          paid: invoice.totalPaid
        });
        counter +=1;
      })
    }
  });

  return (results);
}
export const handleSelectedInvoiceConsolidated = (bill?: any, filter = 'billWise') => {
  let result: any = [];
  let totalCost = 0, totalTax=0, totalDiscount=0,grandTotal=0;
  let counter = 1;

  if (filter === 'transaction-date') {
    return TransactionDateWiseBill(bill);
  }

  if (filter === 'service-group') {
    return ServiceGrouping(bill);
  }

  if (filter === 'itemized') {

    bill.invoices.forEach((invoice: any, invoiceIndex: number) => {
      const billNo = `Bill ${bill.billNo}-`+ (invoiceIndex + 1);

      if (invoice.isOnlyPayment ) {
        result.push({
          billNo,
          sno: counter ,
          name: 'Payment/Advance',
          dateSort: new Date(invoice?.date),
          dateTime: moment((invoice?.date)).format('DD-MM-YYYY & HH:mm a') || '-',
          quantity: '-',
          rate: '-',
          discount:'-',
          tax: '-',
          totalDiscount:'-',
          amount: '-',
          isPackage: false,
          packages: [],
          paid: invoice.totalPaid
        });
        counter +=1;
      } else if (invoice.isDiscount) {
        result.push({
          billNo,
          sno: counter ,
          name: 'Discount',
          dateSort: new Date(invoice?.date),
          dateTime: moment((invoice?.date)).format('DD-MM-YYYY & HH:mm a') || '-',
          quantity: '-',
          rate: '-',
          discount:invoice.discountValue,
          tax: '-',
          totalDiscount: invoice.discountPercentage,
          amount: '-',
          isPackage: false,
          packages: [],
          paid: '-'
        });
        counter +=1;
      } else {
        invoice.items.forEach((el: any, elIndex: number) => {
          const totalAmountAfterDiscount =
            validateNumber(el?.cost) * validateNumber(el?.unit) -
            validateNumber(el?.discount);
          const totalAmountWithTax =
            totalAmountAfterDiscount +
            (validateNumber(el?.tax) / 100) * totalAmountAfterDiscount;
          result.push({
            billNo,
            sno: counter ,
            name: el?.name,
            dateSort: new Date(el?.timeStamp),
            dateTime: moment((el?.timeStamp)).format('DD-MM-YYYY & HH:mm a') || '-',
            quantity: el?.unit || 0,
            rate: el?.cost || 0,
            discount: el?.discount || 0,
            tax: el?.tax || 0,
            totalDiscount: el?.discountPercentage || 0,
            amount: totalAmountWithTax,
            isPackage: el?.isPackage,
            packages: el?.packages,
            paid: el?.totalPaid
          });
          counter +=1;
        });
      }

    });

  } else if (filter === 'consolidated-itemized') {
    bill.forEach((subBill: any) => {
      subBill.invoices.forEach((invoice: any, invoiceIndex: number) => {
        const billNo = subBill.billNo || subBill.billId;
  
        if (invoice.isOnlyPayment ) {
          result.push({
            billNo,
            sno: counter ,
            name: 'Payment/Advance',
            date: moment(subBill.createdAt).format('DD-MM-YYYY HH:mm'),
            dateSort: new Date(invoice?.date),
            dateTime: moment((invoice?.date)).format('DD-MM-YYYY & HH:mm a') || '-',
            quantity: '-',
            rate: '-',
            discount:'-',
            tax: '-',
            totalDiscount:'-',
            amount: '-',
            isPackage: false,
            packages: [],
            paid: invoice.totalPaid
          });
          counter +=1;
        } else if (invoice.isDiscount) {
          result.push({
            billNo,
            sno: counter ,
            name: 'Discount',
            date: moment(subBill.createdAt).format('DD-MM-YYYY HH:mm'),
            dateSort: new Date(invoice?.date),
            dateTime: moment((invoice?.date)).format('DD-MM-YYYY & HH:mm a') || '-',
            quantity: '-',
            rate: '-',
            discount:invoice.discountValue,
            tax: '-',
            totalDiscount: invoice.discountPercentage,
            amount: '-',
            isPackage: false,
            packages: [],
            paid: '-'
          });
          counter +=1;
        } else {
          invoice.items.forEach((el: any, elIndex: number) => {
            const totalAmountAfterDiscount =
              validateNumber(el?.cost) * validateNumber(el?.unit) -
              validateNumber(el?.discount);
            const totalAmountWithTax =
              totalAmountAfterDiscount +
              (validateNumber(el?.tax) / 100) * totalAmountAfterDiscount;
            result.push({
              billNo,
              sno: counter ,
              name: el?.name,
              date: moment(subBill.createdAt).format('DD-MM-YYYY HH:mm'),
              dateSort: new Date(el?.timeStamp),
              dateTime: moment((el?.timeStamp)).format('DD-MM-YYYY & HH:mm a') || '-',
              quantity: el?.unit || 0,
              rate: el?.cost || 0,
              discount: el?.discount || 0,
              tax: el?.tax || 0,
              totalDiscount: el?.discountPercentage || 0,
              amount: totalAmountWithTax,
              isPackage: el?.isPackage,
              packages: el?.packages,
              paid: el?.totalPaid
            });
            counter +=1;
          });
        }
  
      });
    })
  } else if (filter === 'consolidated-bills') {
    let sno = 0;
    bill.forEach((subBill: any) => {
      subBill.invoices.forEach((invoice: any, iIndex: any) => {
        sno = sno + 1;
  
        invoice?.items?.forEach((item: any) => {
          const cost = item.cost * item.unit;
          const discountedCost = Number(item.discount);
          const tax = (item.tax * (item.cost - discountedCost)) / 100;
          totalCost += validateNumber(cost);
          totalTax += validateNumber(tax);
          totalDiscount += validateNumber(discountedCost);
          grandTotal += (((validateNumber(cost) - validateNumber(discountedCost)) + validateNumber(tax)));
        });
  
  
        result.push({
          sno,
          typeOfBill:  invoice.isOnlyPayment || invoice.isDiscount ?  invoice.isDiscount ?'Discount' : 'Payment/Advance' : 'Sales',
          doctorName: subBill.doctorName || '-',
          billId: subBill.billNo || subBill.billId,
          date: moment(subBill.createdAt).format('DD-MM-YYYY HH:mm'),
          total:  invoice.isOnlyPayment || invoice.isDiscount ?  invoice.isDiscount ? invoice?.discountValue : '-' : invoice.totalCost,
          paymentMethod: invoice.method || '-',
          payment: invoice.totalPaid || '-',
          totalCost,
          totalTax,
          totalDiscount,
          grandTotal
        });
      });
    })
  } else {
    bill.invoices.forEach((invoice: any, iIndex: any) => {
      const sno = iIndex + 1;

      invoice?.items?.forEach((item: any) => {
        const cost = item.cost * item.unit;
        const discountedCost = Number(item.discount);
        const tax = (item.tax * (item.cost - discountedCost)) / 100;
        totalCost += validateNumber(cost);
        totalTax += validateNumber(tax);
        totalDiscount += validateNumber(discountedCost);
        grandTotal += (((validateNumber(cost) - validateNumber(discountedCost)) + validateNumber(tax)));
      });


      result.push({
        sno,
        typeOfBill:  invoice.isOnlyPayment || invoice.isDiscount ?  invoice.isDiscount ?'Discount' : 'Payment/Advance' : 'Sales',
        doctorName: invoice.doctorName || '-',
        billId: `${bill.billNo} - ${iIndex + 1}`,
        date: moment(invoice?.date).format("DD MMM YYYY"),
        total:  invoice.isOnlyPayment || invoice.isDiscount ?  invoice.isDiscount ? invoice?.discountValue : '-' : invoice.totalCost,
        paymentMethod: invoice.method || '-',
        payment: invoice.totalPaid || '-',
        totalCost,
        totalTax,
        totalDiscount,
        grandTotal
      });
    });
  }

  // if (Array.isArray(bill?.invoices) && bill?.invoices.length) {

  // }

  return result;
};

//number with commas
export function NC(x:number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const AddDrPrefix = (name: string) => {
  if (!name) {
    return '';
  }
  return name.indexOf('Dr.') > -1 ? name : `Dr.${name}`
}

export const reduceArrayAdd = (array: any, value: string) => {
  if (Array.isArray(array) && array.length) {
    return array.reduce((acc, currentObj) => {
      return validateNumber(acc) + validateNumber(currentObj[value]);
    }, 0);
  }
  return 0;
};

export const  calculateBalance = (invoices: any) => {
  if (Array.isArray(invoices) && invoices?.length) {

    let amount =
      validateNumber(reduceArrayAdd(invoices, "totalPaid")) + validateNumber(reduceArrayAdd(invoices, "totalBillDiscount")) -
      validateNumber(reduceArrayAdd(invoices, "totalCost"));
    return amount ? addComma(amount) : addComma(amount);
  } else {
    return 0;
  }
};


export const addComma = (data: any) => {
  try {
    if (typeof data === "number") {
      data = data.toString()
    }
    data = data.split(".");
    return data[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,") + (data[1] ? ("." + data[1]) : "");
  } catch (e) {
    return data;
  }
}


export const roundAndAddComma = (value: number | string, roundBy = 2) => {
  if(value) {
    return Number(Number(value).toFixed(roundBy)).toLocaleString('en-IN')
  } else {
    return value
  }
}

export const validateNumber = (value: any) => {
  return Number.isNaN(Number(value)) ? 0 : Number(value);
};


export const getBillTotalAmount = (invoices: any, onlyInvoice?: boolean) => {
  let totalPaid = 0,
    totalTax = 0,
    grandCost = 0,
    totalDiscount: any = 0,
    totalDiscountPercentage = 0,
    cashPayment = 0,
    cardPayment = 0,
    netAmount = 0;


   const calculateAggregation = (item: any)=>{
     item.discount = item.discount || 0;
     item.tax = item.tax || 0;
    const cost = item.cost * item.unit;
    const tax = (item.tax * (cost - item.discount)) / 100;
    const discountedCost = item.discount || 0;
    totalTax += tax || 0;
    grandCost += cost + tax;
    // totalCost += grandCost;
    totalDiscount += validateNumber(discountedCost);
    totalDiscountPercentage =
      validateNumber(totalDiscount) &&
      validateNumber(cost) &&
      (validateNumber(totalDiscount) / validateNumber(cost)) * 100;
  };



  if (onlyInvoice) {
    (invoices?.items.forEach(calculateAggregation));
  } else {
    invoices.forEach((invoice: any) => {

      cashPayment +=  validateNumber(invoice.cash || 0);
      cardPayment += validateNumber(invoice.card || 0);
      totalPaid += Number(invoice.totalPaid? invoice.totalPaid : 0);
      totalDiscount += validateNumber(invoice.totalBillDiscount);
      if (invoice.isDiscount) {
        totalDiscount += validateNumber(invoice.discountValue);
      }
      (invoice.items || []).forEach(calculateAggregation);
    });
  }

  netAmount = (grandCost + (totalTax || 0)) - (totalDiscount || 0);
  const roundOff = Math.round(netAmount);
  return [totalPaid, totalTax, grandCost, totalDiscount, totalDiscountPercentage, cashPayment, cardPayment, netAmount, roundOff];
};

