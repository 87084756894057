import * as React from "react";
import {useEffect, useState} from "react";
import axios from "axios";
import {Spinner} from "react-bootstrap";

const FullPageImageStyle = {
  maxHeight: '100vh',
  maxWidth: '100vw',
  minHeight: '100vh',
  minWidth: '100vw'
}

export const DisplaysecureImage = (props: any) => {

  const [isLoading, setLoading] = useState(true);
  const [imgUrl, setImgUrl] = useState('');
  const [preview, setPreview] = React.useState(false);
  const {obj, style, printType} = props;
  let customStyle = {backgroundImage: `url(${imgUrl})`};

  useEffect(()=>{
    (async ()=>{
      try {
        if (obj.url?.indexOf('bewellbox') > -1) {
          setImgUrl(obj.url);
          setLoading(false);
          return;
        }
        const urlObj: any = await axios.get(obj.url);
        setImgUrl(urlObj.data.url);
        setLoading(false);
      } catch(e) {
        console.log(e);
        setLoading(false);
      }

    })()
  }, []);

  if (isLoading) {
    return null;
  }
  //<img  src={imgUrl} style={style} alt={'image'}/>

  if (printType === 'full') {
    customStyle = Object.assign({}, customStyle, FullPageImageStyle);
  }

  return <div className={'fill'}> <div className={'fill-img'} style={customStyle}/> </div>;
};
