import {Col, Row} from "react-bootstrap";
import Allergyhistory from "./Allergyhistory";
import Printpositivehistorycard from "./Printpositivehistory";
import CurrentMedication from "./CurrentMedication";
import PastmedicalHistory from "./PastmedicalHistory";
import FamilialHistory from "./FamilialHistory";



function Patienthistory(props: any) {
  const {patientHistory} = props;
  const {weightLoss, diabetics, cardiovascular, gastrointestinal, genitourinary_conditions, hypertension, neurological_conditions, psychological_conditions, respiratory_illness} = patientHistory;
  const RenderItem = [];

  if (weightLoss.selected) {
     RenderItem.push( <p><span className="label-question-large">History of weight loss</span> : &nbsp; Yes ; weightLoss.comment</p>)
  }

  return <Printpositivehistorycard>
    {

    }
  </Printpositivehistorycard>
}

function Renderhistory(props: any) {
  const {medicalHistory} = props;

  return <>
    {/*<Patienthistory patientHistory={patientHistory}/>*/}
    { medicalHistory['allergy-history'] ? <Allergyhistory allergyHistory={medicalHistory['allergy-history']}/> : null}
    { medicalHistory['current_medications'] && medicalHistory['current_medications'].currentMedications && medicalHistory['current_medications'].currentMedications.length ? <CurrentMedication currentMedications={medicalHistory['current_medications'].currentMedications}/> : null}
    { medicalHistory['past-medical-history'] && medicalHistory['past-medical-history'].comments ? <PastmedicalHistory comments={medicalHistory['past-medical-history'].comments}/> : null}
    { medicalHistory['familial-history']  ? <FamilialHistory familialHistory={ medicalHistory['familial-history'] }/> : null}
  </>
}

export default Renderhistory;
