import Printpositivehistorycard from "./Printpositivehistory";
import {Badge} from "react-bootstrap";

function CurrentMedication(props: any){
  const {currentMedications} = props;
  // const {medicalAllergiesSelected,medicalAllergies, foodAllergiesSelected, foodAllergies, latexAllergiesSelected, latexAllergies, otherAllergiesSelected, otherAllergies} = allergyHistory;

  return <Printpositivehistorycard title={'Current Medications'}>
    {currentMedications.map((medication: string) => {
        return <div className={'pill-div'}>{medication}</div>
    })}
  </Printpositivehistorycard>
}

export default CurrentMedication;
