

import * as React from "react";
import {Table} from "react-bootstrap";

export const CentralDeficit = ['No focal neurological deficit', 'Neurological deficit'];

export const CentralNervousSystemPreview = (props: any)=>{
  const {record, updateCb, fieldIndex, recordIndex} = props;

  const {
    comments,
    centralNervousSystem,
    centralNervousSystemComments,
  } = record;

  return <div className={'general-examination general-examination-preview'}>
      <div className={'field-row-preview-row'}>
        <table>
          <tr>
            <td>
              <span>Central nervous system</span>
              <br/>
             {centralNervousSystem || '-'}
            </td>
          </tr>
          {centralNervousSystem === CentralDeficit[1].toLowerCase() ? <tr>
            <td>
              <span>Central nervous system comments</span>
              <br/>
             {centralNervousSystemComments}
            </td>
          </tr> : null}
          <tr>
            <td>
              <span>Comments</span>
              <br/>
             {comments || '-'}
            </td>
          </tr>
        </table>
      </div>

  </div>

}
