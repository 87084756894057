import img from './medical-icon.png';
import JsBarcode from "jsbarcode";
import { useEffect } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import moment from 'moment';

function PdfHeader(props: any) {
  const { hospitalInfo, patientInfo, report, radiologyv2, headerInfo, radiologyReport, letterhead, letterHead, radreportingshare } = props;


  let headerHeight = 180;
  let empty = headerInfo?.emptyheader

  if (empty && headerInfo.headerHeight) {
    headerHeight = headerInfo.headerHeight;
  }
  headerHeight = Number(headerHeight);

  let headerBackgroundStyle = letterHead?.primaryColor ? { backgroundColor: letterHead?.primaryColor } : {};
  const primaryColor = letterHead?.primaryColor ? { color: letterHead?.primaryColor } : {};
  let styleWaterMark = letterHead?.waterMark ? { backgroundImage: `url(${letterHead?.waterMark})`, 'backgrounPosition': 'center', 'backgroundSize': 'contain', backgroundRepeat: 'no-repeat' } : null;
  let patientObj1 = typeof radiologyv2 !== undefined && report ? report[radiologyv2]?.patientInfo : {};

  let patientObj = patientInfo;
  let hospital = hospitalInfo[0];
  let location = hospital.location;
  let logo = (hospital.logo) ? (hospital.logo) : img;
  if (headerInfo) {
    hospital = headerInfo;
    // location = headerInfo;
  }
  let address = [];
  location.address && address.push(location.address);
  location.city && address.push(location.city);
  location.state && address.push(location.state);
  location.areaCode && address.push(location.areaCode);
  let barcodeHeight = empty ? 20 : 40;


  useEffect(() => {
    if (letterhead) {

      return;
    }
    JsBarcode("#barcode", patientObj?.uhid?.toUpperCase(), {
      width: 1.5,
      height: barcodeHeight,
      displayValue: true,
    });
  });


  if (radiologyReport?.status === 'Not Started') {
    return null;
  }


  try {
    if (radiologyReport) {
      const letterHederIndex = hospital?.radiologyLetterHeads?.findIndex((item: any) => item.id === letterhead);
      let customHeader = letterHederIndex > -1 ? hospital.radiologyLetterHeads[letterHederIndex]?.customHeader : {};
      let header = letterHederIndex > -1 ? hospital.radiologyLetterHeads[letterHederIndex] : null;
      const titleColor = header?.primaryColor ? { color: header.primaryColor } : {};

      return <thead style={{ maxHeight: 150, overflow: 'auto' }}>

        <Table size={'sm'} className='rad-table'>

          <thead>
            {(empty || header?.top && !radreportingshare) ? 
            // <tr>
            //   <th className="">  <div style={{ marginTop: header?.top ? '10mm' : '70px' }}><div >&nbsp;</div></div></th></tr> 
              null
              :
              (customHeader?.left || customHeader?.right) && radreportingshare ? <tr>
                <th>
                  <div style={{ height: 70 }}>
                    {customHeader?.left ? <div style={{ textAlign: 'left', float: 'left', padding: 0, border: 'none', width: customHeader?.right ? '50%' : '100%' }}>
                      <img className="header-logo-img" src={customHeader?.left} style={{ maxWidth: customHeader?.right ? '300px' : '100%', maxHeight: customHeader?.right ? '80px' : '100%' }} />
                    </div>
                      : null}
                  </div>
                </th>
                {customHeader?.right ? <th>
                  <div style={{ height: 70 }}>
                    {customHeader?.right ? <div style={{ float: 'right', border: 'none' }}>
                      <img className="header-logo-img" src={customHeader?.right} style={{ maxWidth: '150px', maxHeight: '120px' }} />
                    </div> : null}
                  </div>
                </th> : null}
              </tr> : null

            }

            <tr>
              <th colSpan={2}>
                <Row>
                  {hospital.radiologyLetterHeads[letterHederIndex]?.headerTitle ? <Col xs={12}>
                    <p style={{ fontFamily: "'Times New Roman', Times, serif", textAlign: 'center', fontSize: radreportingshare ? '13pt' :  '15pt' }}>{hospital.radiologyLetterHeads[letterHederIndex]?.headerTitle}</p>
                  </Col> : null}
                  <Col xs={12}>
                    <Table size={'sm'} className='rad-patient-info'>
                      <tr>
                        <td>
                          <span className='table-title' style={titleColor}> Patient Name   </span> :  <span> {patientObj1.patientName?.replaceAll('^', ' ')}</span>
                        </td>
                        <td>
                          <span className='table-title' style={titleColor}> Date Of Study   </span> :  <span> {patientObj1?.dateOfStudy}</span>
                        </td>
                       
                       
                      </tr>
                      <tr>
                        <td>
                          <span className='table-title' style={titleColor}> Age   </span> :  <span> {patientObj1.age ? patientObj1.age : patientObj1.patientAge ? patientObj1.patientAge : '-'}</span>
                        </td>
                        <td>
                          <span className='table-title' style={titleColor}> {hospital.medicalCenterId === '2c01e90e-ea8a-4b8b-a936-7df3d1099a3d'  ? 'Hospital ID'  : 'Patient ID'}    </span> :  <span> {patientObj1.uhid?.toUpperCase() || '-'}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className='table-title' style={titleColor}> Gender   </span> :  <span> {patientObj1.gender || ''}</span>
                        </td>
                        <td>
                          <span className='table-title' style={titleColor}> Ref   </span> :  <span> {patientObj1?.referringPhysicianName || '-'}</span>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2} style={{border: 'none'}}>
                          <p style={{ textAlign: 'center', fontSize: radreportingshare ? '13pt' : '15pt', fontWeight: 'bold', fontFamily: "'Times New Roman', Times, serif", textDecoration: 'underline' }}>{radiologyReport[radiologyv2]?.reportTitle?.toUpperCase() || ''}</p>
                        </td>
                      </tr>
                      
                    </Table>
                  </Col>

                </Row>
              </th>

             
            </tr>
          </thead>
        </Table>
      </thead>
    }
  } catch (e) {
    console.log(e);
  }







  return <thead>
    {radiologyReport ? null : <tr>
      <th className="outer-table">
        {empty ? <div className="emptybar-header-background"><div className="emptybar-header" style={{ height: headerHeight + "px" }} >&nbsp;</div>
          {(patientObj && patientObj.uhid) ? <div className="emptybar-header-barcode"> <svg id="barcode"></svg> </div> : null}</div> :
          <div className="header">
            <div style={{ float: "left" }}>
              <img className="header-logo-img" src={logo} />
            </div>
            <div className="header-name-position">
              <span className="header-large-medical-center-name">{hospital.name}</span>
              <br />
              {hospital?.subtitle ? <span className="header-large-medical-center-name" style={{ fontSize: '15px' }}>{hospital?.subtitle}</span> : null}
              <p><span className="header-large-address-text">{address.join(', ')}</span></p>
              {hospital.phone || hospital.emailId ? <p>
                {hospital.phone ? <span className="header-large-address-text">Phone: {hospital.phone}</span> : null}

                {hospital.emailId ? <span className="header-large-address-text" style={{ marginLeft: '15px' }}>Email: {hospital.emailId}</span> : null}
              </p> : null}
              <p><span className="header-large-address-text">{hospital.website}</span></p>
            </div>
            {(patientObj && patientObj.uhid) ? <div style={{ float: "right" }}> <svg id="barcode"></svg> </div> : null}
          </div>
        }
      </th>

    </tr>}

    {/* </tr> */}
  </thead>
}

export default PdfHeader;
