import img from './medical-icon.png';
import JsBarcode from "jsbarcode";
import {useEffect} from 'react';

function PharmacyHeader(props: any) {
    const {pharmacyInfo} = props;
    console.log(pharmacyInfo)
    let pharmacy = pharmacyInfo.data[0];
    console.log(pharmacy)
    let logo = (pharmacyInfo.logo) ? (pharmacyInfo.logo) : img;

    return <thead>
        <tr>
        <th className="outer-table">

            <div className="header" style={{padding: 0}}>
                {pharmacy.logo ? <div style={{float: 'left'}}>
                        <img className="header-logo-img" src={pharmacy.logo} /> 
                </div> : null}
                <div className="pharma-header-name-position">
                <span className="header-large-medical-center-name">{pharmacy.pharmacyName}</span>
                <p><span className="header-large-address-text">{pharmacy.pharmacyAddress}</span></p>
                {pharmacy.licenseNo || pharmacy.gstNo || pharmacy.phoneNo ?
                    <p>
                        {pharmacy.phoneNo ? <span className="header-large-additional-details"><b>Tell No: </b>{pharmacy.phoneNo} &nbsp;&nbsp;</span> : null}
                        {pharmacy.licenseNo ? <span className="header-large-additional-details"><b>DL No: </b>{pharmacy.licenseNo} &nbsp;&nbsp;</span> : null}
                        {pharmacy.gstNo ? <span className="header-large-additional-details"><b>GSTIN: </b> {pharmacy.gstNo}</span> : null}
                    </p>
                 : null}
                </div>
            </div>

        </th>
        </tr>
    </thead>
  }

  export default PharmacyHeader;
