import React, { useEffect, useState } from 'react';
import axios from "axios";
import './App.css';
import getUrlParams from "./getUrlParams";
import { Container, Spinner } from 'react-bootstrap';


let url = '';
if (window.location.href.indexOf('pdf.bewelldigital.com') > -1) {
  url = 'https://q1lsdba6f6.execute-api.ap-south-1.amazonaws.com/prod';
} else if (window.location.href.indexOf('pdf-dev.bewelldigital.com') > -1) {
  url = 'https://4440i72tm2.execute-api.us-west-2.amazonaws.com/prod';
} else {
  url = 'https://q1lsdba6f6.execute-api.ap-south-1.amazonaws.com/prod';
  // url = 'https://4440i72tm2.execute-api.us-west-2.amazonaws.com/prod';
}

function getWordDownloadUrl(medicalCenterId: any, itemId: any) {
    return axios.get(`${url}/radiology-reporting-v2-word/word-download-link/${medicalCenterId}/${itemId}`);
}
  
function getPdfDownloadUrl(medicalCenterId: any, instanceId: any, itemId: any) {
    return axios.get(`${url}/radiology-reporting-v2-word/pdf-report/${medicalCenterId}/${instanceId}/${itemId}`);
}

function WordReport() {
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const params = getUrlParams(window.location.href);
        const { centerid, instanceid, wordreportid, wordreport } = params;

        (async () => {
            try {
                let getDownloadUrl;
                if (wordreport === 'word') {
                    getDownloadUrl = await getWordDownloadUrl(centerid, wordreportid);
                } else {
                    getDownloadUrl = await getPdfDownloadUrl(centerid, instanceid, wordreportid);
                }
                setMessage(`URL: ${getDownloadUrl.data}`);
                setLoading(false);
                window.location.href = getDownloadUrl.data;
                setTimeout(() => {
                    window.close();
                  }, 3000);
            } catch (e: any) {
                setMessage(`Failed to load url, error: ${e}`);
                setLoading(false);
            }
        })()

    }, [])


    if (loading) {
        return <div style={{ height: '200px', width: '100%', marginTop: '100px', textAlign: 'center', fontSize: '13px' }}><Spinner animation="border" role="status"></Spinner><br /> <br /> {'Please wait ...'}</div>
    }
    return <div style={{ height: '200px', width: '100%', marginTop: '100px', textAlign: 'center', fontSize: '13px' }}>{message}</div>
}

export default WordReport;