import * as React from "react";
import moment from "moment";

function VisitBillDetails(props: any) {
  const {selectedPatient, selectedVisit, itemized} = props;


  if (!selectedPatient) {
    return <h4 style={{textAlign: 'center'}}>Invalid Patient ID</h4>
  }

  if (!selectedVisit) {
    return <h4 style={{textAlign: 'center'}}>Invalid Visit ID</h4>
  }

  return <div>
    <div className={'center-text'}>
      <span className={'dot-matrix-large-text-bold center-text'}>{itemized ? 'INVOICE - Visit Bill' : 'Visit Bill - CONSOLIDATED INVOICE'}</span>
    </div>
    <div className={'invoice-details'}>
      <div className={'invoice-details-section'}>
        <p>
          <div className="label-question-120">
            <span className={'dot-matrix-large-text-bold'}>Patient Name</span>
          </div>
          <span className={'dot-matrix-large-text-light'}> : {selectedPatient.fullName}</span>
        </p>
        <p>
          <div className="label-question-120">
            <span className={'dot-matrix-large-text-bold'}>Doctor</span>
          </div>
          <span className={'dot-matrix-large-text-light'}> : {selectedVisit.docName}</span>
        </p>
        <p>
          <div className="label-question-120">
            <span className={'dot-matrix-large-text-bold'}>Mobile</span>
          </div>
          <span className={'dot-matrix-large-text-light'}> : {selectedPatient.phone ? selectedPatient.phone : '-'}</span>
        </p>
      </div>
      <div className={'invoice-details-section'}>
        <p>
          <div className="label-question-120">
            <span className={'dot-matrix-large-text-bold'}>IP No</span>
          </div>
          <span className={'dot-matrix-large-text-light'}> : {selectedVisit.ipNumber ? selectedVisit.ipNumber : '-'}</span>
        </p>
        <p>
          <div className="label-question-120">
            <span className={'dot-matrix-large-text-bold'}>Visit Date</span>
          </div>
          <span className={'dot-matrix-large-text-light'}> : {selectedVisit.date ? moment(selectedVisit.date).format('DD/MM/YYYY') : '-'}</span>
        </p>
        <p>
          <div className="label-question-120">
            <span className={'dot-matrix-large-text-bold'}>Time</span>
          </div>
          <span className={'dot-matrix-large-text-light'}> : {selectedVisit.date ? moment(selectedVisit.date).format('h:mm A') : '-'}</span>
        </p>
      </div>
    </div>
  </div>
}

export default VisitBillDetails;
