import { Col, Row } from 'react-bootstrap';
import * as React from "react";
import moment from "moment";
import { ToWords } from 'to-words';

const toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
        currency: true,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
        doNotAddOnly: false,
    }
});

const AddDrPrefix = (name: string) => {
    if (!name) {
        return '';
    }
    return name.indexOf('Dr.') > -1 || name.indexOf('dr.') > -1 ? name : `Dr.${name}`
}

const Nameprefix = (patient: any) => {

    try {


        if (!patient) {
            return '';
        }

        if (patient && !patient.name && !patient.name) {
            patient = { name: patient };
        }
        const hasSalutationInName = ['miss.', 'mrs.', 'mr.', 'baby.'].some(v => (patient?.fullName || patient?.name)?.toLowerCase().includes(v));
        if (patient?.salutation && patient?.salutation.length > 1 && !hasSalutationInName) {
            return patient.salutation + '.';
        }

        if (patient && (!patient.gender || !patient.age)) {
            return '';
        }

        if (['miss.', 'mrs.', 'mr.', 'baby.'].some(v => (patient.fullName || patient.name)?.toLowerCase().includes(v))) {
            return '';
        }

        if ((patient && patient.age && patient.age.years && patient.age.years <= 3) || (!patient.age!.years && patient.age!.months)) {
            return 'Baby. '
        }

        return patient && patient.gender === 'male' ? 'Mr. ' : patient.gender === 'female' ? 'Ms. ' : '';
    } catch (e) {
        console.log(e);
        return '';
    }
}


//number with commas
function NC(x: number, includeDecimal = true) {
    if (!x) {
        return ' - ';
    }

    let y = x.toFixed(0)
    if (includeDecimal) {
        y = x.toFixed(2);
    }

    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function percentageToNumber(percentage: number, total: number) {
    return (percentage / 100) * total;
}

function SelfConsumptionBillTable(props: any) {
    const { billInfo, pharmacyInfo, centerInfo } = props;

    const { pharmacyAddress } = pharmacyInfo?.data[0];

    let bill = billInfo.data[0];

    if (!bill) {
        return <h4 style={{ textAlign: 'center' }}>Invalid Bill ID</h4>
    }
    let billItems = bill.billItems;
    let pharmacy = pharmacyInfo.data[0];
    let isGST = pharmacy.gst;
    let cgst = 0;
    let sgst = 0;
    let grossAmount = 0;
    let serialNumber = 0;

    console.log(bill);
    console.log('####');

    return <div className="body-container discharge-summary">
        <h1 className={'center-text page-header'}>Invoice</h1>
        <div className={'graybox'} style={{ padding: '10px', border: '1px solid #d5d5d5', borderRadius: '0px' }}>
            <Row>
                <Col xs={6}>
                    <div className={'flex-container'}>
                        <div className={'color-blue font-16'}>
                            Self Consumption
                        </div>
                        {/*<div className={'gray-parent graybox'}>*/}
                        <div className={''}>
                            <p>
                                <div className="label-question-120">Department Name</div>
                                : {(centerInfo[0]?.listOfDepartments?.find((department: any) => department.id === Number(bill.departmentId))?.name || '-')}</p>
                            
                        </div>
                        {/*</div>*/}
                    </div>
                </Col>

                <Col xs={6}>
                    <div className={'flex-container'}>
                        <div className={'color-blue font-16'}>
                            Bill Information
                        </div>
                        {/*<div className={'gray-parent graybox'}>*/}
                        <div className={' '}>
                            <p>
                                <div className="label-question-120 width-200">Bill No</div>
                                : {bill.id}</p>
                            <p>
                                <div className="label-question-120 width-200">Date & Time</div>
                                : {bill?.createdAt ? moment(bill?.createdAt).format('DD/MM/YYYY h:mm A') : '-'}</p>
                            <p>
                                <div className="label-question-120">Payment</div>
                                : {bill?.billPayments.map((payment: any, index: number) => { return index > 0 ? <span>, <b>{payment.paymentMode}</b></span> : <span><b> {payment.paymentMode} </b></span> })}
                            </p>
                        </div>
                        {/*</div>*/}
                    </div>
                </Col>

            </Row>
            <Row>

            </Row>
        </div>
        <div>

            <table className={'pharma-bill-table'}>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Product</th>
                        <th>HSN Code</th>
                        <th>Batch</th>
                        <th>Exp</th>
                        <th>Qty</th>
                        <th>MRP</th>
                        {/* <th>Discount %</th> */}
                        {isGST ? <th>GST %</th> : null}
                        <th className={'align-right'}>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        billItems.map((item: any) => {


                            serialNumber++;
                            grossAmount += Number(item.total)

                            if (item?.tax) {
                                let taxValue = percentageToNumber(Number(item?.tax), Number(item.total));
                                cgst += taxValue
                                if (isGST) {
                                    grossAmount -= taxValue
                                }
                            }

                            if (item?.purchaseItem?.sgstPercentage) {
                                let taxValue = percentageToNumber(item?.purchaseItem?.sgstPercentage, Number(item.total));
                                sgst += taxValue
                                if (isGST) {
                                    grossAmount -= taxValue
                                }
                            }

                            return <tr>
                                <td>{serialNumber || '-'}</td>
                                <td>{item.name}</td>
                                <td>{item?.drug?.hsnCode || '-'}</td>
                                <td>{item.batch || '-'}</td>
                                <td>{item?.expiry?.slice(0, 7) || '-'}</td>
                                <td>{item.quantity || '-'}</td>
                                <td>{item?.rate || '-'}</td>
                                {/* <td>{item.discountPercentage || '-'}</td> */}
                                {isGST ?
                                    <td>{(Number(item?.tax) || 0) + (item?.purchaseItem?.sgstPercentage || 0) || '-'}</td> : null}
                                <td className={'align-right'}>{Number(item.total)?.toFixed(2) || '-'}</td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        <div style={{ marginTop: '0.5cm', padding: '10px' }} className={'border-top-divider'}>
            <Row style={{ marginTop: '0.2cm' }}>
                <Col xs={8}>
                    <Row>
                        <Col xs={1} className={' billing-table-td'}><span className="  ">Base: </span></Col>
                        <Col xs={2} className={' billing-table-td align-right'}> {NC(grossAmount)}</Col>

                        {isGST ?
                            <><Col xs={1} className={' billing-table-td'}><span className="  ">CGST:</span></Col>
                                <Col xs={2}
                                    className={' billing-table-td align-right'}>{(cgst) ? <>{NC(cgst)}</> : '-'}</Col></>
                            : null}
                        {isGST ?
                            <><Col xs={1} className={' billing-table-td'}><span className="  ">SGST:</span></Col>
                                <Col xs={2}
                                    className={' billing-table-td align-right'}>{(sgst) ? <> {NC(sgst)}</> : '-'}</Col></>
                            : null}
                        <Col xs={1} className={' billing-table-td'}><span className="  ">ROD:</span></Col>
                        <Col xs={1}
                            className={' billing-table-td align-right'}> {(bill.roundOff) ? <> {NC(-bill.roundOff)}</> : 0}</Col>
                    </Row>
                </Col>
                <Col xs={4}>
                    {<Row>

                        <Col xs={10}>
                            <div>
                                <Row>
                                    {/* <Col xs={6} className={' billing-table-td'}><span
                                        className=""><b>Payment:</b></span></Col>
                                    <Col xs={6} className={' billing-table-td align-right'}>
                                        {bill?.payments.map((payment: any, index: number) => { return index > 0 ? <span>, <b>{payment.mode}</b></span> : <span><b> {payment.mode} </b></span> })}
                                    </Col> */}
                                    <Col xs={6} className={' billing-table-td'}><span
                                        className=""><b>Bill Amount:</b></span></Col>
                                    <Col xs={6}
                                        className={' billing-table-td align-right'}>&#8377;
                                        <b>{NC(bill.totalAmount, false)}</b></Col>
                                </Row>

                            </div>
                        </Col>

                    </Row>}
                </Col>
            </Row>
            <br />
            <Row>
                <Col xs={12} style={{ textAlign: 'right', color: '#000' }}>{<> {toWords.convert(Number(bill.totalAmount))}</>}</Col>
            </Row>
        </div>
    </div>
}

export default SelfConsumptionBillTable;
