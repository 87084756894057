import React, { useEffect, useState } from 'react';
import axios from "axios";
import './App.css';
import './pharmaAnalytics/pharmaAnalytics.css';
import PharmacyHeader from "./commons/pharmacyHeader";
import PharmacyAnalyticsHeader from "./pharmaAnalytics/pharmaAnalyticsHeader";
import PharmacyAnalyticsFooter from "./pharmaAnalytics/pharmaAnalyticsFooter";
import PharmaAnalyticsTable from "./pharmaAnalytics/pharmaAnalyticsTable";
import Pdffooter from "./commons/footer";
import getUrlParams from "./getUrlParams";
import BillTable from './pharma/billTable';
import SelfConsumptionBillTable from './pharma/selfConsumptionBillTable';
import { Container, Spinner } from 'react-bootstrap';
import PharmacyHeaderDotMatrix from "./pharma/DotMatrix/pharmacyHeaderDotMatrix";
import { default as BillTableDotMatrix } from "./pharma/DotMatrix/billTable";
import { default as SelfConsumptionBillTableDotMatrix } from "./pharma/DotMatrix/selfConsumptionBillTable";
import reportDecider from './pharmaAnalytics/pharmaAnalyticsCommons'

let url = '';
if (window.location.href.indexOf('pdf.bewelldigital.com') > -1) {
  url = 'https://q1lsdba6f6.execute-api.ap-south-1.amazonaws.com/prod';
} else {
  url = 'https://4440i72tm2.execute-api.us-west-2.amazonaws.com/prod';
  url = 'https://q1lsdba6f6.execute-api.ap-south-1.amazonaws.com/prod';
}

function getPharmaInfo(pharmaId: any) {
  console.log(`${url}/pharmacy/${pharmaId}`)
  return axios.get(`${url}/pharmacy/${pharmaId}`)
}

function getPharmaSelfConsumptionBill(pharmaId: any, billId: any) {
  return axios.get(`${url}/pharmacy/scbills/${billId}?pharmacyId=${pharmaId}`)
}

function getHospitalInfo(medicalCenterId: any){
    return axios.get(`${url}/medicalcenter/`+medicalCenterId)
  }

const DotMatrixCss = React.lazy(() => import('./pharma/DotMatrix/pharmaCss'));

function PharmaSelfConsumption() {
  const [state, setState] = useState({
    billInfo: null as any,
    pharmacyInfo: null as any,
    reportType: null as any,
  } as any);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const params = getUrlParams(window.location.href);
    const { billid, pharmaid, reporttype, reportsubtype, startdate, enddate } = params;

    (async () => {
      const promises = [];

      if (reporttype === 'pharma-self-consumption-bill' && pharmaid && billid) {
        promises.push(await getPharmaInfo(pharmaid));
        promises.push(await getPharmaSelfConsumptionBill(pharmaid, billid));
      }
      else if (reporttype == 'pharma-self-consumption-analytics' && reportsubtype && url && pharmaid) {
        promises.push(await getPharmaInfo(pharmaid));
        promises.push(await reportDecider.analyticsReportDecider(reportsubtype, url, pharmaid, startdate, enddate,'','','','',''));
      }



      (Promise.all(promises)).then(async (response) => {
        const [pharmaInfoResponse, reportResponse] = response;
        // try {
        //   patient = await getPatientInfo(pharmaInfoResponse.data?.data[0].medicalCenterId, reportResponse.data.data[0]?.customer?.patientId)
        //   // reportResponse.data.data[0].customer.salutation = patient.data.salutation;
        //   reportResponse.data.data[0].customer.phoneNo = patient.data.phone;
        //   // reportResponse.data.data[0].customer.age = patient.data.age;
        //   // reportResponse.data.data[0].customer.gender = patient.data.gender;
        //   console.log(patient.data);
        // } catch(e) {
        //   console.log(e);
        // }

        const centerId = pharmaInfoResponse.data?.data[0].medicalCenterId;
        const centerInfo = await getHospitalInfo(centerId);
        
        setState({
          reportResponse: reportResponse.data,
          pharmacyInfo: pharmaInfoResponse.data,
          centerInfo: centerInfo.data,
          reportType: reporttype,
          reportSubType: reportsubtype,
          startDate: startdate,
          endDate: enddate,
        });
        setLoading(false);
      });
    })();
  }, [])

  if (loading) {
    return <div style={{ height: '200px', width: '100%', marginTop: '100px', textAlign: 'center', fontSize: '13px' }}>
      <Spinner animation="border" role="status"></Spinner><br /> <br /> {'Please wait ...'}</div>
  }

  const { reportResponse, pharmacyInfo, centerInfo, reportType, reportSubType, startDate, endDate } = state;
  let pharmacy = pharmacyInfo.data[0];

  // if(pharmacy.isDotMatrixPrinter) {
  //   require('./css/Pharmacy.css')
  // }

  // @ts-ignore
  const PharmaCss = ({ children }) => {
    return (
      <>
        <React.Suspense fallback={<></>}>
          {(pharmacy.isDotMatrixPrinter) && <DotMatrixCss />}
          {children}
        </React.Suspense>
      </>
    )
  }

  return (
    <div>
      {(reportType === 'pharma-self-consumption-bill' && pharmacy.isDotMatrixPrinter ? (<PharmaCss>
        <table className={'dot-matrix flex-column-center'}>
          <PharmacyHeaderDotMatrix pharmacyInfo={pharmacyInfo} />
          {reportResponse && reportType === 'pharma-self-consumption-bill' ?
            <SelfConsumptionBillTableDotMatrix billInfo={reportResponse} pharmacyInfo={pharmacyInfo} centerInfo={centerInfo} /> : null}
        </table>
      </PharmaCss>) : (<table className="App">
        {reportResponse && reportType === 'pharma-self-consumption-bill' ? <PharmacyHeader pharmacyInfo={pharmacyInfo} /> :
          <PharmacyAnalyticsHeader pharmacyInfo={pharmacyInfo} />}
        <tbody>
          <tr>
            <td className="outer-table">
              {reportResponse && reportType === 'pharma-self-consumption-bill' ?
                <SelfConsumptionBillTable billInfo={reportResponse} pharmacyInfo={pharmacyInfo} centerInfo={centerInfo} /> : <PharmaAnalyticsTable reportData={reportResponse} reportSubType={reportSubType} startDate={startDate} endDate={endDate} />}
            </td>
          </tr>
        </tbody>
          {reportResponse && reportType === 'pharma-self-consumption-bill' ? <Pdffooter empty={true} headerInfo={{ footerText: pharmacyInfo.data[0].footerMessage}}></Pdffooter> :
          <PharmacyAnalyticsFooter />}
      </table>))}

    </div>


  );
}

export default PharmaSelfConsumption;
