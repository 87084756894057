import Printpositivehistorycard from "./Printpositivehistory";

function PastmedicalHistory(props: any){
  const {comments} = props;
  // const {medicalAllergiesSelected,medicalAllergies, foodAllergiesSelected, foodAllergies, latexAllergiesSelected, latexAllergies, otherAllergiesSelected, otherAllergies} = allergyHistory;

  return <Printpositivehistorycard title={'Past Medical History'}>
   <p>{comments.replaceAll(/\u21B5/g,'<br/>')}</p>
  </Printpositivehistorycard>
}

export default PastmedicalHistory;
