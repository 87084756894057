import beWellLogo from "../images/BeWell.svg"
import {useEffect} from 'react';


function PharmaAnalyticsFooter(props: any) {

    return  <tfoot>
    <tr>
      <th className="outer-table">
          <div className="pa-footer">
            <div className="pa-footer-bewell">
                <div className="pa-footer-logo">
                  <img src={beWellLogo} height="30px"/>
                </div>
                <div>
                  <span className="pa-poweredby-text">Powered by</span><br/>
                  <span className="pa-Bewell-text">Bewell Digital, Inc</span>
                </div>
            </div>
          </div>
      </th>
    </tr>
  </tfoot>
  }

  export default PharmaAnalyticsFooter;
