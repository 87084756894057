import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import '../App-v2.css';
import axios from "axios";
import { DisplaysecureImage } from "./DisplaySecureImage";
import * as React from "react";


function getFileUrl(url: string) {
  return axios.get(url);
}

const RenderInfo = (props: any) => {
  const { title, value } = props;
  return <div className={'info-record'}>
    <div className={'info-label'}>
      {title}
    </div>
    <div className={'info-value'}>
      <TextAreaText text={value} />
    </div>
  </div>
}

function TextAreaText(props: { text: string }) {
  let text = props.text;
  if (!text) {
    return null;
  }

  text = text.trim();

  return <><div dangerouslySetInnerHTML={{ __html: text }} className={'preline-whitespace'} /></>;
}


const Nameprefix = (patient: any) => {
  if (['miss.', 'mrs.', 'mr.', 'baby.'].some(v => patient.fullName.toLowerCase().includes(v))) {
    return '';
  }

  if ((patient && patient.age && patient.age.years && patient.age.years <= 3) || (!patient.age!.years && patient.age!.months)) {
    return 'Baby. '
  }

  return patient && patient.gender === 'male' ? 'Mr. ' : patient.gender === 'female' ? 'Ms. ' : '';
}

function DischargeSummaryv2(props: any) {

  const { patientInfo, visit, doctors, reportType, headerInfo } = props;


  return <>
    <div className="body-container discharge-summary discharge-summary-v2" style={{ width: '100%' }}>
      {/* <h1 className={'center-text page-header'}>Discharge Summary</h1> */}

      <Row>
        <Col xs={12}>
          <div dangerouslySetInnerHTML={{ __html: visit?.dischargeSummary?.content || '<p>No Content Available</p>' }} className='radiology-content'>

            </div>
        </Col>
    
      </Row>
    </div>
   





  </>



}

export default DischargeSummaryv2;
