import img from '../commons/medical-icon.png';
import JsBarcode from "jsbarcode";
import {useEffect} from 'react';

function PharmacyAnalyticsHeader(props: any) {
    const {pharmacyInfo} = props;
    console.log(pharmacyInfo)
    let pharmacy = pharmacyInfo.data[0];
    console.log(pharmacy)
    let logo = (pharmacyInfo.logo) ? (pharmacyInfo.logo) : img;
    
    return <thead>
        <tr>
        <th className="outer-table">
            <div className="pa-header">
                <div className="pa-pharma-name">
                <span className="pa-header-pharma-name">{pharmacy.pharmacyName}</span>
                </div>
                <div className="pa-pharma-address">
                    <p><span className="pa-address-text">{pharmacy.pharmacyAddress}</span></p>
                    <p><span className="fa fa-phone pa-color"/>&nbsp;<span className="pa-address-text">{pharmacy.phoneNo}</span></p>
                </div>
            </div>
        
        </th>
        </tr>
    </thead>
  }

  export default PharmacyAnalyticsHeader;
