import * as React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import PdfHeader from "../commons/header";
import HTMLtoDOCX from 'html-to-docx';
import { saveAs } from 'file-saver';


const Nameprefix = (patient: any) => {
  if (['miss.', 'mrs.', 'mr.', 'baby.'].some(v => patient.name.toLowerCase().includes(v))) {
    return '';
  }

  if ((patient && patient.age && patient.age.years && patient.age.years <= 3) || (!patient.age!.years && patient.age!.months)) {
    return 'Baby. '
  }

  return patient && patient.gender === 'male' ? 'Mr. ' : patient.gender === 'female' ? 'Ms. ' : '';
}

export function RadiologyReportv2(props: any) {
  const { report, print, authorized, radiologyv2, centerid, radreportingshare, letterHead, } = props;
  const { patientInfo, reportInfo, radiologyInfo } = report;
  const [footerData, setFooterData] = React.useState(null as any);
  const patientObj = report[radiologyv2]?.patientInfo;
  const footerInfo = report[radiologyv2]?.authorizedLog[report[radiologyv2]?.authorizedLog?.length - 1];
  const [loading, setLoading] = React.useState(true);
  let headerBackgroundStyle = letterHead?.primaryColor ? { backgroundColor: letterHead?.primaryColor } : {};
  const primaryColor = letterHead?.primaryColor ? { color: letterHead?.primaryColor } : {};
  let styleWaterMark = letterHead?.waterMark && radreportingshare ? { padding: 0 } : null;
  // `url(${letterHead?.waterMark})`, 'backgroundPosition': 'center', 'backgroundSize': '500px', backgroundRepeat: 'no-repeat',


  React.useEffect(() => {
    // if (print) {
    if (footerInfo) {
      axios.get(`https://q1lsdba6f6.execute-api.ap-south-1.amazonaws.com/prod/radiologist/list/${centerid}/${footerInfo?.by}`).then((response) => {
        const matchedRadiologist = (response.data).filter((rad: any) => rad.userName === footerInfo?.by);
        setFooterData(matchedRadiologist[0]);
        setLoading(false);
        setTimeout(() => {
          window.print();
        }, 3000);
        // if (!radreportingshare) {

        // }


      }).catch(() => {
        setLoading(false);
        if (!radreportingshare) {
          // window.print();
        }

      });

      // }


    }
  }, []);


  if (loading) {
    return <Spinner animation="border" />
  }

  
  
  return<> <div className={"body-container radiology-report" } style={styleWaterMark ? styleWaterMark : { overflowY: 'hidden', textTransform: 'none',overflow: 'hidden', padding: 0, maxWidth: 950, margin: 0 }}>

    <div dangerouslySetInnerHTML={{ __html: authorized ? report[radiologyv2]?.signOffContent : report[radiologyv2]?.signOffContent }} style={{ color: '#000', position: 'relative', textTransform: 'none' }} className={'radiology-content' + (report[radiologyv2]?.copyPastedContent && centerid === '4d3f167a-4d62-4482-84b0-83ece4aab363' ? ' radiology-content-word' : '')} />

    <Row>
      <Col xs={12}>
        {
          <div style={{ float: 'right', marginTop: '20px', height: '100%', marginRight: 50 }}> {footerData?.signature && authorized ? <><img src={footerData?.signature} alt={'Signatore'} style={{ maxWidth: '150px', maxHeight: '150px' }} /> <br /> </> : null}  <p style={{ fontSize: '17px' }}> {footerData?.name || ''}</p>   <p style={{ fontSize: '15px' }}> {footerData?.title || ''}</p></div>
        }
      </Col>
      <Col xs={12}>
        {
          footerData?.reportFootName ? <div style={{ textAlign: 'right', color: 'rgb(155 151 151)', fontSize: '10pt', marginTop: '10px', marginRight: 50 }}>{footerData?.reportFootName} </div> : null
        }
      </Col>
    </Row>
    {/* <div style={{'pageBreakBefore': 'always'}}>
      {letterHead?.disclaimerImg && radreportingshare ? <div style={{ pageBreakBefore: 'always', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src={letterHead?.disclaimerImg} alt={'disclaimer img'} /></div> : null}
  </div> */}

  </div>
 
  </>


}

export default RadiologyReportv2;
