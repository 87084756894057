import * as React from "react";
import {Table} from "react-bootstrap";

export const ProvisonalDiagnosisPreview = (props: any)=>{
  const {record, updateCb, fieldIndex, recordIndex, title} = props;

  const {
  comments
  } = record;

  return <div className={'general-examination general-examination-preview'} >
    <div className={'field-row-preview-row'}>
      <table>
        <tr>
          <td>
           
           {comments || '-'}
          </td>
        </tr>
      </table>


  </div>
  </div>

}
