import img from '../../commons/medical-icon.png';

function PharmacyHeaderDotMatrix(props: any) {
  const {pharmacyInfo} = props;
  let pharmacy = pharmacyInfo.data[0];
  let logo = (pharmacyInfo.logo) ? (pharmacyInfo.logo) : img;

  return <thead>
  <tr>
    <th className="outer-table">
      <div className="pharma-header-name-position">
        <span className="dot-matrix-xlarge-text-bold">{pharmacy.pharmacyName}</span>
        <p><span className="dot-matrix-small-text-light">{pharmacy.pharmacyAddress}</span></p>
        {pharmacy.phoneNo ? (<span className="dot-matrix-small-text-light">Tell No: {pharmacy.phoneNo} &nbsp;&nbsp;</span>) : null}
        {pharmacy.licenseNo || pharmacy.gstNo ?
          <p>
            {pharmacy.gstNo ? <span className="dot-matrix-small-text-light">GSTIN: {pharmacy.gstNo} &nbsp;&nbsp;</span> : null}
            {pharmacy.licenseNo ? <span className="dot-matrix-small-text-light">DL No: {pharmacy.licenseNo}</span> : null}
          </p>
          : null}
      </div>

    </th>
  </tr>
  </thead>
}

export default PharmacyHeaderDotMatrix;
