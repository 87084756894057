import Printpositivehistorycard from "./Printpositivehistory";

function FamilialHistory(props: any){
  const {familialHistory} = props;
  let doms = [];



  if (familialHistory.cardiovascular && familialHistory.cardiovascular.length) {
    doms.push(<p>Cardiovascular : {familialHistory.cardiovascular.join(' and ')} </p>);
  }

  if (familialHistory.diabetes && familialHistory.diabetes.length) {
    doms.push(<p>Diabetes :  {familialHistory.diabetes.join(' and ')} </p>);
  }

  if (familialHistory.endocrine && familialHistory.endocrine.length) {
    doms.push(<p>Endocrine : {familialHistory.endocrine.join(' and ')} </p>);
  }

  if (familialHistory.hypertension && familialHistory.hypertension.length) {
    doms.push(<p>Hypertension : {familialHistory.hypertension.join(' and ')} </p>);
  }

  if (familialHistory.comments) {
    doms.push(<p>Comments : {familialHistory.comments.replaceAll(/\u21B5/g,'<br/>')}</p>);
  }

  if (!doms.length) {
    return null;
  }

  return <Printpositivehistorycard title={'Familial History'}>
   {doms.map((domElem: any, index: number) => {
     return <div key={index}>{domElem}</div>
   })}
  </Printpositivehistorycard>
}

export default FamilialHistory;
