
import { Row, Col } from 'react-bootstrap';
import * as React from "react";
import getUrlParams from "../getUrlParams";
import { addComma, reduceArrayAdd, validateNumber, AddDrPrefix, handleSelectedInvoiceConsolidated, calculateBalance, NC, getBillTotalAmount } from './utils';
import { GenerateTableBody, GenerateTableHeader } from "./TableHelper";
import moment from "moment";

function ReceiptInfo(props: any) {
  const { invoiceindex, filter } = getUrlParams(window.location.href);
  const { patientInfo, medicalCenterInfo, billInfo, aggregateInfo } = props;
  let patientObj = patientInfo;
  let fullName = patientObj.fullName;
  let age = patientObj.age && patientObj.age.years || patientObj.age.months ? ((patientObj.age.years || '0') + ' Y ' + (patientObj.age.months ? patientObj.age.months + ' M' : '')) : '-';
  let gender = patientObj.gender?.toUpperCase();
  let patientId = patientObj.uhid?.toUpperCase();
  let phone = patientObj.phone;
  let date = moment(new Date(billInfo.creation_date)).format('DD MMM YYYY & hh:mm a')?.toUpperCase();
  let invoices = Number(invoiceindex) > -1 ? [billInfo.invoices[Number(invoiceindex)]] : billInfo.invoices
  let serialNumber = 0;
  let totalCost = 0;
  let grandTotal = 0;
  let [totalPaid, totalTax, grandCost, totalDiscount, totalDiscountPercentage, cashPayment, cardPayment, netAmount, roundOff] = getBillTotalAmount(invoices);

  return <div className="body-container discharge-summary" style={{marginTop: '-25px'}}>
    
    <div className={''} style={{ padding: '0px', borderRadius: '0px' }}>
      <Row>
        <Col xs={12}><h5 className={'center-text page-header'}>Invoice</h5></Col>
        <Col xs={6}>
          <div className={'flex-container'}>
            {/* <div>
                <h6>Patient Information</h6>
              </div> */}

            <div className={'flex-div nalam-bill-header'}>
              <p><div className="label-question">Name </div><div>: {fullName}</div></p>
              <p><div className="label-question">UHID </div>: {patientId}</p>
              <p><div className="label-question">Age & Gender </div>&nbsp; : {age || '-'} & {gender || '-'}</p>
              {/* {billInfo.admissionDate  ? <p>  <div className="label-question"> Admission Date {billInfo.admissionTime ? <> & time </> : null} </div> &nbsp; : { moment((billInfo.admissionDate)).format('DD MMM YYYY')} {billInfo.admissionTime ? <> & {billInfo.admissionTime} </> : null}</p> : null}
                  {billInfo.dischargeDate  ? <p>  <div className="label-question"> Discharge Date {billInfo.dischargeTime ? <> & time </> : null} </div> &nbsp; : { moment((billInfo.dischargeDate)).format('DD MMM YYYY')} {billInfo.dischargeTime ? <> & {billInfo.dischargeTime} </> : null}</p> : null} */}
            </div>

          </div>
        </Col>
        <Col xs={6}>
          <div className={'flex-container'} style={{marginLeft: '140px'}}>
            {/* <div>
                <h6>Bill Details</h6>
              </div> */}

            <div className={'flex-div  nalam-bill-header'} >
              <p><div className="label-question">Bill No. </div><div>: {billInfo.billNo ? billInfo.billNo : billInfo.billId}</div></p>
              <p><div className="label-question">Bill Dt. & Time </div>: {date}</p>
              <p><div className="label-question">Doctor Name</div>: {AddDrPrefix(billInfo.doctorName) || '-'}</p>
              {/* {medicalCenterInfo[0].location?.city ?  <p><div className="label-question">Place of supply </div>: {medicalCenterInfo[0].placeOfSupply || medicalCenterInfo[0].location?.city}</p> : null}
                  {medicalCenterInfo[0]?.gst ? <p><div className="label-question">GST No</div>: {medicalCenterInfo[0]?.gst || '-'}</p> : null}
                  {medicalCenterInfo[0]?.panNumber ? <p><div className="label-question">PAN</div>: {medicalCenterInfo[0]?.panNumber|| '-'}</p> : null} */}
            </div>

          </div>
        </Col>
      </Row>
    </div>

    {!invoiceindex ?
      // Consolidated invoice
      <>
        <div style={{ marginTop: '20px' }}>
          <table className={'pharma-bill-table nalam-bill-table'}>
            {
              <GenerateTableHeader filter={filter} tdStyle={{ padding: '0px' }} />
            }
            <tbody>
              {
                handleSelectedInvoiceConsolidated(billInfo, filter).map((invoice: any, index: number) => {
                  // totalTax = invoice.totalTax;
                  // grandTotal = invoice.grandTotal;
                  // totalCost = invoice.totalCost;
                  // totalDiscount = invoice.totalDiscount;

                  return <GenerateTableBody invoice={invoice} index={index} filter={filter} tdStyle={{ padding: '0px' }} />
                })
              }
            </tbody>
          </table>
        </div>


      </>
      :
      <>
        <div style={{ marginTop: '20px' }}>
          <table className={'pharma-bill-table nalam-bill-table'}>
            <thead>
              <tr>
                <th>S.no</th>
                <th>Date</th>
                <th>Item</th>
                <th>Qty</th>
                <th>Amount &#8377;</th>
                <th>Discount &#8377;</th>
                <th>Tax %</th>
                <th className={'align-right'}>Total &#8377;</th>
              </tr>
            </thead>
            <tbody>
              {
                invoices.map((invoice: any) => {
                  let items = invoice.items;
                  totalPaid = (totalPaid + Number(invoice.totalPaid));
                  let date = new Date(invoice.date).toLocaleDateString();

                  if (invoice.isOnlyPayment) {
                    totalPaid += validateNumber(invoice.totalPaid);

                    serialNumber += 1;
                    return <tr>
                      <td>{serialNumber}</td>
                      <td style={{ minWidth: '100px' }}>{date}</td>
                      <td>Payment/Advance</td>
                      <td>-</td>
                      <td>{validateNumber(invoice.totalPaid)}</td>
                      <td>-</td>
                      <td>-</td>
                      <td>{validateNumber(invoice.totalPaid)}</td>
                    </tr>
                  } else {
                    return items.map((item: any, index: number) => {
                      const cost = item.cost * item.unit;
                      const discountedCost = Number(item.discount);
                      const tax = (item.tax * (item.cost - discountedCost)) / 100;
                      totalCost += validateNumber(cost);
                      // totalTax += validateNumber(tax);
                      // totalDiscount += validateNumber(discountedCost);
                      grandTotal += (((validateNumber(cost) - validateNumber(discountedCost)) + validateNumber(tax)));
                      serialNumber += 1;
                      return <tr>
                        <td>{serialNumber}</td>
                        <td style={{ minWidth: '100px' }}>{date}</td>
                        <td>{item.name}</td>
                        <td>{item.unit}</td>
                        <td>{item.cost}</td>
                        <td>{item.discount}</td>
                        <td>{item.tax}</td>
                        <td className={'align-right'}>{addComma(((cost - discountedCost) + tax))}</td>
                      </tr>
                    })
                  }

                })
              }
            </tbody>
          </table>
        </div>


      </>
    }
    <div style={{ marginTop: '5px', padding: '2px' }}>
      <Row>
        <Col xs={7}></Col>
        <Col xs={5}>
          <div style={{ marginTop: '10px', textAlign: 'right' }}>
            <Row>
              <Col xs={8} className={' billing-table-td'}><span  >Total Amount</span></Col>
              <Col xs={4} className={' billing-table-td'}  >&#8377; {validateNumber(grandCost)}</Col>
            </Row>
            {
              cashPayment > 0 ?
                <Row>
                  <Col xs={8} className={' billing-table-td'}  ><span className="  "  >Total Paid (Cash) </span></Col>
                  <Col xs={4} className={' billing-table-td'}  > &#8377; {NC(cashPayment) || '-'}</Col>
                </Row>
                : null
            }
            {
              cardPayment > 0 ?
                <Row>
                  <Col xs={8} className={' billing-table-td'}><span className="  ">Total Paid (Card) </span></Col>
                  <Col xs={4} className={' billing-table-td'}> &#8377; {NC(cardPayment) || '-'}</Col>
                </Row>
                : null
            }





          </div>


        </Col>

      </Row>



    </div>
    <div style={{ marginTop: '60px', padding: '2px' }}>
      <Row>
        <Col xs={8}> </Col>
        <Col xs={4} className={' billing-table-td'} ><span className="label-question"   style={{float: 'right'}}>Cashier Name & Sign</span></Col>
      </Row>
    </div>









  </div>
}

export default ReceiptInfo;
