import * as React from "react";
import moment from "moment";

function BillInvoiceDetails(props: any) {
  const {billInfo} = props;


  let bill = billInfo?.data[0];

  if (!bill) {
    return  null;//<h4 style={{textAlign: 'center'}}>Invalid Bill ID</h4>
  }

  return <div>
    <div className={'center-text'}>
      <span className={'dot-matrix-large-text-bold center-text'}>INVOICE{bill.printReturnBill ? ' - Return Bill' : ''}</span>
    </div>
    <div className={'invoice-details'}>
      <div className={'invoice-details-section'}>
        <p>
          <div className="label-question-120">
            <span className={'dot-matrix-large-text-bold'}>Patient Name</span>
          </div>
          <span className={'dot-matrix-large-text-light'}> : {bill.customer.name}</span>
        </p>
        <p>
          <div className="label-question-120">
            <span className={'dot-matrix-large-text-bold'}>Doctor</span>
          </div>
          <span className={'dot-matrix-large-text-light'}> : {bill.doctorName}</span>
        </p>
        <p>
          <div className="label-question-120">
            <span className={'dot-matrix-large-text-bold'}>Mobile</span>
          </div>
          <span className={'dot-matrix-large-text-light'}> : {bill.customer.phoneNo?bill.customer.phoneNo:'-'}</span>
        </p>
      </div>
      <div className={'invoice-details-section'}>
        <p>
          <div className="label-question-120">
            <span className={'dot-matrix-large-text-bold'}>Bill No</span>
          </div>
          <span className={'dot-matrix-large-text-light'}> : {bill.billNo}</span>
        </p>
        <p>
          <div className="label-question-120">
            <span className={'dot-matrix-large-text-bold'}>Date</span>
          </div>
          <span className={'dot-matrix-large-text-light'}> : {bill?.createdAt ? moment(bill?.createdAt).format('DD/MM/YYYY') : '-'}</span>
        </p>
        <p>
          <div className="label-question-120">
            <span className={'dot-matrix-large-text-bold'}>Time</span>
          </div>
          <span className={'dot-matrix-large-text-light'}> : {bill?.createdAt ? moment(bill?.createdAt).format('h:mm A') : '-'}</span>
        </p>
      </div>
    </div>
  </div>
}

export default BillInvoiceDetails;
