import { Row, Col } from 'react-bootstrap';
import moment from 'moment';

export const Nameprefix = (patient: any) => {
    const hasSalutationInName = ['miss.', 'mrs.', 'mr.', 'baby.'].some(v => (patient.fullName || patient.name).toLowerCase().includes(v));
    if (patient.salutation && patient.salutation.length > 1 && !hasSalutationInName) {
        return patient.salutation + '.' + patient.fullName;
    }

    if (patient && (!patient.gender || !patient.age)) {
        return '';
    }

    if (['miss.', 'mrs.', 'mr.', 'baby.'].some(v => (patient.fullName || patient.name).toLowerCase().includes(v))) {
        return patient.fullName
    }

    if ((patient && patient.age && patient.age.years && patient.age.years <= 3) || (!patient.age!.years && patient.age!.months)) {
        return 'Baby. '
    }

    return patient && patient.gender === 'male' ? 'Mr. ' + (patient.fullName || patient.name) : patient.gender === 'female' ? 'Ms. ' + (patient.fullName || patient.name) : '';
}

function LabReportv2(props: any) {
    const { patientInfo, emptyHeader } = props;
    let patientObj = patientInfo;
    let fullName = patientObj?.fullName;
    let address = [];
    if (patientObj?.address)
        address.push(patientObj.address)
    if (patientObj?.state)
        address.push(patientObj.state)

    let age = patientObj?.age && (patientObj?.age?.years || patientObj?.age?.months) ? ((patientObj?.age?.years || '-') + ' Y ' + ((patientObj?.age?.months ? patientObj?.age?.months + ' M' : '') || '')) : '-';
    let gender = patientObj?.gender;
    let patientId = patientObj?.uhid;
    const { labTest } = props;

    const collectionLevels = (labTest?.collectionLevels || []).filter((collectionLevel: any) => collectionLevel?.completed);
    let Tests: any = [];

    if (labTest.tests) {

        labTest.tests.forEach((test: any) => {
            let len = 0;
            if (test.fields) {
                Tests.push(test);
            } else {
                (test.tests).forEach((field: any) => {
                    if (field?.result) {
                        len += 1;
                    }
                });

                if (len) {
                    Tests.push(test);
                }
            }

        })
    }

    let date = moment(new Date(labTest.creation_date)).format('DD-MM-YYYY');

    return <div><div className="body-container">
        {<h3 className={'color-black'}> <b> Lab Report</b> </h3>}
        <Row>
            <Col xs={6}>
                <div className={'flex-container'}>
                    {/* <div >
            <h5 className={'color-black'}> <b> Patient Information </b></h5>
          </div> */}
                    <div className={'gray-parent graybox '}>
                        <div className={'flex-div'}>
                            <p style={{ color: '#01334E', fontSize: '17px' }}><div className="label-question color-black" >UHID </div>  <span style={{ fontWeight: 'normal', paddingLeft: '65px' }} className={'color-black'}> : {patientId}</span></p>
                            <p style={{ fontSize: '17px' }}><div className="label-question color-black">Patient Name </div><div className="label-answer color-black" style={{ fontWeight: 'normal', paddingLeft: '8px' }}> : {Nameprefix(patientObj)}</div></p>
                            <p style={{ color: '#01334E', fontSize: '17px' }}><div className="label-question color-black" >Age </div>      <span style={{ fontWeight: 'normal', paddingLeft: '63px' }} className={'color-black'}> : {age}</span></p>
                            <p style={{ color: '#01334E', fontSize: '17px' }}><div className="label-question color-black" >Gender </div>      <span style={{ fontWeight: 'normal', paddingLeft: '59px', textTransform: 'capitalize' }} className={'color-black'}> : {gender}</span></p>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={6}>
                <div className={'flex-container'}>
                    <div className={'gray-parent graybox'}>
                        <div className={'flex-div '}>
                            <p style={{ color: '#01334E', fontSize: '17px' }}><div className="label-question color-black" >Registered On</div>  <span style={{ fontWeight: 'normal', paddingLeft: '14px' }} className={'color-black'}> : {labTest?.visitInformation?.visit_creation_date ? moment(new Date(labTest?.visitInformation?.visit_creation_date)).format('DD-MM-YYYY hh:mm a') : '-'}</span></p>
                            <p style={{ color: '#01334E', fontSize: '17px' }}><div className="label-question color-black" >Collected On </div>  <span style={{ fontWeight: 'normal', paddingLeft: '22px' }} className={'color-black'}> : {labTest.collectionLevels[0].collectionLevel ? moment(new Date(labTest.collectionLevels[0].date)).format('DD-MM-YYYY hh:mm a') : '-'}</span></p>
                            <p style={{ color: '#01334E', fontSize: '17px' }}><div className="label-question color-black" >Reported On </div>  <span style={{ fontWeight: 'normal', paddingLeft: '25px' }} className={'color-black'}> : {labTest.completed ? moment(new Date(labTest.completed_at)).format('DD-MM-YYYY hh:mm a') : '-'}</span></p>
                            <p style={{ color: '#01334E', fontSize: '17px' }}><div className="label-question color-black" >Ref. By </div>  <span style={{ fontWeight: 'normal', paddingLeft: '71px' }} className={'color-black'}> : {labTest.docName.indexOf('Dr.') > -1 ? ' ' : ' Dr.'} {labTest.docName}</span></p>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={12}><h5> </h5></Col>
        </Row>
    </div>

        {Tests?.length ? <div style={{ marginBottom: '-4%' }} className={'body-container'}>
            <h5 className={'color-black'}> <b> Investigation Report</b> </h5>
        </div>
            : null}
            
        {
           
            Tests.map((test: any, testIndex: number) => {
            
                let group = test.group;
                let indexValue = test.tests.findIndex(function(test: any) {
                    return test.result 
                  });
                  
                let spanValue = test.tests.length;
                return <div className={"body-container" + (testIndex > 0 ? ' page-break-avoid' : '')}>
                    <div key={testIndex}>
                        <div>
                            <table>
                                <tr ><th colSpan={3} style={{ border: 'none', background: 'none', paddingLeft: 0 }}><h6 style={{ fontSize: '18px', textTransform: 'capitalize' }} className={'color-black'}> <b>{test.title ? test.title : test.name ? test.name : ''} </b></h6></th></tr>
                                <tr>
                                    <th style={{ width: '210px' }} className={'color-black'}>Specimen</th>
                                    <th style={{ width: '320px' }} className={'color-black'}>Test Name</th>
                                    <th className={'color-black'}>Result</th>
                                    <th className={'color-black'}>Units</th>
                                    {test.noUnit ? null : <th className={'color-black'}>Reference Value</th>}
                                </tr>

                                {
                                    (test.fields || test.tests).map((field: any, index: number) => {
                                        if (!field.range) {
                                            field.range = {};
                                        }
                                        const noRange = field?.range?.min === 0 && field?.range?.max === 0;
                                        if (noRange) {
                                            field.range = {};
                                            field.range.text = '-';
                                        }

                                        let isInvalid = field?.type === 'text' && noRange ? false : field?.result && Number(field?.result) < field?.range?.min || Number(field?.result) > field?.range?.max;
                                        let ResultRange: any = (field?.range?.min >= 0 || field?.range?.text) ? <td className={'color-black'}>{field?.range?.text ? field?.range?.text : (field?.range?.min + '-' + field?.range?.max)} </td> : null;

                                        if (gender && field?.range && field?.range[gender]) {
                                            ResultRange = <td className={'color-black'}>{field?.range[gender]?.min} - {field?.range[gender]?.max}</td>;
                                            isInvalid = field?.type === 'text' && noRange ? false : field?.result && Number(field?.result) < field?.range[gender]?.min || Number(field?.result) > field?.range[gender]?.max;
                                        }
                                        if (!field?.result) {
                                            return null;
                                        }
                                        
                                        return <tr key={index} className={'table-row-investigations'}>
                                            {index === indexValue ? <td rowSpan={spanValue}>{group ? group : '-'}</td>:null}
                                            <td style={{ width: '320px' }} className={'color-black'}>{field?.name}</td>
                                            <td className={'color-black'}>{field?.result ? <span style={isInvalid ? { fontWeight: 'bold', color: '#000' } : {}}> {field?.result} {isInvalid ? <sup>*</sup> : null} </span> : ''}</td>
                                            <td style={{ textTransform: 'lowercase' }} className={'color-black'}>{field?.unit || '-'}</td>
                                            {test.noUnit ? null : ResultRange}
                                        </tr>

                                    })
                                }
                            </table>
                        </div>
                    </div>


                </div>


            })

        }
        {
            emptyHeader ? <div>
                <div className="end-of-report"><span>END OF THE REPORT</span></div>
                <div className="footer-doctor color-black" style={{
                    float: 'right',
                    marginTop: '40px',
                    'paddingRight': '50px',
                    'textAlign': 'right',

                }}>
                    Lab Technician
                </div>
            </div> : null
        }


    </div>
}

export default LabReportv2;
