import PaymentItem from "./paymentItem";
import * as React from "react";
import {useEffect} from "react";

function BillFooter(props: any) {
  const {billItemsLength, bill, billTotal, isGST, paymentModes, pharmacy, reportType, aggregates} = props;

  if (aggregates && bill.reportType !== 'pharma-ip-bill-no-wise') {
    bill.roundOff = aggregates.totalAmount - aggregates.totalReturnAmount - billTotal.grossAmount - (billTotal.cgst || 0) - (billTotal.sgst || 0);
  }
  useEffect(()=>{
    setTimeout(function () {
      window.print();
    }, 1000)
  }, [])

  return <>
    <div className={'dashed-border-top-bottom flex-row bill-payment-amount'}>
      <div className={'bill-amount-details'}>
        <span className={'dot-matrix-large-text-bold'}>Total Items: &nbsp;
          <span className={'dot-matrix-large-text-light'}>{billItemsLength}</span>
        </span>
        {isGST ? (<span className={'dot-matrix-large-text-bold'}>SGST: &nbsp;
          <span className={'dot-matrix-large-text-light'}>{billTotal?.sgst?.toFixed(2) || '-'}</span> &nbsp; CGST: &nbsp;
          <span className={'dot-matrix-large-text-light'}> {billTotal?.cgst?.toFixed(2) || '-'}</span>
        </span>) : null}
      </div>
      <div className={'bill-amount-details'}>
        <PaymentItem label={bill.printReturnBill ? 'Total Return Amount' : 'Gross Amount'} value={bill.printReturnBill ? bill.totalReturnAmount : billTotal?.grossAmount?.toFixed(2)}/>
        {isGST ? <PaymentItem label={'Total GST'} value={((billTotal?.sgst || 0) + (billTotal?.cgst || 0)).toFixed(2)}/> : null}
        {reportType === 'pharma-ip-bill-no-wise' ? <PaymentItem label={'Total Paid'} value={bill?.totalPaid?.toFixed(2) || '0'}/> : <PaymentItem label={'Discount'} value={bill?.discount?.toFixed(2) || '0'}/>}
        {(bill?.roundOff > 1) || (bill?.roundOff < -1) ? null : <PaymentItem label={'Round Off'} value={-bill?.roundOff?.toFixed(2)}/>}
      </div>
    </div>
    <div className={'dashed-border-bottom flex-row bill-payment-amount'}>
      <div className={'bill-amount-details'}>
        <span className={'dot-matrix-large-text-bold'}>Payment Mode: &nbsp;
          <span className={'dot-matrix-large-text-light'}>{bill?.payments && bill?.payments[0]?.mode === 'Credit' ? bill?.payments[0]?.mode :  paymentModes.length > 0 ? paymentModes.join(' & ') : '-'}</span>
        </span>
      </div>
      <div className={'bill-amount-details'}>
      {reportType === 'pharma-ip-bill-no-wise' ? <PaymentItem label={'Balance'} value={aggregates?.balance?.toFixed(2)}/> : <PaymentItem label={bill.printReturnBill ? 'Return Amount' : 'Grand Total'} value={bill.printReturnBill ? bill.totalReturnAmount : (aggregates ? (Number(aggregates.totalAmount || 0) - Number(aggregates.totalReturnAmount || 0))?.toFixed(2) : bill?.totalAmount?.toFixed(2))}/>}
      </div>
    </div>
    <div className={'flex-row-space-between'}>
      <div className={'footer-note'}>
        {pharmacy.footerMessage ? <span className={'dot-matrix-xsmall-text-bold'}>{pharmacy.footerMessage}</span> : null}
      </div>
      <div className={'footer-signature'}>
        <span className={'dot-matrix-large-text-bold right-text'}>Authorised Signature</span>
      </div>
    </div>
  </>
}

export default BillFooter;
