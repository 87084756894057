import Printpositivehistorycard from "./Printpositivehistory";

function Allergyhistory(props: any){
  const {allergyHistory} = props;
  const {medicalAllergiesSelected,medicalAllergies, foodAllergiesSelected, foodAllergies, latexAllergiesSelected, latexAllergies, otherAllergiesSelected, otherAllergies} = allergyHistory;

  if (!medicalAllergiesSelected && !foodAllergiesSelected && !latexAllergiesSelected && !otherAllergiesSelected ) {
    return null;
  }

  return <> 
  
  { medicalAllergiesSelected  ?  
    <Printpositivehistorycard title={'Medical Allergies'}>
      <table className='no-border-class' cellPadding={'0'} cellSpacing={'0'}>
        <tr>
          <td>Yes { medicalAllergies.length ? '('+medicalAllergies.join(', ')+')' : null }</td>
        </tr>
      </table>
    </Printpositivehistorycard> : null}
    { foodAllergiesSelected  ?  
    <Printpositivehistorycard title={'Food Allergies'}>
      <table className='no-border-class' cellPadding={'0'} cellSpacing={'0'}>
        <tr>
          <td>Yes { foodAllergies.length ? '('+foodAllergies.join(', ')+')' : null }</td>
        </tr>
      </table>
    </Printpositivehistorycard> : null}
    { latexAllergiesSelected  ?  
    <Printpositivehistorycard title={'Latex Allergies'}>
      <table className='no-border-class' cellPadding={'0'} cellSpacing={'0'}>
        <tr>
          <td>Yes { latexAllergies.comment.length ? latexAllergies.comment : null }</td>
        </tr>
      </table>
    </Printpositivehistorycard> : null}
    { otherAllergiesSelected  ?  
    <Printpositivehistorycard title={'Other Allergies'}>
      <table className='no-border-class' cellPadding={'0'} cellSpacing={'0'}>
        <tr>
          <td>Yes { latexAllergies.comment.length ? otherAllergies.comment : null }</td>
        </tr>
      </table>
    </Printpositivehistorycard> : null}
  
  </>
}

export default Allergyhistory;
