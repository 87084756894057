import reportDecider from './pharmaAnalyticsCommons'
import {addComma} from "../bills/utils";


function PharmaAnalyticsTitleCard(props: any) {
    const {aggregates,reportSubType} = props

    if(!aggregates)
      return <div className="pa-report-name">{reportDecider.analyticsReportHeader[reportSubType]}</div>
    else
      return  <div className="pa-title-card">
        <div className="pa-report-name">{reportDecider.analyticsReportHeader[reportSubType]}</div>
        <div className="pa-stat-card">
            {
                Object.keys(aggregates).map( (key: any, index: number) => {
                    return  <div className={`${reportSubType === 'collection-report' || reportSubType === 'bill-payments' ? 'pa-stat-small' : 'pa-stat'}`} key={index}>{key}
                                <p className="pa-center-text"><span className="pa-stat-number">{addComma(aggregates[key])}</span></p>
                            </div>
                  })
            }
        </div>
    </div>
  }

  export default PharmaAnalyticsTitleCard;
