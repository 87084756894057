import * as React from "react";
import {Col, Row} from "react-bootstrap";
import moment from "moment";
import { addComma, AddDrPrefix, validateNumber } from "../bills/utils";

const Nameprefix = (patient: any) => {
  if (['miss.', 'mrs.', 'mr.', 'baby.'].some(v => patient.name.toLowerCase().includes(v))) {
    return '';
  }

  if ((patient && patient.age && patient.age.years && patient.age.years <= 3) || (!patient.age!.years && patient.age!.months)) {
    return 'Baby. '
  }

  return patient && patient.gender === 'male' ? 'Mr. ' : patient.gender === 'female' ? 'Ms. ' : '';
}

export function BillingItemizedReport(props: any) {
  const {medicalCenterInfo, startDate, endDate, report, print, departmentfilter} = props;
  const {patientInfo, reportInfo, radiologyInfo} = report;
  const patientObj = patientInfo;
  const [departments, setDepartments] = React.useState([] as any);
  const [reports, setReport] = React.useState([] as any);
  const [billCount, setCount] = React.useState({
    cash: 0,
    card: 0,
    cheque: 0,
    rtgs: 0,
    totalDiscount: 0,
    totalDue: 0,
    totalAdvance: 0,
    totalAmount: 0,
    totalReturns: 0,
  } as any);

  console.log(report);
  React.useEffect(() => {
    const types: any = [];
        const patiensList = [];
        let uniqueCount = 0;
        let billingGroups: any = {};
    const amounts = {
        cash: 0,
        card: 0,
        cheque: 0,
        credit: 0,
        rtgs: 0,
        totalDiscount: 0,
        totalDue: 0,
        totalAdvance: 0,
        totalAmount: 0,
        totalPaid: 0,
        totalReturns: 0,
      };

      setCount({ ...amounts });
      const results: any = [];

    let additionalDepartments = [] as any;
    report.forEach((bill: any, index: number) => {
        bill.invoices = typeof bill.invoices === 'string' ?  JSON.parse(bill.invoices) : bill.invoices;
            bill.invoices.forEach((invoice: any, iIndex: number) => {
              let type = '';
              if (invoice.returnedItems) {
                type = 'returns'
                amounts.totalReturns += -1 * validateNumber(invoice.returnedAmount);
                Object.values(invoice.returnedItems).forEach((item: any) => {
                    results.push({
                        sno: bill.sno,
                        patientId: bill.patientId,
                        id: bill.uhid,
                        name: bill.fullName,
                        departmentType: item.type,
                        department: bill.department?.toUpperCase()  + (bill.unit ? ' - '+bill.unit : ''),
                        createdAt: moment(invoice.returnDate).format('DD-MM-YYYY HH:mm'),
                        billId: (bill.billNo || bill.billId),
                        purchaseAmount: -1 * Number(item.returnedAmount),
                        amountInNumber: 0,
                        itemName: item.name,
                        itemCost: item.cost,
                        itemQuantity: item.unit || '-',
                        cancelApproveBy: '-',
                        type,
                        billUid: bill.billId,
                        billIndex: iIndex,
                        addedBy: bill.addedBy,
                        visitType: bill.ipNumber ? 'IP' : 'OP'
                        // others: method !== 'Cash' && method !== 'Credit' &&  method !== 'Card' ? YesText : '-'
                      });
                });
              }
              
              if (!bill.invoices[0]?.isOnlyPayment && bill.invoices[0]?.items) {
                  type = 'sales'
                  bill.invoices[0].items.forEach((item: any) => {
                    results.push({
                        sno: bill.sno,
                        patientId: bill.patientId,
                        id: bill.uhid,
                        name: bill.fullName,
                        departmentType: item.type,
                        department: bill.department?.toUpperCase()  + (bill.unit ? ' - '+bill.unit : ''),
                        createdAt: moment(bill.createdAt).format('DD-MM-YYYY HH:mm'),
                        billUnit: bill.billUnit,
                        doctorName: bill.doctorName,
                        billId: (bill.billNo || bill.billId),
                        purchaseAmount: (Number(item?.cost) * Number(item?.unit)) - (item.discount || 0),
                        itemName: item.name,
                        itemCost: item.cost,
                        itemQuantity: item.unit || '-',
                        cancelApproveBy:  bill.cancelApproveBy || '-',
                        type,
                        billUid: bill.billId,
                        billIndex: iIndex,
                        addedBy: bill.addedBy,
                        visitType: bill.ipNumber ? 'IP' : 'OP'
                        // others: method !== 'Cash' && method !== 'Credit' &&  method !== 'Card' ? YesText : '-'
                      });
                      const itemType = medicalCenterInfo?.listOfDepartments?.find((department: any) => department.name === item.type);
                      const existingRecord = additionalDepartments?.find((department: any) => department.name === item.type);
                      if (!itemType && !existingRecord) {
                        additionalDepartments.push({name: item.type});
                      }
                  })
              }
              let totalAmount: any = 0;
              let cost = 0, discountedCost = 0, tax = 0, totalCost = 0, paid = 0;
              const method = invoice.method;
              paid = Number(invoice.totalPaid || 0);

              amounts.cash += validateNumber(invoice.cash);
              amounts.card += validateNumber(invoice.card);
              amounts.credit += validateNumber(invoice.totalAmount);


              invoice.items.forEach((item: any) => {
                  if (isNaN(item.tax)) {
                    item.tax = 0; 
                  }
                cost = item.cost * item.unit;
                type = item.type;
                discountedCost = Number(item.discount || 0);
                tax = (item.tax * (cost - discountedCost)) / 100;
                totalCost = (((cost - discountedCost) + tax));
                totalAmount += totalCost;
          
              });
              amounts.totalAmount += totalAmount;
              amounts.totalDiscount += discountedCost;
              amounts.totalPaid += paid;
              const credit = validateNumber(invoice.totalPaid) - (validateNumber(invoice.cash) + validateNumber(invoice.card));
              
            });
      });
      // }


      // Not a great way of doing have to find a better way to do it
      
      let department : any = [{name:'All Department'}];
        department = department.concat(medicalCenterInfo?.listOfDepartments);
        department = department.concat(additionalDepartments);
        setDepartments(department);

        if(departmentfilter?.toUpperCase() !== 'ALL DEPARTMENT'){
          let filterResults : any = [];
          filterResults = results.filter((bill: any)=>bill.departmentType?.toLowerCase() === departmentfilter?.toLowerCase())
          setReport(filterResults);
        }
        else{
          setReport(results);
        }
        setCount(amounts);
    if (print) {
      window.print();
    }
  }, []);

 

  return  <>
    <div className="pa-title-card">
        <div className="pa-report-name">{'Billing Itemized report'}</div>
    </div>
    <div className="pa-date-range">
                <div className="pa-printed-on">Report Printed On   : {new Date().toLocaleString()}</div>
                {(startDate && endDate)?<div className="pa-printed-range" style={{paddingRight:'20px'}}>Date Range   : {startDate} - {endDate}</div>: null}
              </div>
    <div className={'report-stats'} style={{ background: '#FFF', justifyContent: 'center' }}>
    
    </div>
    <br/>
    <br/>
    <div>
    <>
    <div className={'reports-main-container'}>

      <div className={'reports-table-container'}>

      <table className={'pa-table'}>
                <thead>
                <tr>
                  <th key={"td-1"}>S.No</th>
                  <th key={"td-2"}>UHID</th>
                  <th key={"td-3"}>Patient Name</th>
                  <th key={"td-4"}>Bill ID</th>
                  <th key={"td-5"}>Bill Date</th>
                  <th key={"td-6"}>Department</th>
                  <th key={"td-7"}>Item</th>
                  <th key={"td-8"}>Type</th>
                  <th key={"td-9"}>Item Cost</th>
                  <th key={"td-10"}>Quantity</th>
                  <th key={"td-11"}>Purchase Amount</th>
                </tr>
                </thead>
                <tbody>
                  {
                    reports.map(( item: any, index: number) =>{
                      return <tr key={"tr-"+index}>
                        <td>{index+1}</td>
                        <td>{item.id}</td>
                        <td>{item.name}</td>
                        <td>{item.billId}</td>
                        <td>{item.createdAt}</td>
                        <td>{item.departmentType}</td>
                        <td>{item.itemName}</td>
                        <td>{item.type}</td>
                        <td>{item.itemCost}</td>
                        <td>{item.itemQuantity}</td>
                        <td>{item.purchaseAmount}</td>
                      </tr>
                      }
                    )
                  }
                </tbody>
              </table>
      </div>
    </div>
  </>
  </div> </>

}

export default BillingItemizedReport;
