
import * as React from "react";
import {Table} from "react-bootstrap";

export const RespiratorysystemPreview = (props: any)=>{
  const {record, updateCb, fieldIndex, recordIndex} = props;

  const {
    chestWallMovement,
    chestWallComments,
    bilateralAirentry,
    bilateralAirentryComments,
    breathSound,
    breathsoundComments,
    addedSounds,
    addedSoundsComments,
    trachealPosition,
    trachealPositionComments,
    comments,
  } = record;


  return <div className={'general-examination general-examination-preview'}>
    <div className={'field-row-preview-row'}>
      <table>
        <tr>
          <td colSpan={2}>
            <span>Chest wall movement with respiration</span>
            <br/>
            {chestWallMovement || '-'}
          </td>
          {<td colSpan={2}>
            <span>Chest wall movement comments</span>
            <br/>
            {chestWallComments || '-'}
          </td>}
        </tr>

        <tr>
          <td colSpan={2}>
            <span>Bilateral Air entry</span>
            <br/>
            {bilateralAirentry || '-'}
          </td>
          {<td colSpan={2}>
            <span>Bilateral Air entry comments</span>
            <br/>
            {bilateralAirentryComments || '-'}
          </td>}
        </tr>
        <tr>
          <td colSpan={2}>
            <span>Breath sound</span>
            <br/>
            {breathSound || '-'}
          </td>
          {<td colSpan={2}>
            <span>Breath sound comments</span>
            <br/>
            {breathsoundComments || '-'}
          </td>}
        </tr>
        <tr>
          <td colSpan={2}>
            <span>Added sound  </span>
            <br/>
            {addedSounds || '-'}
          </td>
          {<td colSpan={2}>
            <span>Added sound comments</span>
            <br/>
            {addedSoundsComments || '-'}
          </td>}
        </tr>
        <tr>
          <td colSpan={2}>
            <span>Tracheal position</span>
            <br/>
            {trachealPosition || '-'}
          </td>
          {<td colSpan={2}>
            <span>Tracheal position comments</span>
            <br/>
            {trachealPositionComments || '-'}
          </td>}
        </tr>
        <tr>
          <td>
            <span>Comments</span>
            <br/>
            {comments || '-'}
          </td>
        </tr>
      </table>
    </div>
  </div>

}
