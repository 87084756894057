import * as React from "react";
import BillInvoiceDetails from "./billInvoiceDetails";
import BillFooter from "./billFooter";
import moment from "moment";
import VisitBillDetails from "./visitBillDetails";

function percentageToNumber(percentage: number, total: number) {
  return (percentage / 100) * total;
}

function BillTable(props: any) {
  const {billInfo, reportType, ipBills, ipBillItems, pharmacyInfo, selectedPatient, selectedVisit, aggregates} = props;


  let bill = billInfo?.data[0];
  if (bill) {
    bill.roundOff = bill.printReturnBill ? (Number(bill.totalReturnAmount) * -1) : bill.roundOff;
  }

  if (!bill && !ipBillItems && !ipBills) {
    return <h4 style={{textAlign: 'center'}}>Invalid Bill ID</h4>
  }

  let billItems;
  if (ipBillItems || bill) {
    billItems = selectedVisit ? ipBillItems : bill?.billItems;
  }

  let pharmacy = pharmacyInfo.data[0];
  let isGST = pharmacy.gst;

  let billTotal = {
    cgst: 0,
    sgst: 0,
    grossAmount: 0,
    serialNumber: 0
  }

  let paymentModes: string[] = []

  if (bill) {
    for (const payment of bill.payments) {
      if(payment.amount) {
        paymentModes.push(payment.mode)
      }
    }
  } else {
    bill = {
      discount: 0, 
      totalAmount: 0,
      totalPaid: 0,
      balance: 0
    };
  }

  return <div>
    {selectedVisit ? <VisitBillDetails selectedPatient={selectedPatient} selectedVisit={selectedVisit} itemized={ipBillItems ? true : false}/> : <BillInvoiceDetails billInfo={billInfo}/>}
    {billItems ? <>{ reportType === 'pharma-ip-bill-items' ? 
    <tr className={'dotted-header'}>
      <th className={'th-large'}>Bill #</th>
      <th className={'th-small'}>Bill Date</th>
      <th className={'th-xlarge'}>Particulars</th>
      <th className={'th-small'}>Expiry</th>
      <th className={'align-center'}>Qty</th>
      <th className={'align-right'}>{bill.printReturnBill ? 'Return' : ''}Amount</th>
    </tr> : 
    <tr className={'dotted-header'}>
      <th className={'th-xlarge'}>Particulars</th>
      <th className={'th-large'}>{reportType === 'pharma-ip-bill-items' ? 'Bill #' : 'Batch No'}</th>
      {reportType === 'pharma-ip-bill-items' ? <th className={'th-small'}>Bill Date</th> : null}
      <th className={'th-small'}>Expiry</th>
      {/*<th className={'th-medium'}>MRP</th>*/}
      <th className={'align-center'}>Qty{bill.printReturnBill ? ' bought' : ''}</th>
      {bill.printReturnBill ? <th className={'th-large'}>Return qty</th> : null}
      {/*{ isGST ? <th className={'th-xsmall'}>Tax</th> : null}*/}
      <th className={'align-right'}>{bill.printReturnBill ? 'Return' : ''}Amount</th>
    </tr>}
    {billItems?.map((item:any) => {
      billTotal.serialNumber++;
      billTotal.grossAmount += item.total;
      bill.roundOff += bill.printReturnBill ? (Number(item.returnQuantity || 0) * Number(item.rate)) : 0

      if (selectedVisit) {
        bill.discount += item.discountValue || 0;
        bill.totalAmount += item.totalAmount || 0;
      }
      if(item?.purchaseItem?.cgstPercentage || item?.cgstPercentage) {
        let taxValue = percentageToNumber(item?.purchaseItem?.cgstPercentage || item?.cgstPercentage, item.total);
        billTotal.cgst+= taxValue
        if(isGST) {
          billTotal.grossAmount -= taxValue
        }
      }

      if(item?.purchaseItem?.sgstPercentage || item?.sgstPercentage) {
        let taxValue = percentageToNumber(item?.purchaseItem?.sgstPercentage || item?.sgstPercentage, item.total);
        billTotal.sgst += taxValue
        if(isGST) {
          billTotal.grossAmount -= taxValue
        }
      }

      return (reportType === 'pharma-ip-bill-items' ? 
      <tr>
        <td>{item.billNo || '-'}</td>
        <td>{moment(item?.createdAt).format('DD/MM/YY')  || '-'}</td>
        <td>{item.name}</td>
        <td>{moment(item?.purchaseItem?.expiry || item?.expiry).format('MM/YY')  || '-'}</td>
        <td className={'center-text'}>{bill.printReturnBill ? (Number(item.returnQuantity) * -1) : item.quantity || '-'}</td>
        <td className={'right-text'}>{bill.printReturnBill ? (Number(item.returnQuantity || 0) * Number(item.rate) * -1).toFixed(2) : (item.total)?.toFixed(2) || '-'}</td>
      </tr> :
      <tr>
        <td>{item.name}</td>
        <td>{reportType === 'pharma-ip-bill-items' ? item.billNo || '-' : item.batch  || '-'}</td>
        {reportType === 'pharma-ip-bill-items' ? <td>{moment(item?.createdAt).format('DD/MM/YY')  || '-'}</td> : null}
        <td>{moment(item?.purchaseItem?.expiry || item?.expiry).format('MM/YY')  || '-'}</td>
        {/*<td>{item?.rate || '-'}</td>*/}
        <td className={'center-text'}>{bill.printReturnBill ? item.quantityBought : item.quantity || '-'}</td>
        {bill.printReturnBill ? <td className={'center-text'}>{item.returnQuantity || 0}</td> : null}
        {/*{ isGST ? <td className={'center-text'}>{(item?.purchaseItem?.cgstPercentage || 0) + (item?.purchaseItem?.sgstPercentage || 0)  || '-'}</td> : null }*/}
        <td className={'right-text'}>{bill.printReturnBill ? (Number(item.returnQuantity || 0) * Number(item.rate)).toFixed(2) : (item.total)?.toFixed(2) || '-'}</td>
      </tr>)
    })}</> : <><tr className={'dotted-header'}>
    <th className={'th-xlarge'}>Bill No</th>
    <th className={'th-xlarge'}>Bill Date</th>
    <th className={'th-large force-right-text'}>Amount</th>
    {/*<th className={'th-medium'}>MRP</th>*/}
    <th className={'th-large force-right-text'}>Paid</th>
    {/*{ isGST ? <th className={'th-xsmall'}>Tax</th> : null}*/}
  </tr>
  {ipBills?.map((item:any) => {
    billTotal.serialNumber++;
    billTotal.grossAmount += item.totalAmount || 0;

    if (selectedVisit) {
      bill.discount += item.discountValue || 0;
      bill.totalAmount += item.totalAmount || 0;
      bill.totalPaid += item.totalPayment || 0;
      bill.balance += (item.totalAmount || 0 - item.totalPayment || 0);
    }

    if(item?.purchaseItem?.cgstPercentage || item?.cgstPercentage) {
      let taxValue = percentageToNumber(item?.purchaseItem?.cgstPercentage || item?.cgstPercentage, item.total);
      billTotal.cgst+= taxValue
      if(isGST) {
        billTotal.grossAmount -= taxValue
      }
    }

    if (item?.tax) {
      let taxValue = percentageToNumber(item?.tax, item.total);
      billTotal.sgst += taxValue
      if(isGST) {
        billTotal.grossAmount -= taxValue
      }
    }

    if(item?.purchaseItem?.sgstPercentage || item?.sgstPercentage) {
      let taxValue = percentageToNumber(item?.purchaseItem?.sgstPercentage || item?.sgstPercentage, item.total);
      billTotal.sgst += taxValue
      if(isGST) {
        billTotal.grossAmount -= taxValue
      }
    }

    return (<tr>
      <td>{item.billNo || 'Payment/Advance'}</td>
      <td>{moment(item?.createdAt).format('DD/MM/YY HH:MM a')  || '-'}</td>
      <td className={'right-text'}>{item.billNo?.indexOf('RT-NO-') > -1 ? (Number(item.returnAmount || 0) * -1)?.toFixed(2) : item.totalAmount?.toFixed(2)  || '-'}</td>
      <td className={'right-text'}>{item.billNo?.indexOf('RT-NO-') > -1 ? Number(item.returnedAmount || 0)?.toFixed(2) : item.totalPayment?.toFixed(2)  || '-'}</td>
    </tr>)
  })}</>}
    <BillFooter
      billItemsLength={billItems?.length || ipBills?.length}
      bill={bill}
      aggregates={aggregates}
      reportType={reportType}
      billTotal={billTotal}
      isGST={isGST}
      paymentModes={paymentModes}
      pharmacy={pharmacy}/>
  </div>
}

export default BillTable;
