import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import '../App-v2.css';
import axios from "axios";
import { DisplaysecureImage } from "./DisplaySecureImage";
import * as React from "react";


function getFileUrl(url: string) {
  return axios.get(url);
}

const RenderInfo = (props: any) => {
  const { title, value } = props;
  return <div className={'info-record'}>
    <div className={'info-label'}>
      {title}
    </div>
    <div className={'info-value'}>
      <TextAreaText text={value} />
    </div>
  </div>
}

function TextAreaText(props: { text: string }) {
  let text = props.text;
  if (!text) {
    return null;
  }

  text = text.trim();

  return <><div dangerouslySetInnerHTML={{ __html: text }} className={'preline-whitespace'} /></>;
}


const Nameprefix = (patient: any) => {
  if (patient.salutation) {
    return patient.salutation+'.';
  }
  if (['miss.', 'mrs.', 'mr.', 'baby.'].some(v => patient.fullName.toLowerCase().includes(v))) {
    return '';
  }

  if ((patient && patient.age && patient.age.years && patient.age.years <= 3) || (!patient.age!.years && patient.age!.months)) {
    return 'Baby. '
  }

  return patient && patient.gender === 'male' ? 'Mr. ' : patient.gender === 'female' ? 'Ms. ' : '';
}

function DischargeSummary(props: any) {

  const { patientInfo, visit, doctors, reportType, headerInfo } = props;
  let adviceInNextPage = true;

  if (headerInfo?.emptyheader) {
    adviceInNextPage = false;
  }
  let patientObj = patientInfo;
  let fullName = patientObj.fullName;

  // JsBarcode(".barcode").init();
  let address = [];
  if (patientObj.address)
    address.push(patientObj.address)
  if (patientObj.state)
    address.push(patientObj.state)

  let age = patientObj.age && patientObj.age.years || patientObj.age.months ? ((patientObj.age.years || '-') + ' Y ' + (patientObj.age.months ? (patientObj.age.months + ' M') : '')) : '-';
  let gender = patientObj.gender;
  let patientId = patientObj.uhid;
  let doctor = doctors.find((doctor: any) => doctor.name === visit.docName);

  let visitobj = visit;
  let { dischargeSummary } = visitobj;
  if (typeof dischargeSummary === 'string') {
    dischargeSummary = JSON.parse(dischargeSummary);
  }
  let docName = doctor && doctor.name ? doctor.name : visit.docName;
  let clinicalNotes: any;
  let date = moment(new Date(visitobj.creation_date)).format('DD-MM-YYYY');

  const patientSummary = (dischargeSummary || []).find((summary: any) => summary.id === 'patient-info');
  const { emergencyContact, city, state, country, areaCode } = patientSummary;


  const medicalTeam = (dischargeSummary || []).find((summary: any) => summary.id === 'medical-team');
  const complaints = (dischargeSummary || []).find((summary: any) => summary.id === 'complaints');
  const diagnosisObj = (dischargeSummary || []).find((summary: any) => summary.id === 'diagnosis');
  const reasonOfAdmission = (dischargeSummary || []).find((summary: any) => summary.id === 'admission-reason');
  const historyObj = (dischargeSummary || []).find((summary: any) => summary.id === 'patient-history');
  const admissionCondition = (dischargeSummary || []).find((summary: any) => summary.id === 'admission-condition');
  const investigations = (dischargeSummary || []).find((summary: any) => summary.id === 'investigations');
  const opinion = (dischargeSummary || []).find((summary: any) => summary.id === 'opinion');
  const procedures = (dischargeSummary || []).filter((summary: any) => summary.id === 'procedure');
  const courseInHospital = (dischargeSummary || []).find((summary: any) => summary.id === 'course-in-hospital');
  const dischargeCondition = (dischargeSummary || []).find((summary: any) => summary.id === 'discharge-and-advice');
  const manageImages = (dischargeSummary || []).find((summary: any) => summary.id === 'manage-images');
  const { conditionOfPatient, dischargeAdvice, treatmentToBeContinued, review, summaryGenerationDate, location, summaryGeneratedBy, checkedByDr, approvedByDr } = dischargeCondition;



  const DateOfSurgeries = procedures.map((procedure: any) => {
    return <>{procedure.procedureDate ? <div> {moment(new Date(procedure.procedureDate)).format('DD-MM-YYYY')}</div> : ''} </>
  });

  //investigations
  let { medicalHistory: { knownMedicalCondition, currentMedications, allergies, pastMedicalHistory }, surgicalHistory, personalHistory = '', familialHistory = '' } = historyObj;
  let currentMediationFiltered = currentMedications.filter((medication: any) => medication.name);

  const RenderTr = (props: { title: any, value: any }) => {
    const { title, value } = props;
    if (!value) {
      return null;
    }
    return <tr style={{ height: '10px' }} key={Math.random() * 1000}><td>{title}</td><td>:</td><td>{value}</td></tr>
  }

  return <>
    <div className="body-container discharge-summary" style={{overflowX: 'hidden'}}>
      <h1 className={'center-text page-header'}>Discharge Summary</h1>

      <Row>
        <Col xs={12}>
          <div className={'header-with-background '}>
            <h6>Patient Information</h6>
          </div>
          <div className={'info'}>
            <table>
              <tr>
                <td>
                  <div className={'info-record'}>
                    <div className={'info-label'}>
                      Patient Name
                    </div>
                    <div className={'info-value'}>
                      {Nameprefix(patientObj)} {patientSummary.fullName}
                    </div>
                  </div>
                </td>
                <td>
                  <div className={'info-record'}>
                    <div className={'info-label'}>
                      UHID{patientSummary.ipNumber || visitobj.ipNumber ? <> / IP no.</> : ''}
                    </div>
                    <div className={'info-value'}>
                      {patientId} {patientSummary.ipNumber || visitobj.ipNumber ? <> / {patientSummary.ipNumber || visitobj.ipNumber}</> : ''}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className={'info-record'}>
                    <div className={'info-label'}>
                      Age {gender ? <> / sex</> : ''}
                    </div>
                    <div className={'info-value'}>
                      {patientObj.age && patientObj.age.years ? <>{patientObj.age.years} Y</> : ''} {patientObj.age && patientObj.age.months ? <>{patientObj.age.months} M</> : ''}
                      {gender ? <span style={{ textTransform: 'capitalize' }}> / {gender || '-'}</span> : ''}
                    </div>
                  </div>
                </td>
                <td>
                  <div className={'info-record'}>
                    <div className={'info-label'}>
                      Emergency contact
                    </div>
                    <div className={'info-value'}>
                      {emergencyContact && emergencyContact.name ? emergencyContact.name : ''} {emergencyContact && emergencyContact.contact ? <>  {emergencyContact.name ? '/' : ''} {emergencyContact.contact}</> : ''}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div className={'info-record full-width'}>
                    <div className={'info-label'}>
                      Address
                    </div>
                    <div className={'info-value'}>
                      {patientSummary.address ? patientSummary.address + (city ? ' , ' : '') : ''}{(city ? city : '') || ''}{state ? ', ' + state + (areaCode ? ' , ' : '') : ''}{areaCode ? (' ' + areaCode) : ''}
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </Col>
        <Col xs={12}>
          <div className={'header-with-background '} style={{ marginTop: '0.2cm' }}>
            <h6>Medical Team Details</h6>
          </div>
          <div className={'info'}>
            <table>
              <tr>
                <td>
                  <div className={'info-record'}>
                    <div className={'info-label'}>
                      Primary Consultant
                    </div>
                    <div className={'info-value'}>
                      {medicalTeam.primaryConsultant}
                    </div>
                  </div>
                </td>
                <td>
                  {medicalTeam.otherConsultants !== '-' || medicalTeam.extraConsultants !== '-' ? 
                  <div className={'info-record'}>
                    <div className={'info-label'}>
                      Other Consultants
                    </div>
                    <div className={'info-value'}>
                      {medicalTeam.otherConsultants !== '-' ? medicalTeam.otherConsultants : ''}
                        {medicalTeam.extraConsultants !== '-' ? <>{medicalTeam.extraConsultants?.length > 1 ? (' , ' + medicalTeam.extraConsultants) : '' }</>: ''}
                    </div>
                  </div>
                   : null }
                </td>
              </tr>
              <tr>
                <td>
                  <div className={'info-record'}>
                    <div className={'info-label'}>
                      Date of admission
                    </div>
                    <div className={'info-value'}>
                      {medicalTeam.dateOfAdmission ? moment(new Date(medicalTeam.dateOfAdmission)).format('DD-MM-YYYY') : '-'}
                    </div>
                  </div>
                </td>
                <td>
                  <div className={'info-record'}>
                    <div className={'info-label'}>
                      Date of discharge
                    </div>
                    <div className={'info-value'}>
                      {medicalTeam.dateOfDischarge ? moment(new Date(medicalTeam.dateOfDischarge)).format('DD-MM-YYYY') : '-'}
                    </div>

                  </div>
                </td>
              </tr>
              <tr>
                {DateOfSurgeries.length ? <td>
                  <div className={'info-record'}>
                    <div className={'info-label'}>
                      Date of surgery
                    </div>
                    <div className={'info-value'}>
                      {DateOfSurgeries.length ? DateOfSurgeries : '-'}
                    </div>
                  </div>
                </td> : null}
                <td>
                  <div className={'info-record'}>
                    <div className={'info-label'}>
                      Next review date
                    </div>
                    <div className={'info-value'}>
                      {medicalTeam.reviewDate ? moment(new Date(medicalTeam.reviewDate)).format('DD-MM-YYYY') : '-'}
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>

        </Col>

      </Row>
      {diagnosisObj && (diagnosisObj.diagnosis || (procedures && procedures.length)) ?
        <>
          {diagnosisObj.diagnosis ? <div className={'no-page-break'}> <Row>  <Col xs={12}>
            <div className={'header-with-underline '}>
              Diagnosis
            </div>
            <div className={'info'}>
              <> <div className={'padding-top-5'}>
                <div className={'info-value'}>
                  <TextAreaText text={diagnosisObj.diagnosis} />
                </div>
              </div></>

            </div>

          </Col> </Row> </div> : null}

          {procedures && procedures.length ?
            <div className={'no-page-break'}>
              <Row>
                <Col xs={12} className={'padding-top-5'}>
                  <div className={'header-with-underline'}>
                    Procedures
                  </div>
                  <div className={'info'}>
                    {/*<div className={'padding-top-5'}>*/}
                    <div className={'info-value'}>
                      {procedures.map((procedure: any, index: number) => {
                        return <div className={'full-caps'}>{index + 1} . {procedure.name}</div>
                      })}
                    </div>
                    {/*</div>*/}
                  </div>

                </Col> </Row></div> : null}

        </>
        : null}

      {complaints.complaints || complaints.historyOfPresentingIllness ?
        <div className={'no-page-break'}>
          <Row>
            <Col xs={12}>
              <div className={'header-with-underline '}>
                Reason for admission
              </div>
              <div className={'info'}>
                {complaints.complaints ? <>
                  <div className={'info-record'}>
                    <div className={'info-label'}>
                      Complaints
                    </div>
                    <div className={'info-value'}>
                      <TextAreaText text={complaints.complaints} />

                    </div>
                  </div>

                </> : null}
                {complaints.historyOfPresentingIllness ? <>

                  <div className={'info-record'}>
                    <div className={'info-label'}>
                      History of presenting illness
                    </div>
                    <div className={'info-value'}>
                      <TextAreaText text={complaints.historyOfPresentingIllness} />

                    </div>
                  </div>

                </> : null}
              </div>
            </Col>
          </Row>
        </div>
        :
        null
      }

      {knownMedicalCondition || (currentMediationFiltered || []).length || (allergies || []).length || pastMedicalHistory || (surgicalHistory && surgicalHistory.length) ?
        <div className={'no-page-break'}>
          <Row>
            <Col xs={12}>
              <div className={'header-with-underline '}>
                History
              </div>
              <div className={'info'}>
                {
                  knownMedicalCondition ? <RenderInfo title={'Known medical condition'} value={knownMedicalCondition} /> : null
                }

                {
                  currentMediationFiltered && currentMediationFiltered.length ? <> <div className={'info-record'}>
                    <div className={'info-label'}>
                      Current Medications
                    </div>
                    <div className={'info-value'}>
                      {

                        currentMediationFiltered.map((prescription: any, index: number) => {
                          return <div className={'full-caps'}>{index + 1} . {prescription.name} &nbsp; [{prescription.morning || '-'}]  [{prescription.afternoon || '-'}] [{prescription.evening || '-'}] [{prescription.night || '-'}]</div>
                        })
                        // <Prescription currentMedications={currentMediationFiltered} visit={{records: []}} fromSummary={true}/>
                      }
                    </div>
                  </div></> : null
                }
                {allergies && allergies.length ? <div className={'info-record'}>
                  <div className={'info-label'}>
                    Allergies
                  </div>
                  <div className={'info-value'}>
                    {allergies.map((allergy: any, index: number) => {
                      return <div className={'full-caps'} key={'allergy' + index}>{index + 1}. {allergy}</div>
                    })}
                  </div>
                </div> : null}
                {pastMedicalHistory ? <RenderInfo title={'Past medical history'} value={pastMedicalHistory} /> : null}
                {surgicalHistory && surgicalHistory.length ? <div className={'info-record'}>
                  <div className={'info-label'}>
                    Surgical History
                  </div>
                  <div className={'info-value'}>
                    {surgicalHistory.map((surgery: any, index: number) => {
                      return <div className={'full-caps'} key={'surgery-' + index}>{index + 1}. {surgery.name}</div>
                    })}
                  </div>
                </div> : null}
                {personalHistory ? <RenderInfo title={'Personal history'} value={personalHistory} /> : null}
                {familialHistory ? <RenderInfo title={'Familial history'} value={familialHistory} /> : null}
              </div>


            </Col>
          </Row></div> : null}


      {
        admissionCondition && (admissionCondition.generalExamination || admissionCondition.systemicExamination || admissionCondition.localExamination) ?
          <div className={'no-page-break'}>
            <Row><Col> <div className={'padding-top-5'}>
              <div className={'header-with-underline '} >
                Admission condition
              </div>
              {admissionCondition.generalExamination || admissionCondition.systemicExamination ? <div className={''}>
                {admissionCondition.generalExamination ? <RenderInfo title={'General examination'} value={admissionCondition.generalExamination} /> : null}
              </div> : null}
              {admissionCondition.systemicExamination ? <div className={''}>
                {admissionCondition.systemicExamination ? <RenderInfo title={'Systemic examination'} value={admissionCondition.systemicExamination} /> : null}
              </div> : null}
              {admissionCondition.localExamination ? <div className={''}>
                {admissionCondition.localExamination ? <RenderInfo title={'Local examination'} value={admissionCondition.localExamination} /> : null}
              </div> : null}
            </div><br /></Col></Row> </div> : null
      }

      {investigations?.radiologyImpression && investigations?.radiologyImpression.trim().length ?
        <div className={'no-page-break'}>
          <Row>
            <Col xs={12} className={'padding-top-5'}>
              <div className={'header-with-underline'}>
                Radiology Impression
              </div>
              <div className={'info'}>
                <div className={'padding-top-5'}>
                  <div className={'info-value'} dangerouslySetInnerHTML={{ __html: investigations?.radiologyImpression }}>
                  </div>
                </div>
              </div>

            </Col> </Row>
        </div>
        : null
      }

      {investigations?.labTests ?
        <div className={'no-page-break'}>
          <Row>
            <Col xs={12} className={'padding-top-5'}>
              <div className={'header-with-underline'}>
                Lab Investigations
              </div>
              <div className={'info'}>
                <div className={'padding-top-5'}>
                  <div className={'info-value'} dangerouslySetInnerHTML={{ __html: investigations?.labTests }}>
                  </div>
                </div>
              </div>

            </Col> </Row></div>
        : null
      }

      {investigations?.mriImpression ?
        <div className={'no-page-break'}>
          <Row>
            <Col xs={12} className={'padding-top-5'}>
              <div className={'header-with-underline'}>
                MRI Impressions
              </div>
              <div className={'info'}>
                <div className={'padding-top-5'}>
                  <div className={'info-value'} dangerouslySetInnerHTML={{ __html: investigations?.mriImpression }}>
                  </div>
                </div>
              </div>

            </Col> </Row></div>
        : null
      }

      {investigations?.ctImpression && investigations?.ctImpression?.trim().length ?
        <div className={'no-page-break'}>
          <Row>
            <Col xs={12} className={'padding-top-5'}>
              <div className={'header-with-underline'}>
                CT Impressions
              </div>
              <div className={'info'}>
                <div className={'padding-top-5'}>
                  <div className={'info-value'} dangerouslySetInnerHTML={{ __html: investigations?.ctImpression }}>
                  </div>

                </div>
              </div>

            </Col> </Row></div>
        : null
      }

      {investigations?.otherInvestigations ?
        <div className={'no-page-break'}>
          <Row>
            <Col xs={12} className={'padding-top-5'}>
              <div className={'header-with-underline'}>
                Other Investigations
              </div>
              <div className={'info'}>
                <div className={'padding-top-5'}>
                  <div className={'info-value'} dangerouslySetInnerHTML={{ __html: investigations?.otherInvestigations }}>
                  </div>
                </div>
              </div>

            </Col> </Row></div>
        : null
      }

      {
        opinion && opinion.expertiseOpinionAndPlan ? <Row><Col> <div className={'no-page-break'}>
          <div className={'header-with-underline '} >
            Opinion
          </div>
          <div className={'info'}>
            {opinion.expertiseOpinionAndPlan ? <RenderInfo title={'Expert Opinion And Plan'} value={opinion.expertiseOpinionAndPlan} /> : null}
          </div>

        </div><br /></Col></Row> : null
      }

      {
        procedures && procedures.length ?
          <div className={'no-page-break'}>
            <Row> <Col xs={12}>
              <div className={'header-with-underline '} >
                Procedures
              </div>
              {procedures.map((procedure: any, index: number) => {
                return <Row>
                  <Col xs={12}>
                    <div className={'info'}>
                      <div>
                        <div className={'info-record'}><div className={'full-caps info-label'}>{index + 1}. {procedure.name}</div></div>
                        <div className={'info'}>
                          {procedure.procedureDate ? <RenderInfo title={'Procedure Date'} value={moment(procedure.procedureDate).format('DD-MM-YYYY')} /> : null}
                          {procedure.preoperativeAssessment ? <RenderInfo title={'Pre-operative assessment'} value={procedure.preoperativeAssessment} /> : null}
                          {procedure.typeOfanaesthesia ? <RenderInfo title={'Type of anaesthesia'} value={procedure.typeOfanaesthesia} /> : null}
                          {procedure.position ? <RenderInfo title={'Position'} value={procedure.position} /> : null}
                          {procedure.incision ? <RenderInfo title={'Incision'} value={procedure.incision} /> : null}
                          {procedure.operativeNotes ? <RenderInfo title={'Operative notes'} value={procedure.operativeNotes} /> : null}
                          {procedure.operativeFindings ? <RenderInfo title={'Operative findings'} value={procedure.operativeFindings} /> : null}
                          {procedure.closure ? <RenderInfo title={'Closure'} value={procedure.closure} /> : null}
                          {procedure.anaesthesiologistNotes ? <RenderInfo title={'Anaesthesiologist notes'} value={procedure.anaesthesiologistNotes} /> : null}
                          {procedure.immediatePostoperativePeriod ? <RenderInfo title={'Immediate postoperative period'} value={procedure.immediatePostoperativePeriod} /> : null}
                        </div>
                      </div>
                    </div>

                  </Col>

                </Row>
              })}
            </Col>
            </Row>
          </div>
          : null
      }
      {
        courseInHospital && (courseInHospital.courseInHospital || courseInHospital.treatmentProvided) ? <div className={'no-page-break'}> <Row><Col> <div className={'info'}>
          <div className={'header-with-underline '} >
            Course in hospital
          </div>

          {<div className={'info'}>
            {courseInHospital.courseInHospital ? <RenderInfo title={''} value={courseInHospital.courseInHospital} /> : null}
            {courseInHospital.treatmentProvided ? <RenderInfo title={'Treatment provided'} value={courseInHospital.treatmentProvided} /> : null}
          </div>}

        </div></Col></Row></div> : null
      }

      {conditionOfPatient || dischargeAdvice || treatmentToBeContinued || review || summaryGenerationDate || summaryGeneratedBy || location || checkedByDr || approvedByDr ?
        <div className={'no-page-break' + (adviceInNextPage ? ' force-page-break' : '')} >
          <Row>
            <Col xs={12}>
              <div className={'header-with-underline'}>
                {dischargeCondition.title}
              </div>
              <div className={'info'}>
                <RenderInfo title={'Condition of patient at the time of discharge'} value={conditionOfPatient || '-'} />
                <RenderInfo title={'Discharge Advice'} value={dischargeAdvice || '-'} />
                <RenderInfo title={'Treatment to be continued'} value={treatmentToBeContinued || '-'} />

              </div>
              <div className={'info'}>
                <table style={{ border: 0 }}>
                  <tr>
                    <td>
                      <RenderInfo title={'Location'} value={location || '-'} />
                    </td>
                    <td>
                      <RenderInfo title={'Summary generation date'} value={moment(new Date(summaryGenerationDate ? summaryGenerationDate : medicalTeam.dateOfDischarge ? medicalTeam.dateOfDischarge : '')).format('DD-MM-YYYY')} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <RenderInfo title={'Checked By'} value={checkedByDr || '-'} />
                    </td>
                    <td>
                      {approvedByDr ? <RenderInfo title={'Approved By'} value={approvedByDr} /> : null}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <RenderInfo title={'Summary Generated by'} value={summaryGeneratedBy || '-'} />
                    </td>
                  </tr>
                </table>
              </div>
            </Col>
          </Row></div> : null}
      {
        <div>
          <div className="footer-doctor color-black" style={{
            float: 'right',
            marginTop: '5px',
            'paddingRight': '50px',
            'textAlign': 'right'
          }}>
            Authorized signatory
          </div>
        </div>
      }

    </div>
    <div className="body-container ">
      <div className={''}>


        {
          reportType === 'discharge-summary-with-image' && (manageImages?.items || []).length ?
            // <div className={'seperatePage'}>

            manageImages.items.map((item: any, index: number) => {

              if (item?.filemeta?.indexOf('pdf') > -1) {
                return null;
              }
              return <div className={'text-center' + (index === 0 || index % 2 === 0 ? ' image-container' : '') + (item.printType === 'full' ? ' seperatePage' : '')} style={{ marginTop: '0.1cm' }}>

                <Row>
                  <Col xs={'12'}>
                    <h5>{item.name}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col xs={'12'}>
                    <DisplaysecureImage obj={item} style={{ maxWidth: '100%', maxHeight: '100%' }} printType={item.printType} />
                  </Col>
                </Row>
                {/*<Row style={{height: '0.2cm'}}>*/}
                {/*  <Col xs={'12'}>*/}

                {/*    /!*<h5>{item.description}</h5>*!/*/}

                {/*  </Col>*/}
                {/*</Row>*/}
              </div>


            })

            // </div>


            // <div className={''}>
            //
            //
            // </div>

            : null
        }
        {/*</div>*/}
      </div>
    </div>





  </>



}
export default DischargeSummary;