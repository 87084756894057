import React, { useEffect, useState } from 'react';
import axios from "axios";
import './App.css';
import './pharmaAnalytics/pharmaAnalytics.css';
import PharmacyHeader from "./commons/pharmacyHeader";
import PharmacyAnalyticsHeader from "./pharmaAnalytics/pharmaAnalyticsHeader";
import PharmacyAnalyticsFooter from "./pharmaAnalytics/pharmaAnalyticsFooter";
import PharmaAnalyticsTable from "./pharmaAnalytics/pharmaAnalyticsTable";
import Pdffooter from "./commons/footer";
import getUrlParams from "./getUrlParams";
import BillTable from './pharma/billTable';
import { Container, Spinner } from 'react-bootstrap';
import PharmacyHeaderDotMatrix from "./pharma/DotMatrix/pharmacyHeaderDotMatrix";
import { default as BillTableDotMatrix } from "./pharma/DotMatrix/billTable";
import reportDecider from './pharmaAnalytics/pharmaAnalyticsCommons'
import moment from 'moment';

let url = '';
if (window.location.href.indexOf('pdf.bewelldigital.com') > -1) {
  url = 'https://q1lsdba6f6.execute-api.ap-south-1.amazonaws.com/prod';
} else {
  url = 'https://4440i72tm2.execute-api.us-west-2.amazonaws.com/prod';
  url = 'https://q1lsdba6f6.execute-api.ap-south-1.amazonaws.com/prod';
}

function getPharmaInfo(pharmaId: any) {
  console.log(`${url}/pharmacy/${pharmaId}`)
  return axios.get(`${url}/pharmacy/${pharmaId}`)
}

function getPatientInfo(medicalCenterId: any, patientId: string) {
  return axios.get(`${url}/patients/patient/` + medicalCenterId + '/' + patientId)
}


function getPharmaBill(pharmaId: any, billId: any) {
  return axios.get(`${url}/pharmacy/bills/${billId}?pharmacyId=${pharmaId}`)
}

const DotMatrixCss = React.lazy(() => import('./pharma/DotMatrix/pharmaCss'));

function Pharmacy() {
  const [state, setState] = useState({
    billInfo: null as any,
    pharmacyInfo: null as any,
    reportType: null as any,
  } as any);

  const [loading, setLoading] = useState(true);
  const [listOfBills, setListOfBills] = useState([]);
  const [listOfItems, setListOfItems] = useState([]);
  const [aggregateAmount, setAggregateAmount] = useState({} as any);
  const [selectedPatient, setSelectedPatient] = useState({} as any);
  const [selectedVisit, setSelectedVisit] = useState({} as any);
  const [patientId, setPatientId] = useState('' as any);

  useEffect(() => {
    const params = getUrlParams(window.location.href);
    const { billid, pharmaid, reporttype, reportsubtype, startdate, enddate , vendor, drug, visitid, customerid, gst } = params;
    const returnNo = new URLSearchParams(window.location.search).get('returnNo');
    const returnId = new URLSearchParams(window.location.search).get('returnId');

    (async () => {
      const promises = [];

      if ((reporttype === 'pharma-ip-bill-items' || reporttype === 'pharma-ip-bill-no-wise') && pharmaid && visitid && customerid) {
        try {
          const getCenterId = await getPharmaInfo(pharmaid);
          const centerId = getCenterId?.data?.data[0].medicalCenterId
          const getIpBillItems = await axios.get(`${url}/pharmacy/bills/ip-bill-items?pharmacyId=${pharmaid}&customerId=${customerid}&visitId=${visitid}`);
          setListOfItems(getIpBillItems?.data?.data?.length ? getIpBillItems?.data?.data.sort((a: any,b: any)=> (a.createdAt > b.createdAt ? 1 : -1)): []);
          const getCustomer = await axios.get(`${url}/pharmacy/customers/${customerid}?pharmacyId=${pharmaid}`);
          const patientId = getCustomer.data.data[0].patientId;
          setPatientId(patientId);
          const getPatient = await axios.get(`${url}/patients/patient/${centerId}/${patientId}`);
          setSelectedPatient(getPatient.data);
          const getVisits = await axios.get(`${url}/visits/patient/${centerId}-${patientId}`);
          const currentVisit = getVisits.data.find((visit: { visitId: any; }) => visit.visitId === visitid);
          setSelectedVisit(currentVisit);
          const results: any = [];
          const billIds: any = {};
          const patientRecordPayments: any = {};
          let totalBillAmount = 0;
          let totalAmountPaid = 0;
          let totalBalance = 0;
          let totalReturns = 0;
          let totalReturnedAmount = 0;
          const recordPaymentUrl = `/pharmacy/bills/patient-record-payments?pharmacyId=${pharmaid}`;
          const recordPayments = await axios.get(url + recordPaymentUrl);
          recordPayments?.data?.data?.forEach((payment: any) => {
              if (patientRecordPayments[payment.customerId]) {
                  patientRecordPayments[payment.customerId] += payment.totalPayment;
              } else {
                  patientRecordPayments[payment.customerId] = payment.totalPayment;
              }
              if (payment.customerId === Number(customerid)) {
                  payment.type = 'Record Payment';
                  payment[payment?.paymentMode?.toLowerCase()] = payment.totalPayment;
                  totalAmountPaid += payment.totalPayment;
                  totalBalance -= payment.totalPayment;
                  payment.name = selectedPatient.fullName;
                  results.push(payment);
              }
          });
          const getVisitBills = await axios.get(`${url}/pharmacy/bills?pharmacyId=${pharmaid}&customerId=${customerid}&visitId=${visitid}`);
          if (getVisitBills.data.data.length) {
              getVisitBills.data.data.forEach((bill: { type: any }) => {bill.type = bill.type ? bill.type : 'sales'});
              let sno = 1;
              getVisitBills.data.data.forEach((bill: any) => {
                totalBillAmount += bill.totalAmount || 0;
                bill.payments?.forEach((payment: any) => {
                  totalAmountPaid += (payment.isReturn ? 0 : (payment.amount || 0));
                  totalReturnedAmount += payment.isReturn ? (payment.amount || 0) : 0;
                });
                bill.billReturns?.forEach((returnBill: any) => {
                  totalReturns += returnBill.returnAmount || 0;
                });
                bill.cash = 0;
                bill.card = 0;
                bill.totalPayment = 0;
                // totalBalance += bill.totalAmount - bill.totalPayment - (bill.returnAmount ? bill.returnAmount : 0);
                bill.payments?.forEach((payment: any) => {
                  if (payment.mode === 'Cash') {
                    bill.cash += (payment.isReturn ? 0 : payment.amount);
                  } else if (payment.mode === 'Card') {
                    bill.card += (payment.isReturn ? 0 : payment.amount);
                  }
                  bill.totalPayment += payment.isReturn ? 0 : payment.amount;
                  
                  billIds[bill.id] = {};
                });

                results.push(bill);

                bill.billReturns?.forEach((billReturn: any) => {
                  const returnBill = {id: billReturn.billId} as any;
                  returnBill.returnAmount = billReturn.returnAmount;
                  returnBill.billNo = billReturn.returnNo;
                  returnBill.returnedAmount = 0;
                  bill.payments?.forEach((payment: any) => {
                    if (payment.isReturn && payment.billReturnId === billReturn.id) {
                      returnBill.returnedAmount += payment.amount;
                    }
                  });
                  returnBill.createdAt = billReturn.createdAt;
                  results.push(returnBill);
                });

                  if (bill.totalPayment) {
                      if (bill.paymentMode === 'Cash') {
                          billIds[bill.id] = Object.assign({}, billIds[bill.id], {Cash: bill.totalPayment});
                      } else if (bill.paymentMode === 'Card') {
                          billIds[bill.id] = Object.assign({}, billIds[bill.id], {Card: bill.totalPayment});
                      }
                  }
              });
              if (patientRecordPayments[selectedPatient.customerId]) {
                  totalAmountPaid += patientRecordPayments[selectedPatient.customerId];
              }
              results.forEach((bill: any) => {
                bill.balance = bill.totalAmount - bill.totalPayment - (bill.returnAmount || 0) - (bill.returnedAmount || 0);
              });
              results.sort((a: any,b: any)=> (a.createdAt > b.createdAt ? 1 : -1));
              setListOfBills(results);
              setAggregateAmount({
                  totalAmount: totalBillAmount,
                  totalPaid: totalAmountPaid,
                  balance: (totalBillAmount - totalAmountPaid - totalReturns - totalReturnedAmount),
                  totalReturnAmount: totalReturns,
                  totalReturnedAmount: totalReturnedAmount
              });
              setState({
                reportResponse: listOfItems,
                pharmacyInfo: getCenterId.data,
                reportType: reporttype,
                reportSubType: reportsubtype,
                startDate: startdate,
                endDate: enddate,
              });
              setLoading(false);
          } else {
              setListOfBills([]);
              setState({
                reportResponse: listOfItems,
                pharmacyInfo: getCenterId.data,
                reportType: reporttype,
                reportSubType: reportsubtype,
                startDate: startdate,
                endDate: enddate,
              });
              setLoading(false);
          }
        } catch (e) {
          setListOfBills([]);
          setLoading(false);
        }
      } else if ((reporttype === 'pharma-bill' || reporttype === 'pharma-bill-return') && pharmaid && billid) {
        promises.push(await getPharmaInfo(pharmaid));
        promises.push(await getPharmaBill(pharmaid, billid));
      }
      else if (reporttype === 'pharma-analytics' && reportsubtype && url && pharmaid) {
        promises.push(await getPharmaInfo(pharmaid));
        promises.push(await reportDecider.analyticsReportDecider(reportsubtype, url, pharmaid, startdate, enddate, vendor, drug, '', '', gst ));
      }



    if (promises.length) {
      (Promise.all(promises)).then(async (response) => {
        const [pharmaInfoResponse, reportResponse] = response;
        if (reporttype === 'pharma-bill-return') {
          const bill = reportResponse.data.data[0];
          if (bill.isReturned && bill.billReturnItems?.length) {
            let filteredReturnItems = [] as any;
            bill.billHistory = bill.billHistory?.filter((billHistoryItem: any) => billHistoryItem.details?.action !== 'return');
            bill.billHistory?.sort((a: any,b: any)=> (a.createdAt < b.createdAt ? 1 : -1));
            bill.billReturnItems.forEach((returnItem: any) => {
              const initialBill = bill.billHistory[0]?.details;
              const initialBillItem = initialBill?.billItems?.find((billItem: any) => billItem.drugId === returnItem.billItem?.drugId && billItem.purchaseItemId === returnItem.billItem?.purchaseItemId);
              const actualQuantity = initialBillItem?.quantity;
              returnItem.quantityBought = Number(actualQuantity) || '-';
              if (returnItem.returnQuantity && returnItem.billReturnId === Number(returnId)) {
                const actualBillItem = returnItem.billItem;
                returnItem = {...returnItem, ...actualBillItem}
                filteredReturnItems.push(returnItem);
              }
            });
            bill.billReturnItems = filteredReturnItems;
            bill.billReturns?.forEach((billReturn: any) => {
              if (billReturn.id === Number(returnId)) {
                bill.totalReturnAmount = billReturn.returnAmount;
                bill.billNo = billReturn.returnNo || bill.billNo;
              }
            });
            bill.printReturnBill = true;
          }
          bill.billItems = bill.billReturnItems;
          bill.payments = bill.payments.filter((payment: any) => payment.isReturn && payment.billReturnId === Number(returnId));
          bill.payments.forEach((payment: any) => {
            if (payment.createdAt > bill.createdAt && payment.isReturn && payment.billReturnId === Number(returnId)) {
              bill.createdAt = payment.createdAt;
            }
          })
        } else if (reporttype === 'pharma-bill') {
          const bill = reportResponse.data.data[0];
          if (bill.billItems?.length) {
            const updatedBillItems = [] as any;
            bill.billItems.forEach((billItem: any) => {
              if (!billItem.deletedAt) {
                updatedBillItems.push(billItem);
              }
            });
            bill.billItems = updatedBillItems;
          }
        }
        console.log('reportResponse: ', reportResponse);
        let patient: any = '';
        try {
          patient = await getPatientInfo(pharmaInfoResponse.data?.data[0].medicalCenterId, reportResponse.data.data[0]?.customer?.patientId)
          // reportResponse.data.data[0].customer.salutation = patient.data.salutation;
          reportResponse.data.data[0].customer.phoneNo = patient.data.phone;
          // reportResponse.data.data[0].customer.age = patient.data.age;
          // reportResponse.data.data[0].customer.gender = patient.data.gender;
          console.log(patient.data);
        } catch(e) {
          console.log(e);
        }
        
        setState({
          reportResponse: reportResponse?.data,
          pharmacyInfo: pharmaInfoResponse?.data,
          reportType: reporttype,
          reportSubType: reportsubtype,
          startDate: startdate,
          endDate: enddate,
        });
        setLoading(false);
      });
    }
    })();
  }, [])

  if (loading) {
    return <div style={{ height: '200px', width: '100%', marginTop: '100px', textAlign: 'center', fontSize: '13px' }}>
      <Spinner animation="border" role="status"></Spinner><br /> <br /> {'Please wait ...'}</div>
  }

  const { reportResponse, pharmacyInfo, reportType, reportSubType, startDate, endDate } = state;
  let pharmacy = pharmacyInfo.data[0];

  // if(pharmacy.isDotMatrixPrinter) {
  //   require('./css/Pharmacy.css')
  // }

  // @ts-ignore
  const PharmaCss = ({ children }) => {
    return (
      <>
        <React.Suspense fallback={<></>}>
          {(pharmacy.isDotMatrixPrinter) && <DotMatrixCss />}
          {children}
        </React.Suspense>
      </>
    )
  }

  return (
    reportType === 'pharma-ip-bill-items' || reportType === 'pharma-ip-bill-no-wise' ? <div>
    <PharmaCss>
        <table className={'dot-matrix flex-column-center'}>
          <PharmacyHeaderDotMatrix pharmacyInfo={pharmacyInfo} />
          {reportResponse && reportType === 'pharma-ip-bill-items' ?
            <BillTableDotMatrix reportType={reportType} ipBillItems={listOfItems} pharmacyInfo={pharmacyInfo} selectedPatient={selectedPatient} selectedVisit={selectedVisit} aggregates={aggregateAmount} /> : <BillTableDotMatrix reportType={reportType} ipBills={listOfBills} pharmacyInfo={pharmacyInfo} selectedPatient={selectedPatient} selectedVisit={selectedVisit} aggregates={aggregateAmount} />}
        </table>
      </PharmaCss>
    </div> :
    <div>
      {((reportType === 'pharma-bill' || reportType === 'pharma-bill-return') && pharmacy.isDotMatrixPrinter ? (<PharmaCss>
        <table className={'dot-matrix flex-column-center'}>
          <PharmacyHeaderDotMatrix pharmacyInfo={pharmacyInfo} />
          {reportResponse && (reportType === 'pharma-bill' || reportType === 'pharma-bill-return') ?
            <BillTableDotMatrix billInfo={reportResponse} pharmacyInfo={pharmacyInfo} /> : null}
        </table>
      </PharmaCss>) : (<table className="App">
        {reportResponse && (reportType === 'pharma-bill' || reportType === 'pharma-bill-return') ? <PharmacyHeader pharmacyInfo={pharmacyInfo} /> :
          <PharmacyAnalyticsHeader pharmacyInfo={pharmacyInfo} />}
        <tbody>
          <tr>
            <td className="outer-table">
              {reportResponse && (reportType === 'pharma-bill' || reportType === 'pharma-bill-return') ?
                <BillTable billInfo={reportResponse} pharmacyInfo={pharmacyInfo} /> : <PharmaAnalyticsTable reportData={reportResponse} reportSubType={reportSubType} startDate={startDate} endDate={endDate} />}
            </td>
          </tr>
        </tbody>
          {reportResponse && (reportType === 'pharma-bill' || reportType === 'pharma-bill-return') ? <Pdffooter empty={true} headerInfo={{ footerText: pharmacyInfo.data[0].footerMessage}}></Pdffooter> :
          <PharmacyAnalyticsFooter />}
      </table>))}

    </div>


  );
}

export default Pharmacy;
