import * as React from "react";
import {Col, Row} from "react-bootstrap";
import moment from "moment";
import { addComma, AddDrPrefix, validateNumber } from "../bills/utils";

const Nameprefix = (patient: any) => {
  if (['miss.', 'mrs.', 'mr.', 'baby.'].some(v => patient.name.toLowerCase().includes(v))) {
    return '';
  }

  if ((patient && patient.age && patient.age.years && patient.age.years <= 3) || (!patient.age!.years && patient.age!.months)) {
    return 'Baby. '
  }

  return patient && patient.gender === 'male' ? 'Mr. ' : patient.gender === 'female' ? 'Ms. ' : '';
}

export function TotalCollectionReport(props: any) {
  const {startDate, endDate, visitType, report, print} = props;
  const {patientInfo, reportInfo, radiologyInfo} = report;
  const patientObj = patientInfo;
  const [reports, setReport] = React.useState([] as any);
  const [billCount, setCount] = React.useState({
    cash: 0,
    card: 0,
    cheque: 0,
    rtgs: 0,
    totalDiscount: 0,
    totalDue: 0,
    totalAdvance: 0,
    totalAmount: 0,
    totalReturns: 0,
  } as any);

  console.log(report);
  React.useEffect(() => {
    const amounts = {
        cash: 0,
        card: 0,
        cheque: 0,
        credit: 0,
        rtgs: 0,
        totalDiscount: 0,
        totalDue: 0,
        totalAdvance: 0,
        totalAmount: 0,
        totalPaid: 0,
        totalReturns: 0,
      };

      setCount({ ...amounts });
      const results: any = [];

    report.forEach((bill: any, index: number) => {
        bill.invoices = typeof bill.invoices === 'string' ?  JSON.parse(bill.invoices) : bill.invoices;
          bill.invoices.forEach((invoice: any, iIndex: number) => {
            let type = '';
            if (invoice.returnedItems) {
              type = 'returns'
              amounts.totalReturns += -1 * validateNumber(invoice.returnedAmount);
              results.push({
                sno: bill.sno,
                patientId: bill.patientId,
                id: bill.uhid,
                name: bill.fullName,
                department: bill.department?.toUpperCase()  + (bill.unit ? ' - '+bill.unit : ''),
                createdAt: moment(invoice.returnDate).format('DD-MM-YYYY HH:mm'),
                billId: (bill.billNo || bill.billId),
                billAmount: '-',
                amountInNumber: 0,
                paid: -1 * validateNumber(invoice.returnedAmount),
                cash: '-',
                card: '-',
                credit: '-',
                free: '-',
                cancelApproveBy: '-',
                type,
                billUid: bill.billId,
                billIndex: iIndex,
                addedBy: bill.addedBy,
                visitType: bill.ipNumber ? 'IP' : 'OP'
                // others: method !== 'Cash' && method !== 'Credit' &&  method !== 'Card' ? YesText : '-'
              });
            }
            
            type = bill.invoices[0]?.isOnlyPayment ? 'record payment' : 'sales';
            let totalAmount: any = 0;
            let cost = 0, discountedCost = 0, tax = 0, totalCost = 0, paid = 0;
            const method = invoice.method;
            paid = Number(invoice.totalPaid || 0);

            amounts.cash += validateNumber(invoice.cash);
            amounts.card += validateNumber(invoice.card);
            amounts.credit += validateNumber(invoice.totalAmount);


            invoice.items.forEach((item: any) => {
                if (isNaN(item.tax)) {
                  item.tax = 0; 
                }
              cost = item.cost * item.unit;
              type = item.type;
              discountedCost = Number(item.discount || 0);
              tax = (item.tax * (cost - discountedCost)) / 100;
              totalCost = (((cost - discountedCost) + tax));
              totalAmount += totalCost;
        
            });
            totalAmount -= Number(invoice.totalBillDiscount || 0);
            amounts.totalAmount += totalAmount;
            amounts.totalDiscount += discountedCost;
            amounts.totalPaid += paid;
            const credit = validateNumber(invoice.totalPaid) - (validateNumber(invoice.cash) + validateNumber(invoice.card));
            results.push({
              sno: bill.sno,
              patientId: bill.patientId,
              id: bill.uhid,
              name: bill.fullName,
              department: bill.department?.toUpperCase()  + (bill.unit ? ' - '+bill.unit : ''),
              createdAt: moment(bill.createdAt).format('DD-MM-YYYY HH:mm'),
              billUnit: bill.billUnit,
              doctorName: bill.doctorName,
              billId: (bill.billNo || bill.billId),
              billAmount: totalAmount ? ` ${addComma(totalAmount)}` : '0',
              amountInNumber: totalAmount || 0,
              paid: invoice.totalPaid ? ` ${addComma(validateNumber(invoice.totalPaid))}` : '0',
              cash: method === 'Cash' || method === 'Cash & Card' ? addComma(validateNumber(invoice.cash)) : '0',
              card: method === 'Card' || method === 'Cash & Card' ? addComma(validateNumber(invoice.card)) : '0',
              credit: addComma(totalAmount-invoice.totalPaid),
              free:  bill.cancelApproveBy  ? 'Yes' : '-',
              cancelApproveBy:  bill.cancelApproveBy || '-',
              type,
              billUid: bill.billId,
              billIndex: iIndex,
              addedBy: bill.addedBy,
              visitType: bill.ipNumber ? 'IP' : 'OP'
              // others: method !== 'Cash' && method !== 'Credit' &&  method !== 'Card' ? YesText : '-'
            });
            
          });
        
      });
      setCount({ ...amounts });
      setReport(results);
    if (print) {
      window.print();
    }
  }, []);

 

  return  <>
    <div className="pa-title-card">
        <div className="pa-report-name">{'Billing total collection report'}</div>
    </div>
    <div className="pa-stat-card">
      <div className="pa-stat" key={'totalAmount'}>
        Total Amount
        <p className="pa-center-text"><span className="pa-stat-number"> {addComma(billCount.totalAmount)}</span></p>
      </div>
      <div className="pa-stat" key={'cashPayment'}>
        Cash payment
        <p className="pa-center-text"><span className="pa-stat-number"> {addComma(Math.ceil(Number(billCount.cash)))}</span></p>
      </div>
      <div className="pa-stat" key={'cardPayment'}>
        Card payment
        <p className="pa-center-text"><span className="pa-stat-number"> {addComma(Math.ceil(Number(billCount.card)))}</span></p>
      </div>

      <div className="pa-stat" key={'totalPaid'}>
        Total Paid
        <p className="pa-center-text"><span className="pa-stat-number"> {addComma(billCount.totalPaid)}</span></p>
      </div>

      <div className="pa-stat" key={'totalCredit'}>
        Total Credit
        <p className="pa-center-text"><span className="pa-stat-number"> {addComma(billCount.totalAmount - billCount.totalPaid)}</span></p>
      </div>

      <div className="pa-stat" key={'totalReturns'}>
        Total Returns
        <p className="pa-center-text"><span className="pa-stat-number"> {addComma(billCount.totalReturns)}</span></p>
      </div>

    </div>
    <div className="pa-date-range">
        <div className="pa-printed-on">Report Printed On   : {new Date().toLocaleString()}</div>
        {(startDate && endDate)?<div className="pa-printed-range" style={{paddingRight:'20px'}}>Date Range   : {startDate} - {endDate}</div>: null}
    </div>
    { visitType ? <>
        <br/>
        <div className={'pa-date-range'}>
            <div className="pa-printed-on">Visit Type   : {visitType.toUpperCase()}</div>
        </div>
        <br /> </> : null
    }
    
    <div>
    <>
    <div className={'reports-main-container'}>

      <div className={'reports-table-container'}>

      <table className={'pa-table'}>
                <thead>
                <tr>
                  <th key={"td-1"}>S.No</th>
                  <th key={"td-2"}>UHID</th>
                  <th key={"td-3"}>Name</th>
                  <th key={"td-4"}>Doctor Name</th>
                  <th key={"td-5"}>Bill Date</th>
                  <th key={"td-6"}>Bill ID</th>
                  <th key={"td-8"}>Visit</th>
                  <th key={"td-9"}>Amount</th>
                  <th key={"td-10"}>Paid</th>
                  <th key={"td-11"}>Cash</th>
                  <th key={"td-12"}>Card</th>
                  <th key={"td-13"}>Credit</th>
                </tr>
                </thead>
                <tbody>
                  {
                    reports.map(( bill: any, index: number) =>{
                      return <tr key={"tr-"+index}>
                        <td>{index+1}</td>
                        <td>{bill.id}</td>
                        <td>{bill.name}</td>
                        <td>{AddDrPrefix(bill.doctorName) || '-'}</td>
                        <td>{bill.createdAt}</td>
                        <td>{bill.billId}</td>
                        <td>{bill.visitType}</td>
                        <td>{bill.billAmount}</td>
                        <td>{bill.paid}</td>
                        <td>{bill.cash}</td>
                        <td>{bill.card}</td>
                        <td>{bill.credit}</td>
                      </tr>
                      }
                    )
                  }
                </tbody>
              </table>
      </div>
    </div>
  </>
  </div> </>

}

export default TotalCollectionReport;
