import { Row, Col } from 'react-bootstrap';
import moment from 'moment';

export const Nameprefix = (patient: any) => {
  const hasSalutationInName = ['miss.', 'mrs.', 'mr.', 'baby.'].some(v => (patient.fullName || patient.name).toLowerCase().includes(v));
  if (patient.salutation && patient.salutation.length > 1 && !hasSalutationInName) {
    return patient.salutation + '.' + patient.fullName;
  }

  if (patient && (!patient.gender || !patient.age)) {
    return '';
  }

  if (['miss.', 'mrs.', 'mr.', 'baby.'].some(v => (patient.fullName || patient.name).toLowerCase().includes(v))) {
    return patient.fullName
  }

  if ((patient && patient.age && patient.age.years && patient.age.years <= 3) || (!patient.age!.years && patient.age!.months)) {
    return 'Baby. '
  }

  return patient && patient.gender === 'male' ? 'Mr. ' + (patient.fullName || patient.name) : patient.gender === 'female' ? 'Ms. ' + (patient.fullName || patient.name) : '';
}

function LabReport(props: any) {
  const { patientInfo, emptyHeader } = props;
  let patientObj = patientInfo;
  let fullName = patientObj?.fullName;
  let address = [];
  if (patientObj?.address)
    address.push(patientObj.address)
  if (patientObj?.state)
    address.push(patientObj.state)

  let age = patientObj?.age && (patientObj?.age?.years || patientObj?.age?.months) ? ((patientObj?.age?.years || '-') + ' Y ' + ((patientObj?.age?.months ? patientObj?.age?.months + ' M' : '') || '')) : '-';
  let gender = patientObj?.gender;
  let patientId = patientObj?.uhid;
  const { labTest } = props;

  const collectionLevels = (labTest?.collectionLevels || []).filter((collectionLevel: any) => collectionLevel?.completed);

  // const filterData =  labTest.tests ?

  let Tests: any = [];

  if (labTest?.tests) {

    labTest.tests.forEach((test: any) => {
      let len = 0;
      if (test.fields) {
        Tests.push(test);
      } else {
        (test.tests).forEach((field: any) => {
          if (field?.result) {
            len += 1;
          }
        });

        if (len) {
          Tests.push(test);
        }
      }

    })
  }


  let date = moment(new Date(labTest?.creation_date)).format('DD-MM-YYYY');
 
  return <> {labTest ? <div><div className="body-container">
    {<h3 className={'color-black'}> <b> Lab Report </b> </h3>}
    <Row>
      <Col xs={6}>
        <div className={'flex-container'}>
          <div >
            <h5 className={'color-black'}> <b> Patient Information </b></h5>
          </div>
          <div className={'gray-parent graybox '}>
            <div className={'flex-div'}>
              <p style={{ fontSize: '17px' }}><div className="label-question color-black">Name </div><div className="label-answer color-black" style={{ fontWeight: 'normal' }}> : {Nameprefix(patientObj)}</div></p>
              <p style={{ color: '#01334E', fontSize: '17px' }}><div className="label-question color-black" >Age </div>  <span style={{ fontWeight: 'normal' }} className={'color-black'}> : {age}</span></p>
            </div>
            <div className={' flex-div'}>
              <p style={{ color: '#01334E', fontSize: '17px' }}><div className="label-question color-black">Gender </div>   <span style={{ fontWeight: 'normal' }} className={'color-black'}>: {gender}</span></p>
              <p style={{ color: '#01334E', fontSize: '17px' }}><div className="label-question color-black">UHID </div> <span style={{ fontWeight: 'normal' }} className={'color-black'}>: {patientId}</span></p>
            </div>
          </div>
        </div>
      </Col>
      <Col xs={6}>
        <div className={'flex-container'}>
          <div >
            <h5 className={'color-black'}> <b> Doctor Information </b> </h5>
          </div>
          <div className={'gray-parent graybox'}>
            <div className={'flex-div '}>
              <p style={{ fontSize: '17px' }}><div className="label-question color-black">Doctor </div><div className="label-answer color-black"> : <span style={{ fontWeight: 'normal', marginLeft: '2px' }}> {labTest?.docName.indexOf('Dr.') > -1 ? ' ' : ' Dr.'} {labTest?.docName} </span></div></p>
              <p style={{ color: '#01334E', fontSize: '17px' }}><div className="label-question color-black">Date </div> : <span style={{ fontWeight: 'normal' }} className={'color-black'}>{date}</span></p>
            </div>
          </div>
        </div>
      </Col>
      <Col xs={12}><h5> </h5></Col>
    </Row>
  </div>


    {

      collectionLevels?.length ?
        <div className="body-container">
          <div >
            <h6 className={'color-black'}><b> Sample collection </b> </h6>
          </div>
          <div className={'gray-parent graybox'} style={{ padding: '2px' }}>
            <Row style={{ width: '100%', padding: '5px' }}>
              {
                (collectionLevels || []).map((collectionLevel: any, index: number) => {
                  return <Col key={index + '-level'} xs={6}>
                    <Row>
                      <Col xs={7}>
                        <span style={{ color: '#969494' }}>Collected</span>  <span style={{ fontWeight: 'normal' }} className={'color-black'} > &nbsp; : {collectionLevel.sampleCollected}</span>
                      </Col>
                      <Col xs={5}>
                        <span style={{ color: '#969494' }}>Date</span><span style={{ fontWeight: 'normal' }} className={'color-black'}> &nbsp; :  {moment(new Date(collectionLevel.date)).format('DD-MM-YYYY')}</span>
                      </Col>
                      <Col xs={6}>
                        <span className="label-question" style={{ color: '#969494', width: '80px' }}>Time</span><span style={{ fontWeight: 'normal' }} className={'color-black'}>&nbsp; :  {collectionLevel.time}</span>
                      </Col>
                    </Row>

                  </Col>
                })
              }
            </Row>
          </div>
        </div>
        : null
    }

    {
      Tests.map((test: any, testIndex: number) => {

        return <div className={"body-container" + (testIndex > 0 ? ' page-break-avoid' : '' )}>
          <div key={testIndex}>
            <div>
              <table>
                <tr ><th colSpan={3} style={{ border: 'none', background: 'none', paddingLeft: 0 }}><h6 style={{ fontSize: '18px' }} className={'color-black'}> <b>{test.title ? test.title : test.name ? test.name : ''} </b></h6></th></tr>
                <tr>
                  <th style={{ width: '320px' }} className={'color-black'}>Name</th>
                  <th className={'color-black'}>Value</th>
                  <th className={'color-black'}>Unit</th>
                  {test.noUnit ? null : <th className={'color-black'}>Range</th>}
                </tr>

                {
                  (test.fields || test.tests).map((field: any, index: number) => {
                    if (!field.range) {
                      field.range = {};
                    }
                    const noRange = field?.range?.min === 0 && field?.range?.max === 0;
                    if (noRange) {
                      field.range  = {};
                      field.range.text = '-';
                    }
                    
                    let isInvalid = field?.type === 'text' && noRange ? false : field?.result && Number(field?.result) < field?.range?.min || Number(field?.result) > field?.range?.max;
                    let ResultRange: any = (field?.range?.min >= 0 || field?.range?.text) ? <td className={'color-black'}>{field?.range?.text ? field?.range?.text : (field?.range?.min + '-' + field?.range?.max)} </td> : null;

                    if (gender && field?.range &&field?.range[gender]) {
                      ResultRange = <td className={'color-black'}>{field?.range[gender]?.min} - {field?.range[gender]?.max}</td>;
                      isInvalid = field?.type === 'text' && noRange ? false : field?.result && Number(field?.result) < field?.range[gender]?.min || Number(field?.result) > field?.range[gender]?.max;
                    }
                    if (!field?.result) {
                      return null;
                    }
                    return <tr key={index} className={'table-row-investigations'}>
                      <td style={{ width: '320px' }} className={'color-black'}>{field?.name}</td>
                      <td className={'color-black'}>{field?.result ? <span style={isInvalid ? { fontWeight: 'bold', color: '#000' } : {}}> {field?.result} {isInvalid ? <sup>*</sup> : null} </span> : ''}</td>
                      <td style={{ textTransform: 'lowercase' }} className={'color-black'}>{field?.unit || '-'}</td>
                      {test.noUnit ? null : ResultRange}
                    </tr>


                  })
                }
              </table>
            </div>
          </div>


        </div>


      })

    }
    {
      emptyHeader ? <div>
        <div className="footer-doctor color-black" style={{
          float: 'right',
          marginTop: '22px',
          'paddingRight': '50px',
          'textAlign': 'right',
          
        }}>
          Lab Technician
        </div>
      </div> : null
    }
  </div> : null} </>
}

export default LabReport;
