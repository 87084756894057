

import { Row, Col } from 'react-bootstrap';
import moment from 'moment';

export const Nameprefix = (patient: any) => {
    const hasSalutationInName = ['miss.', 'mrs.', 'mr.', 'baby.'].some(v => (patient.fullName || patient.name).toLowerCase().includes(v));
    if (patient.salutation && patient.salutation.length > 1 && !hasSalutationInName) {
        return patient.salutation + '.' + patient.fullName;
    }

    if (patient && (!patient.gender || !patient.age)) {
        return '';
    }

    if (['miss.', 'mrs.', 'mr.', 'baby.'].some(v => (patient.fullName || patient.name).toLowerCase().includes(v))) {
        return patient.fullName
    }

    if ((patient && patient.age && patient.age.years && patient.age.years <= 3) || (!patient.age!.years && patient.age!.months)) {
        return 'Baby. '
    }

    return patient && patient.gender === 'male' ? 'Mr. ' + (patient.fullName || patient.name) : patient.gender === 'female' ? 'Ms. ' + (patient.fullName || patient.name) : '';
}




function LabIPReport(props: any) {
    const { patientInfo, emptyHeader } = props;
    let patientObj = patientInfo;
    let fullName = patientObj.fullName;
    let address = [];
    if (patientObj.address)
        address.push(patientObj.address)
    if (patientObj.state)
        address.push(patientObj.state)

    let age = patientObj.age && (patientObj.age.years || patientObj.age.months) ? ((patientObj.age.years || '-') + ' Y ' + ((patientObj.age.months ? patientObj.age.months + ' M' : '') || '')) : '-';
    let gender = patientObj.gender;
    let patientId = patientObj.uhid;
    const { labTest } = props;
    
    labTest.sort(function (a: any, b: any) {
        var keyA = a.creation_date,
            keyB = b.creation_date;
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
    });

    return <div><div className="body-container">
        {<h3 style={{color:'rgb(66, 110, 205)'}}> <b> Lab Report </b> </h3>}
        <Row>
            <Col xs={12}>
                <div className={'flex-container'}>
                    <div >
                        <h5 className={'color-black'}> <b> Patient Information </b></h5>
                    </div>
                    <div className={'graybox '}>
                        <div  style={{display:'flex', flexDirection:'row', padding:'10px', margin:'10px', justifyContent:'space-between'}}>
                            
                            <div><p style={{ fontSize: '14px' }}><div className="label-question-labreport color-black">Name </div><div className="label-answer color-black"> : {Nameprefix(patientObj)}</div></p></div>
                            <div><p style={{ color: '#01334E', fontSize: '14px' }}><div className="label-question-labreport color-black" >Age </div>  <span className={'color-black'}> : {age}</span></p></div>
                       
                            <div><p style={{ color: '#01334E', fontSize: '14px' }}><div className="label-question-labreport color-black">Gender </div>   <span className={'color-black'}>: {gender}</span></p></div>
                            <div><p style={{ color: '#01334E', fontSize: '14px' }}><div className="label-question-labreport color-black">UHID </div> <span  className={'color-black'}>: {patientId}</span></p></div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    </div>

        {
            labTest.map((labTest: any, testIndex: any) => {
               
                const allCollectionResult = (labTest.collectionLevels || []).filter((collectionLevel: any) => collectionLevel.completed)
                let Tests: any = [];
                if (labTest.tests) {

                    labTest.tests.forEach((test: any) => {
                        let len = 0;
                        if (test.fields) {
                            Tests.push(test);
                        } else {
                            (test.tests).forEach((field: any) => {
                                if (field?.result) {
                                    len += 1;
                                }
                            });

                            if (len) {
                                Tests.push(test);
                            }
                        }

                    })
                }
                return <div><div className={"body-container" + (testIndex > 0 ? ' page-break-avoid' : '')}>
                    <Row>
                        <Col xs={12}>
                            <div className={'flex-container'}>
                                <div>
                                    <h6 style={{color:'rgb(66, 110, 205)'}}> <b>{testIndex+1}. Test  {moment(labTest.creation_date).format('D MMM YYYY - hh:mm a')} </b></h6>
                                </div>
                                <div className={'graybox '} style={{background:'rgb(219, 229, 250 )'}}>
                                    <div className={'flex-div'} style={{display:'flex', flexDirection:'row', padding:'10px', margin:'10px'}}>
                                        <div><p style={{ fontSize: '14px' }}><div className="label-question-labreport color-black">Test ID </div><div className="label-answer color-black" > : {labTest.testNo ? 'Test ID-' + labTest.testNo : '-'}</div></p></div>
                                        <div style={{marginLeft:'13%'}}><p style={{ fontSize: '14px' }}><div className="label-question-labreport color-black">Doctor </div><div className="label-answer color-black"> : <span style={{ marginLeft: '2px' }}> {labTest.docName.indexOf('Dr.') > -1 ? ' ' : ' Dr.'} {labTest.docName} </span></div></p></div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                    {allCollectionResult.length ?
                        <div className={"body-container" + (testIndex > 0 ? ' page-break-avoid' : '')} >
                            <div className={'graybox'}>
                                <Row style={{ width: '100%', padding:'10px', display:'flex', flexDirection:'row', justifyContent:'space-between', margin:'10px'}}>
                                    {
                                        (labTest.collectionLevels).map((collectionLevel: any, index: number) => {
                                            
                                            return <div >
                                            <div style={{fontSize: '16px', fontWeight:600}} >{labTest.collectionLevels.length > 1 ?  <div>Sample collection - {index+1}</div> :<div> Sample collection </div>} </div>
                                                    <Col className={'graybox'} key={index + '-level'} >
                                                <Row>
                                                   <div style={{display:'flex', flexDirection:'row'}}>
                                                        <div><p style={{ fontSize: '14px' }}><div className="label-question-labreport color-black">Collection</div>  <span style={{fontSize: '14px'  }} className={'color-black'} > &nbsp; : {collectionLevel.sampleCollected}</span></p></div>
                                                    
                                                        <div style={{marginLeft:'10px'}}><p style={{ fontSize: '14px'}}><div className="label-question-labreport color-black">Date & Time</div><span style={{fontSize: '14px'  }} className={'color-black'}> &nbsp; :  {moment(new Date(collectionLevel.date)).format('D MMM YYYY')} {collectionLevel.time}</span></p></div>
                                                    </div>
                                                </Row>
                                            </Col>
                                            </div>

                                        })

                                    }
                                </Row>
                            </div>
                        </div> : null}

                    {
                        Tests.map((test: any, testIndexes: number) => {

                            return <div><div className={"body-container" + (testIndex > 0 ? ' page-break-avoid' : '')}>
                                <div key={testIndexes} >
                                    <div>
                                        <table>
                                            <tr ><th colSpan={3} style={{ border: 'none', background: 'none', paddingLeft: 0 }}><h6 style={{ fontSize: '18px' }} className={'color-black'}> <b>{test.title ? test.title : test.name ? test.name : ''} </b></h6></th></tr>
                                            <tr>
                                                <th style={{ width: '320px' }} className={'color-black'}>Name</th>
                                                <th className={'color-black'}>Value</th>
                                                <th className={'color-black'}>Unit</th>
                                                {test.noUnit ? null : <th className={'color-black'}>Range</th>}
                                            </tr>
                                            {
                                                (test.fields || test.tests).map((field: any, index: number) => {
                                                    if (!field.range) {
                                                        field.range = {};
                                                    }
                                                    const noRange = field?.range?.min === 0 && field?.range?.max === 0;
                                                    if (noRange) {
                                                        field.range = {};
                                                        field.range.text = '-';
                                                    }

                                                    let isInvalid = field?.type === 'text' && noRange ? false : field?.result && Number(field?.result) < field?.range?.min || Number(field?.result) > field?.range?.max;
                                                    let ResultRange: any = (field?.range?.min >= 0 || field?.range?.text) ? <td className={'color-black'}>{field?.range?.text ? field?.range?.text : (field?.range?.min + '-' + field?.range?.max)} </td> : null;

                                                    if (gender && field?.range && field?.range[gender]) {
                                                        ResultRange = <td className={'color-black'}>{field?.range[gender]?.min} - {field?.range[gender]?.max}</td>;
                                                        isInvalid = field?.type === 'text' && noRange ? false : field?.result && Number(field?.result) < field?.range[gender]?.min || Number(field?.result) > field?.range[gender]?.max;
                                                    }
                                                    if (!field?.result) {
                                                        return null;
                                                    }
                                                    return <tr key={index} className={'table-row-investigations'}>
                                                        <td style={{ width: '320px' }} className={'color-black'}>{field?.name}</td>
                                                        <td className={'color-black'}>{field?.result ? <span style={isInvalid ? { 'fontWeight': 'bolder', color: '#000' } : {}}> {field?.result} {isInvalid ? <sup>*</sup> : null} </span> : ''}</td>
                                                        <td style={{ textTransform: 'lowercase' }} className={'color-black'}>{field?.unit || '-'}</td>
                                                        {test.noUnit ? null : ResultRange}
                                                    </tr>
                                                })
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>
                            </div>
                        })
                    }
                      <hr style={{borderTop:'1px dashed'}}/>
                </div>
            })
        }

        {
            emptyHeader ? <div>
                <div className="footer-doctor color-black" style={{
                    float: 'right',
                    marginTop: '22px',
                    'paddingRight': '50px',
                    'textAlign': 'right',

                }}>
                    Lab Technician
                </div>
            </div> : null
        }
    </div>


}

export default LabIPReport;

