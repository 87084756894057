import * as React from "react";
import BillInvoiceDetails from "./billInvoiceDetails";
import BillFooter from "./billFooter";
import moment from "moment";

function percentageToNumber(percentage: number, total: number) {
  return (percentage / 100) * total;
}

function SelfConsumptionBillTable(props: any) {
  const {billInfo, pharmacyInfo, centerInfo} = props;


  let bill = billInfo.data[0];

  if (!bill) {
    return <h4 style={{textAlign: 'center'}}>Invalid Bill ID</h4>
  }

  let billItems = bill.billItems;

  let pharmacy = pharmacyInfo.data[0];
  let isGST = pharmacy.gst;

  let billTotal = {
    cgst: 0,
    sgst: 0,
    grossAmount: 0,
    serialNumber: 0
  }

  let paymentModes: string[] = []

  for (const payment of bill.billPayments) {
    if(payment.amount) {
      paymentModes.push(payment.paymentMode)
    }
  }

  return <div>
    <div>
    <div className={'center-text'}>
      <span className={'dot-matrix-large-text-bold center-text'}>INVOICE</span>
    </div>
    <div className={'invoice-details'}>
      <div className={'invoice-details-section'}>
        <p>
          <div className="label-question-120">
            <span className={'dot-matrix-large-text-bold'}>Department Name</span>
          </div>
          <span className={'dot-matrix-large-text-light'}> : {(centerInfo[0]?.listOfDepartments?.find((department: any) => department.id === Number(bill.departmentId))?.name || '-')}</span>
        </p>
      </div>
      <div className={'invoice-details-section'}>
        <p>
          <div className="label-question-120">
            <span className={'dot-matrix-large-text-bold'}>Bill No</span>
          </div>
          <span className={'dot-matrix-large-text-light'}> : {bill.id}</span>
        </p>
        <p>
          <div className="label-question-120">
            <span className={'dot-matrix-large-text-bold'}>Date</span>
          </div>
          <span className={'dot-matrix-large-text-light'}> : {bill?.createdAt ? moment(bill?.createdAt).format('DD/MM/YYYY') : '-'}</span>
        </p>
        <p>
          <div className="label-question-120">
            <span className={'dot-matrix-large-text-bold'}>Time</span>
          </div>
          <span className={'dot-matrix-large-text-light'}> : {bill?.createdAt ? moment(bill?.createdAt).format('h:mm A') : '-'}</span>
        </p>
      </div>
    </div>
  </div>
    <tr className={'dotted-header'}>
      <th className={'th-xlarge'}>Particulars</th>
      <th className={'th-large'}>Batch No</th>
      <th className={'th-small'}>Expiry</th>
      {/*<th className={'th-medium'}>MRP</th>*/}
      <th className={'th-xsmall'}>Qty</th>
      {/*{ isGST ? <th className={'th-xsmall'}>Tax</th> : null}*/}
      <th className={'th-large force-right-text'}>Amount</th>
    </tr>
    {billItems.map((item:any) => {
      billTotal.serialNumber++;
      billTotal.grossAmount += Number(item.total)

      if(item?.purchaseItem?.cgstPercentage) {
        let taxValue = percentageToNumber(Number(item?.tax), Number(item.total));
        billTotal.cgst+= taxValue
        if(isGST) {
          billTotal.grossAmount -= taxValue
        }
      }

      if(item?.purchaseItem?.sgstPercentage) {
        let taxValue = percentageToNumber(item?.purchaseItem?.sgstPercentage, Number(item.total));
        billTotal.sgst += taxValue
        if(isGST) {
          billTotal.grossAmount -= taxValue
        }
      }

      return (<tr>
        <td>{item.name}</td>
        <td>{item.batch  || '-'}</td>
        <td>{moment(bill?.createdAt).format('MM/YY')  || '-'}</td>
        {/*<td>{item?.rate || '-'}</td>*/}
        <td className={'center-text'}>{item.quantity  || '-'}</td>
        {/*{ isGST ? <td className={'center-text'}>{(item?.purchaseItem?.cgstPercentage || 0) + (item?.purchaseItem?.sgstPercentage || 0)  || '-'}</td> : null }*/}
        <td className={'right-text'}>{Number(item.total).toFixed(2)  || '-'}</td>
      </tr>)
    })}
    <BillFooter
      billItemsLength={billItems.length}
      bill={bill}
      billTotal={billTotal}
      isGST={isGST}
      paymentModes={paymentModes}
      pharmacy={pharmacy}/>
  </div>
}

export default SelfConsumptionBillTable;
