import axios from "axios";
import moment from 'moment';
import { addComma, roundAndAddComma } from '../bills/utils';

function analyticsReportDecider(reportSubType: any, urlprefix: any, pharmaId: any, startDate: any, endDate: any, selectedVendor: any, selectedDrug: any, customerId: any, visitId: any, gst: any) {
    switch (reportSubType) {
        case 'income-expense':
            return getIncomeExpense(urlprefix + '/pharmacy/reports', pharmaId, startDate, endDate);
        case 'collection-report':
            return getCollectionReport(urlprefix + '/pharmacy', pharmaId, startDate, endDate);
        case 'creditors':
            return getCreditors(urlprefix + '/pharmacy/reports', pharmaId, startDate, endDate);
        case 'debtors':
            return getDebtors(urlprefix + '/pharmacy/reports', pharmaId, startDate, endDate);
        case 'expiry-drug':
            return getExpiryDrug(urlprefix + '/pharmacy/drugs', pharmaId, startDate, endDate, selectedDrug);
        case 'scheduled':
            return getScheduled(urlprefix + '/pharmacy/drugs', pharmaId, startDate, endDate, selectedDrug);
        case 'minimum-stock':
            return getMinimumStocks(urlprefix + '/pharmacy/reports', pharmaId, startDate, selectedDrug);
        case 'stock-report':
            return getStockReport(urlprefix + '/pharmacy/reports', pharmaId, startDate, endDate, selectedDrug);
        case 'stock-history':
            return getStockHistory(urlprefix + '/pharmacy/reports', pharmaId, startDate, endDate, selectedDrug);
        case 'stock-change-report':
            return getStockChangeReport(urlprefix + '/pharmacy/reports', pharmaId, startDate, endDate, selectedDrug);
        case 'purchase-book':
            return getPurchaseBook(urlprefix + '/pharmacy', pharmaId, startDate, endDate);
        case 'vendor-summary':
            return getVendorSummary(urlprefix + '/pharmacy', pharmaId, startDate, endDate);
        case 'vendor-purchase':
            return getVendorPurchaseSummary(urlprefix + '/pharmacy', pharmaId, startDate, endDate, selectedVendor);
        case 'purchase-history':
            return getPurchaseHistory(urlprefix + '/pharmacy', pharmaId, startDate, endDate);
        case 'purchases':
            return getPurchaseReport(urlprefix + '/pharmacy/reports', pharmaId, startDate, endDate, gst);
        case 'bills':
            return getBillReport(urlprefix + '/pharmacy/reports', pharmaId, startDate, endDate);
        case 'ip-report-summary':
            return getIpReportSummary(urlprefix, pharmaId, startDate, endDate);
        case 'ip-report':
            return getIpReport(urlprefix, pharmaId, customerId, visitId, startDate, endDate);
        case 'bill-payments':
            return getBillPaymentsReport(urlprefix + '/pharmacy', pharmaId, startDate, endDate);
        default:
            return getCreditors(urlprefix + '/pharmacy/reports', pharmaId, startDate, endDate);
    }
}


function getIncomeExpense(url: any, pharmaId: any, startDate: any, endDate: any) {
    return axios.get(`${url}/income-expense?pharmacyId=${pharmaId}&startDate=${startDate}&endDate=${endDate}`)
}

function getCollectionReport(url: any, pharmaId: any, startDate: any, endDate: any) {
    return axios.get(`${url}/reports/collection-report?pharmacyId=${pharmaId}&dateFrom=${startDate.toUpperCase()}&dateTo=${endDate.toUpperCase()}`)
}

function getCreditors(url: any, pharmaId: any, startDate: any, endDate: any) {
    return axios.get(`${url}/creditors?pharmacyId=${pharmaId}&dateFrom=${startDate.toUpperCase()}&dateTo=${endDate.toUpperCase()}`)
}

function getDebtors(url: any, pharmaId: any, startDate: any, endDate: any) {
    return axios.get(`${url}/debtors?pharmacyId=${pharmaId}&dateFrom=${startDate.toUpperCase()}&dateTo=${endDate.toUpperCase()}`)
}

async function getExpiryDrug(url: any, pharmaId: any, startDate: any, endDate: any, selectedDrug: any) {
    const records: any = [];
    let expiryDrug: any;
    selectedDrug = decodeURI(selectedDrug)
    try {
        const reportData = await axios.get(`${url}/expiry?pharmacyId=${pharmaId}&expiryFrom=${startDate}&expiryTo=${endDate}`)
        if (selectedDrug) {
            expiryDrug = reportData.data.data.filter((record: any) => {
                return record?.drug?.name.toLowerCase().includes(selectedDrug.toLowerCase())
            });
        }
        else {
            expiryDrug = reportData.data.data
        }
        expiryDrug.map((drug: any, val: any) => {
            let manufacturer = drug.drug?.manufacturer;
            records.push({
                sno: Number(val) + 1,
                name: drug?.drug?.name || '-',
                batch: drug.batch,
                vendor: drug.purchase?.vendor?.name,
                manufacturer: manufacturer ? manufacturer.name : '-',
                invoiceDate: manufacturer ? new Date(drug.drug.manufacturer.createdAt).toLocaleDateString() : '-',
                stock: addComma((drug.stock)),
                costPrice: addComma(drug.purchaseRate),
                sellingPrice: addComma(drug.sellingPrice),
                dateOfExpiry: new Date(drug.expiry).toLocaleDateString(),
            });

        });
        return ({
            data: {
                data: records
            }
        });
    } catch (e) {
        console.log(e);
        return ({ data: { data: [] } });

    }


}

async function getScheduled(url: any, pharmaId: any, startDate: any, endDate: any, selectedDrug: any) {
    const records: any = [];
    let stockDrug: any;
    selectedDrug = decodeURI(selectedDrug)
    try {
        const reportData = await axios.get(`${url}/scheduled?pharmacyId=${pharmaId}&dateFrom=${startDate}&dateTo=${endDate}`)
        if (selectedDrug) {
            stockDrug = reportData.data.data.filter((record: any) => {
                return record?.drug?.name.toLowerCase().includes(selectedDrug.toLowerCase())
            });
        }
        else {
            stockDrug = reportData.data.data
        }
        let sno = 0;
        reportData.data.data.map((drug: any) => {
            let manufacturer = drug.drug?.manufacturer;
            records.push({
                sno: ++sno,
                billNo: drug.bill?.billNo,
                patientName: drug.bill?.customer?.name,
                billDate: new Date(drug.createdAt).toLocaleDateString(),
                doctorName: drug.bill?.doctorName,
                drugName: drug.drug?.name,
                batchNo: drug.batch,
                manufacturer: manufacturer ? manufacturer.name : '-',
                quantity: addComma(drug.quantity),
                expiryDate: drug?.purchaseItem?.expiry ? new Date(drug.purchaseItem.expiry).toLocaleDateString() : '-',
                signature: ''
            });
        });
        return ({
            data: {
                data: records
            }
        });
    } catch (e) {
        console.log(e);
        return ({ data: { data: [] } });
    }
}

async function getMinimumStocks(url: any, pharmaId: any, stock: any, selectedDrug: any) {

    let stockDrug: any;
    selectedDrug = decodeURI(selectedDrug)

    try {
        const reportData = await axios.get(`${url}/minimum-stocks?pharmacyId=${pharmaId}&stock=${stock}`)
       
        let lowStockDrugs = 0;
        let drugsWithoutMinimumStocks = 0;
        let sno = 0;
        const records: any = [];
        if (selectedDrug) {
            stockDrug = reportData.data.data.filter((record: any) => {
                return record?.name.toLowerCase().includes(selectedDrug.toLowerCase())
            });
        }
        else {
            stockDrug = reportData.data.data
        }
        stockDrug.map((stock: any) => {
            records.push({
                sno: ++sno,
                drugName: stock.name,
                minimumQuantity: stock.minimumStock ? addComma(stock.minimumStock) : 'Not Available',
                actualQuantity: addComma(stock.stock),
            });

            drugsWithoutMinimumStocks += stock.minimumStock ? 0 : 1;
            if (stock.minimumStock && stock.stock && stock.minimumStock > stock.stock)
                lowStockDrugs++
        });
        return ({
            data: {
                data: records, aggregates: {
                    'Low Stock Drugs': addComma(lowStockDrugs),
                    'Drug Without Minimum Stocks': addComma(drugsWithoutMinimumStocks)
                }
            }
        });
    } catch (e) {
        console.log(e);
        return ({ data: { data: [], aggregates: {} } });

    }
}

async function getStockReport(url: any, pharmaId: any, startDate: any, endDate: any, selectedDrug: any) {

    const records: any = [];
    let stockDrug: any;
    selectedDrug = decodeURI(selectedDrug)
    try {
        const reportData = await axios.get(`${url}/stock-report?pharmacyId=${pharmaId}&expiryFrom=${startDate}&expiryTo=${endDate}`)
        if (selectedDrug) {
            stockDrug = reportData.data.data.filter((record: any) => {
                return record?.drug?.name.toLowerCase().includes(selectedDrug.toLowerCase())
            });
        }
        else {
            stockDrug = reportData.data.data
        }
        stockDrug.map((drug: any, val: any) => {
            let manufacturer = drug.drug?.manufacturer;
            records.push({
                sno: Number(val) + 1,
                drugName: drug.drug?.name || '-',
                manufacturerName: manufacturer ? manufacturer.name : '-',
                batch: drug.batch,
                expiry: drug.expiry,
                purchaseRate: drug.purchaseRate,
                mrp: addComma(drug.sellingPrice),
                taxPercent: addComma(drug.cgstPercentage + drug.sgstPercentage),
                stock: addComma(drug.stock),
                totalPurchaseValue: drug?.drug?.unitsPerStrip? Number((drug.purchaseRate / drug.drug.unitsPerStrip)*drug.stock).toFixed(2)  : '-',
			    totalSalesValue: (Number(drug.stock) * Number(drug.sellingPrice)).toFixed(2),
            });

        });
        return ({
            data: {
                data: records
            }
        });
    } catch (e) {
        console.log(e);
        return ({ data: { data: [] } });

    }

}

async function getStockHistory(url: any, pharmaId: any, startDate: any, endDate: any, selectedDrug: any) {

    const records: any = [];
    let stockDrug: any;
    selectedDrug = decodeURI(selectedDrug)
    try {
        const reportData = await axios.get(`${url}/stock-history?pharmacyId=${pharmaId}&dateFrom=${startDate}&dateTo=${endDate}`)
        if (selectedDrug) {
            stockDrug = reportData.data.data.filter((record: any) => {
                return record?.drugName.toLowerCase().includes(selectedDrug.toLowerCase())
            });
        }
        else {
            stockDrug = reportData.data.data
        }
        stockDrug.map((stock: any, val: any) => {

            records.push({
                sno: Number(val) + 1,
                drugName: stock.drugName,
                batch: stock.batch,
                quantity: stock.quantity,
                billNo: stock.billNo,
                customerName: stock.customerName,
                date: stock.createdAt ? moment(stock.createdAt).format('DD-MM-YYYY') : '',
            });
        });
        return ({
            data: {
                data: records
            }
        });
    } catch (e) {
        console.log(e);
        return ({ data: { data: [] } });

    }
}

async function getStockChangeReport(url: any, pharmaId: any, startDate: any, endDate: any, selectedDrug: any) {

    try {
        const reportData = await axios.get(`${url}/stock-change-history?pharmacyId=${pharmaId}&dateFrom=${startDate}&dateTo=${endDate}`)
        let stockDrug: any;
        selectedDrug = decodeURI(selectedDrug)
        const records: any = [];
        reportData.data.data.reports.map((report: any) => {
            records.push({
                type: 'Bill',
                drugName: report.drugName,
                batch: report.batch,
                quantity: report.quantity,
                billInvNo: report.billNo,
                customerVendorName: report.customerName,
                date: report.createdAt,
            });
        });

        reportData.data.data.purchase.map((purchases: any) => {
            for (const purchaseItem of purchases.purchaseItems) {
                records.push({
                    type: 'Purchase',
                    billInvNo: purchases.invoiceNumber ? purchases.invoiceNumber : '-',
                    customerVendorName: purchases.vendor ? purchases.vendor.name : '-',
                    date: purchases.invoiceDate,
                    drugName: purchaseItem.drug ? purchaseItem.drug.name : '-',
                    quantity: purchaseItem.purchaseQuantity,
                    batch: purchaseItem.batch ? purchaseItem.batch : '-',
                });
            }
        });

        reportData.data.data.physicalStock.map((physicalStocks: any) => {
            records.push({
                type: 'Adjustment',
                billInvNo: physicalStocks.name ?? '-',
                customerVendorName: physicalStocks.createdBy ?? '-',
                date: physicalStocks.createdAt ?? '-',
                drugName: physicalStocks.drugName ?? '-',
                quantity: (Number(physicalStocks.newStock ?? 0) - Number(physicalStocks.oldStock ?? 0)) ?? 0,
                batch: physicalStocks.batch ?? '-',
            });
        });

        records.sort(function (a: any, b: any) {
            var keyA = a.date,
                keyB = b.date;
            if (keyA > keyB) return -1;
            if (keyA < keyB) return 1;
            return 0;
        });

        if (selectedDrug) {
            stockDrug = records.filter((record: any) => {
                return record?.drugName.toLowerCase().includes(selectedDrug.toLowerCase())
            });
        }
        else {
            stockDrug = records
        }

        stockDrug.forEach((element: any, index: any) => {
            element['sno'] = index + 1;
        });
        stockDrug.forEach((data: any) => {
            data.date = moment(data.date).format('DD-MM-YYYY')
        })

        return ({
            data: {
                data: stockDrug
            }
        });

    } catch (e) {
        console.log(e);
        return ({ data: { data: [] } });
    }
}

function getPurchaseBook(url: any, pharmaId: any, startDate: any, endDate: any) {
    return axios.get(`${url}/purchases?pharmacyId=${pharmaId}&invoiceFrom=${startDate}&invoiceTo=${endDate}`)
}

function getVendorSummary(url: any, pharmaId: any, startDate: any, endDate: any) {
    return axios.get(`${url}/purchases?pharmacyId=${pharmaId}&invoiceFrom=${startDate}&invoiceTo=${endDate}`)
}

function getPurchaseHistory(url: any, pharmaId: any, startDate: any, endDate: any) {
    return axios.get(`${url}/purchases?pharmacyId=${pharmaId}&invoiceFrom=${startDate}&invoiceTo=${endDate}`)
}

function getPurchaseReport(url: any, pharmaId: any, startDate: any, endDate: any, gst: any) {
    return axios.get(`${url}/purchase-report?pharmacyId=${pharmaId}&purchaseFrom=${startDate}&purchaseTo=${endDate}${gst ? `&taxPercentage=${gst}` : ''}`)
}

function getBillReport(url: any, pharmaId: any, startDate: any, endDate: any) {
    return axios.get(`${url}/bill-report?pharmacyId=${pharmaId}&billFrom=${startDate}&billTo=${endDate}`)
}

async function getVendorPurchaseSummary(url: any, pharmaId: any, startDate: any, endDate: any, selectedVendor: any) {
    selectedVendor = decodeURI(selectedVendor)
    try {
        const results: any = [];
        const response = await axios.get(`${url}/purchases?pharmacyId=${pharmaId}&invoiceFrom=${startDate}&invoiceTo=${endDate}`)
        const filteredVendors = response.data.data.filter((record: any) => {
            return record.vendor.name.toLowerCase().trim() === (selectedVendor.toLowerCase().trim())
        });
        let amounts = {
            totalTax: 0,
            totalValue: 0,
            totalPaid: 0,
        };
        filteredVendors.forEach((purchase: any, index: any) => {
            amounts.totalTax += Number(purchase.totalTax);
            amounts.totalValue += Number(purchase.totalPurchaseValue);
            let paid = 0;
            purchase.payments?.forEach((payment: any) => {
                paid += Number(payment.amount);
                amounts.totalPaid += Number(payment.amount);
            });
            results.push({
                sno: index + 1,
                grnNo: purchase.grnNo,
                invoiceNo: purchase.invoiceNumber ? purchase.invoiceNumber : '-',
                vendorName: purchase.vendor ? purchase.vendor.name : '-',
                invoiceDate: purchase?.invoiceDate ?  moment(purchase?.invoiceDate).format('DD/MM/YYYY') : '-',
                totalPurchaseValue: Number(purchase.totalPurchaseValue).toFixed(2),
                totalTax: Number(purchase.totalTax).toFixed(2),
                paymentMode: purchase.payments[0]?.mode ? purchase.payments[0]?.mode : '-',
                totalPaid: addComma(Number(paid).toFixed(0)),
            });
        });

        return ({
            data: {
                data: results, aggregates: {
                    'Total Tax': Math.round(amounts.totalTax),
                    'Total Value': Math.round(amounts.totalValue),
                    'Total Paid': Math.round(amounts.totalPaid),
                }
            }
        });
    } catch (e) {
        console.log(e);
        return ({ data: { data: [], aggregates: {} } });

    }

}

async function getIpReportSummary(url: any, pharmaId: any, startDate: any, endDate: any) {
    try {
        const results: any = [];
        const patientRecordPayments: any = {};
        const recordPaymentUrl = `/pharmacy/bills/patient-record-payments-with-date-filter?pharmacyId=${pharmaId}&startDate=${startDate}&endDate=${endDate}`;
        const recordPayments = await axios.get(url + recordPaymentUrl);
        recordPayments?.data?.data?.forEach((payment: any) => {
            if (patientRecordPayments[payment.customerId]) {
                patientRecordPayments[payment.customerId] += payment.totalPayment;
            } else {
                patientRecordPayments[payment.customerId] = payment.totalPayment;
            }
        });
        let ipSummaryUrl = `/pharmacy/bills/ip-patient-payments-with-date-filter?pharmacyId=${pharmaId}&startDate=${startDate}&endDate=${endDate}`;
        const response = await axios.get(url + ipSummaryUrl);
        let usersBills = {} as any;
        response?.data?.data?.forEach((bill: any, index: number) => {
            bill.sno = index + 1;
            if (patientRecordPayments[bill.customerId]) {
                bill.totalPayment += patientRecordPayments[bill.customerId];
            }
            results.push(bill);
        })
        return ({data:{data: results, aggregates:{}}});
    } catch (e) {
        console.log(e);
        return ({data:{data: [], aggregates: {}}});
    }
}

async function getIpReport(url: any, pharmaId: any, customerId: any, visitId: any, startDate: any, endDate: any) {
    const amounts: any = {
        cashCount: 0,
        cardCount: 0,
        cash: 0,
        card: 0,
        cheque: 0,
        credit: 0,
        rtgs: 0,
        totalDiscount: 0,
        totalDue: 0,
        totalAdvance: 0,
        totalAmount: 0,
        totalAmountPaid: 0,
        totalReturns: 0,
    };

    try {
        const getPharmaInfo = await axios.get(`${url}/pharmacy/${pharmaId}`)
        const centerId = getPharmaInfo?.data?.data[0]?.medicalCenterId
        const getCustomer = await axios.get(`${url}/pharmacy/customers/${customerId}?pharmacyId=${pharmaId}`);
        const patientId = getCustomer.data.data[0].patientId;
        const getPatient = await axios.get(`${url}/patients/patient/${centerId}/${patientId}`);
        const results: any = [];
        const billIds: any = {};
        const patientRecordPayments: any = {};
        const recordPaymentUrl = `/pharmacy/bills/patient-record-payments-with-date-filter?pharmacyId=${pharmaId}&startDate=${startDate}&endDate=${endDate}`;
        const recordPayments = await axios.get(url + recordPaymentUrl);
        recordPayments?.data?.data?.forEach((payment: any) => {
            if (patientRecordPayments[payment.customerId]) {
                patientRecordPayments[payment.customerId] += payment.totalPayment;
            } else {
                patientRecordPayments[payment.customerId] = payment.totalPayment;
            }
            if (payment.customerId === Number(customerId)) {
                amounts.totalAdvance += payment.totalPayment;
                payment.type = 'Record Payment';
                payment[payment?.paymentMode?.toLowerCase()] = payment.totalPayment;
                amounts[payment?.paymentMode?.toLowerCase()] += payment.totalPayment;
                payment.name = getPatient?.data?.fullName;
                payment.billNo = '-';
                payment.doctorName = '-';
                payment.totalAmount = '-';
                results.push(payment);
            }
        });
        let ipBillsUrl = `/pharmacy/bills/ip-bill-payments-with-date-filter?pharmacyId=${pharmaId}&customerId=${customerId}&visitId=${visitId}&startDate=${startDate}&endDate=${endDate}`;
        const response = await axios.get(url + ipBillsUrl);
        let bill: any = {}
        let index: number = 1;
        if (response?.data?.data?.length) {
            response.data.data.forEach((bill: { type: any }) => {bill.type = bill.type ? bill.type : 'sales'});
            let sno = 1;
            response.data.data.forEach((bill: any) => {
                bill.sno = sno;
                sno += 1;
                bill.name = getPatient?.data?.fullName;
                amounts.totalAmount += bill.totalAmount;
                amounts.totalAmountPaid += bill.totalPayment;
                amounts.totalDue += bill.totalAmount - bill.totalPayment - (bill.returnAmount ? bill.returnAmount : 0);
                if (billIds[bill.id]) {
                    results.find((record: any) => record.id === bill.id).totalPayment += bill.totalPayment;
                    if (bill.paymentMode === 'Cash') {
                        results.find((record: any) => record.id === bill.id).cash = bill.totalPayment;
                        amounts.cash += bill.totalPayment;
                        amounts.cashCount += 1;
                    } else if (bill.paymentMode === 'Card') {
                        results.find((record: any) => record.id === bill.id).card = bill.totalPayment;
                        amounts.card += bill.totalPayment;
                        amounts.cardCount += 1;
                    }
                } else {
                    amounts.totalReturns += bill.returnAmount ? bill.returnAmount : 0;
                    if (bill.paymentMode === 'Cash') {
                        bill.cash = bill.totalPayment;
                        amounts.cash += bill.totalPayment;
                        amounts.cashCount += 1;
                    } else if (bill.paymentMode === 'Card') {
                        bill.card = bill.totalPayment;
                        amounts.card += bill.totalPayment;
                        amounts.cardCount += 1;
                    }
                    results.push(bill);
                }
                if (bill.totalPayment) {
                    if (bill.paymentMode === 'Cash') {
                        billIds[bill.id] = Object.assign({}, billIds[bill.id], {Cash: bill.totalPayment});
                    } else if (bill.paymentMode === 'Card') {
                        billIds[bill.id] = Object.assign({}, billIds[bill.id], {Card: bill.totalPayment});
                    }
                }
            });
            if (patientRecordPayments[customerId]) {
                amounts.totalAmountPaid += patientRecordPayments[customerId];
            }
            results.sort((a: any,b: any)=> (a.createdAt < b.createdAt ? 1 : -1));
            results.forEach((bill: any, index: number)=>{
                bill.createdAt = moment(new Date(bill.createdAt)).format('DD-MM-YYYY HH:mm a')
                bill.sno = index + 1;
            })
            const aggregates = {
                'Total Bill Amount': Math.round(amounts.totalAmount),
                'Total Payment': Math.round(amounts.totalAmountPaid),
                // 'Total Cash Payment': Math.round(amounts.cash),
                // 'Total Card Payment': Math.round(amounts.card),
                'Total Advance': Math.round(amounts.totalAdvance),
                'Total Due' : Math.round(amounts.totalDue),
                'Total Returns' : Math.round(amounts.totalReturns),
            }
            return ({data:{data: results, aggregates: aggregates}});
        } else {
            return ({data:{data: [], aggregates: {}}});
        }


    } catch (e) {
        console.log(e);
        return ({data:{data: [], aggregates: {}}});
    }
}

function getBillPaymentsReport(url: any, pharmaId: any, startDate: any, endDate: any) {
    return axios.get(`${url}/reports/bill-payments?pharmacyId=${pharmaId}&dateFrom=${startDate.toUpperCase()}&dateTo=${endDate.toUpperCase()}`)
}

const analyticsTableHeader: any = {
    'income-expense': ['S.no', 'Date', 'Description', 'Category', 'Income', 'Expense'],
    'collection-report': ['S.no', 'Bill #', 'Bill Date', 'UHID', 'Patient Name', 'Doctor Name', 'Payment Date', 'Amount', 'Cash', 'Card'],
    'creditors': ['S.no', 'Name', 'UHID/GST No.', 'Type', 'Balance'],
    'debtors': ['S.no', 'Name', 'UHID/GST No.', 'Type', 'Balance'],
    'expiry-drug': ['S.no', 'Drug Name', 'Batch', 'Vendor Name', 'Manufacturer Name', 'Invoice Date', 'Stock', 'Cost Price', 'Selling Price', 'Date of Expiry'],
    'scheduled': ['S.No', 'Bill #', 'Patient Name', 'Bill Date', 'Doctor Name', 'Drug Name', 'Batch No', 'Manufacturer', 'Quantity', 'Expiry Date', 'Signature'],
    'minimum-stock': ['S.No', 'Drug Name', 'Minimum Quantity', 'Actual Quantity'],
    'stock-report': ['S.No', 'Drug Name', 'Manufacturer Name', 'Batch', 'Expiry', 'Purchase Rate', 'MRP', 'Tax %', 'Current Quantity', 'Total Purchase Value', 'Total Sales Value'],
    'stock-history': ['S.no', 'Drug Name', 'Batch', 'Quantity', 'Bill No', 'Customer Name', 'Date'],
    'stock-change-report': ['S.no', 'Drug Name', 'Type', 'Bill No/Invoice No', 'Date', 'Customer Name/Vendor Name', 'Batch', 'Quantity'],
    'purchase-book': ['S.no', 'GRN #', 'Invoice #', 'Vendor Name', 'Invoice Date', 'Total Value', 'Total Tax'],
    'vendor-summary': ['S.no', 'Vendor Name', 'Number of Purchase', 'Total Value', 'Total Paid'],
    'vendor-purchase': ['S.no', 'GRN #', 'Invoice #', 'Vendor Name', 'Invoice Date', 'Total Value', 'Total Tax', 'Payment Mode', 'Total Paid'],
    'purchase-history': ['S.no', 'GRN #', 'Invoice #', 'Vendor Name', 'Invoice Date','Purchase Date', 'Medicine Name', 'Quantity', 'free Quantity', 'Expiry Date', 'Batch', 'Purchase Value', 'Selling Price'],
    'purchases': ['S.no', 'GRN No', 'GST No', 'Vendor Name', 'Invoice Number', 'Invoice Date', 'Invoice Value', 'HSN', 'Drug Name', 'Purchase Date', 'Taxable Value', 'CGST %', 'CGST Amount', 'SGST %', 'SGST Amount', 'Total'],
    'bills': ['S.no', 'Date', 'UHID', 'Customer Name', 'Bill No', 'Pre-Tax Value', 'Tax Exempt', '5% Pre-Tax Bill Value', '5% Val', '2.5% CGST', '2.5% SGST', '12% Pre-Tax Bill Value', '12% Val', '6% CGST', '6% SGST', '18% Pre-Tax Bill Value', '18% Val', '9% CGST', '9% SGST', 'Total'],
    'ip-report-summary' : ['S.no', 'Patient Name', 'UHID', 'IP Number', 'Total Amount', 'Total Payment', 'Total Returns'],
    'ip-report' : ['S.no', 'Bill #', 'Name', 'Doctor Name', 'Bill Type', 'Bill Date', 'Amount', 'Cash', 'Card', 'Total Paid'],
    'bill-payments': ['S.no', 'Bill #', 'Bill Date', 'UHID', 'Patient Name', 'Doctor Name', 'Payment Date', 'Bill Amount', 'Cash', 'Card', 'Total Paid'],
};

const analyticsTableValues: any = {
    'income-expense': ['sno', 'createdAt', 'description', 'transaction', 'income', 'expense'],
    'collection-report': ['sno', 'billNo', 'billDate', 'uhid', 'patientName', 'doctorName', 'paymentDate', 'amount', 'cash', 'card'],
    'creditors': ['sno', 'name', 'id', 'type', 'balance'],
    'debtors': ['sno', 'name', 'id', 'type', 'balance'],
    'expiry-drug': ['sno', 'name', 'batch', 'vendor', 'manufacturer', 'invoiceDate', 'stock', 'costPrice', 'sellingPrice', 'dateOfExpiry'],
    'scheduled': ['sno', 'billNo', 'patientName', 'billDate', 'doctorName', 'drugName', 'batchNo', 'manufacturer', 'quantity', 'expiryDate', 'signature'],
    'minimum-stock': ['sno', 'drugName', 'minimumQuantity', 'actualQuantity'],
    'stock-report': ['sno', 'drugName', 'manufacturerName', 'batch', 'expiry', 'purchaseRate', 'mrp', 'taxPercent', 'stock', 'totalPurchaseValue', 'totalSalesValue'],
    'stock-history': ['sno', 'drugName', 'batch', 'quantity', 'billNo', 'customerName', 'date'],
    'stock-change-report': ['sno', 'drugName', 'type', 'billInvNo', 'date', 'customerVendorName', 'batch', 'quantity'],
    'purchase-book': ['sno', 'grnNo', 'invoiceNo', 'vendorName', 'invoiceDate', 'totalValue', 'totalTax'],
    'vendor-summary': ['sno', 'vendorName', 'purchaseCount', 'totalValue', 'totalPaid'],
    'vendor-purchase': ['sno', 'grnNo', 'invoiceNo', 'vendorName', 'invoiceDate', 'totalPurchaseValue', 'totalTax', 'paymentMode', 'totalPaid'],
    'purchase-history': ['sno', 'grnNo', 'invoiceNo', 'vendorName', 'invoiceDate','purchaseDate', 'medicineName', 'quantity', 'freeQuantity', 'expiry', 'batch', 'purchaseValue', 'sellingPrice'],
    'purchases': ['sno', 'grnNo', 'gstNo', 'vendorName', 'invoiceNumber', 'invoiceDate', 'invoiceValue', 'hsnCode', 'drugName', 'purchaseDate', 'taxableValue', 'cgstPercentage', 'cgstAmount', 'sgstPercentage', 'sgstAmount', 'totalValue'],
    'bills': ['sno', 'date', 'uhid', 'name', 'billNo', 'preTaxValue', 'taxExempt', 'billValueFive', 'taxFive', 'taxFiveSplit', 'taxFiveSplit', 'billValueTwelve', 'taxTwelve', 'taxTwelveSplit', 'taxTwelveSplit', 'billValueEighteen', 'taxEighteen', 'taxEighteenSplit', 'taxEighteenSplit', 'totalAmount'],
    'ip-report-summary': ['sno', 'customerName', 'uhid', 'ipNumber', 'totalAmount', 'totalPayment', 'returnAmount'],
    'ip-report': ['sno', 'billNo', 'name', 'doctorName', 'type', 'createdAt', 'totalAmount', 'cash', 'card', 'totalPayment'],
    'bill-payments': ['sno', 'billNo', 'billDate', 'uhid', 'patientName', 'doctorName', 'paymentDate', 'amount', 'cash', 'card', 'totalPaid'],
};

const analyticsReportHeader: any = {
    'income-expense': 'Income Expense Report',
    'collection-report': 'Collection Report',
    'creditors': 'Creditors Report',
    'debtors': 'Debtors Report',
    'expiry-drug': 'Expiry Drug Report',
    'scheduled': 'Scheduled Report',
    'minimum-stock': 'Minimum Stock Report',
    'stock-report': 'Stock Report',
    'stock-history': 'Stock History',
    'stock-change-report': 'Stock Change Report',
    'purchase-book': 'Purchase Book Report',
    'vendor-summary': 'Vendor Summary Report',
    'vendor-purchase': 'Vendor Purchase Report',
    'purchase-history': 'Purchase History Report',
    'purchases': 'Purchase Report',
    'bills': 'Bill Report',
    'ip-report-summary': 'IP Report Summary',
    'ip-report': 'IP Report',
    'bill-payments': 'Bill Payments',
};

function getProcessedData(reportSubType: any, reportData: any) {
    switch (reportSubType) {
        case 'income-expense':
            return getIncomeExpenseData(reportData);
        case 'collection-report':
            return getCollectionReportData(reportData);
        case 'creditors':
            return getCreditorsData(reportData);
        case 'debtors':
            return getDebtorsData(reportData);
        case 'expiry-drug':
            return getExpiryDrugData(reportData);
        case 'scheduled':
            return getScheduledData(reportData);
        case 'minimum-stock':
            return getMinimumStocksData(reportData);
        case 'stock-report':
            return getStockReportData(reportData);
        case 'stock-history':
            return getStockHistoryData(reportData);
        case 'stock-change-report':
            return getStockChangeReportData(reportData);
        case 'purchase-book':
            return getPurchaseBookData(reportData);
        case 'vendor-summary':
            return getVendorSummaryData(reportData);
        case 'vendor-purchase':
            return getVendorPurchaseSummaryData(reportData);
        case 'purchase-history':
            return getPurchaseHistoryData(reportData);
        case 'purchases':
            return getPurchaseReportData(reportData);
        case 'bills':
            return getBillReportData(reportData);
        case 'ip-report-summary':
            return getIpBillSummary(reportData);
        case 'ip-report':
            return getIpReportData(reportData);
        case 'bill-payments':
            return getBillPaymentsReportData(reportData);
        default:
            return getIncomeExpenseData(reportData);
    }
}

const getTransactionType = (report: any) => {
    let type: string = "Sales";
    if (report.transaction === 'return') {
        if (report.type === 'bill') {
            type = "Bill Return"
        } else if (report.type === 'purchase') {
            type = "Purchase Return"
        }
    } else if (report.type === 'bill') {
        type = "Sales"
    } else if (report.type === 'purchase') {
        type = "Purchase"
    }
    return type
}

function getIncomeExpenseData(reportData: any) {
    let totalSalesReturn: number = 0, totalPurchaseReturn: number = 0, totalExpense: number = 0,
        totalIncome: number = 0;
    let records = [];

    for (const [index, datum] of reportData.data.entries()) {
        let obj = {
            sno: index + 1,
            createdAt: moment(datum.createdAt).format('DD-MM-YYYY'),
            description: datum?.description,
            transaction: getTransactionType(datum),
            income: (datum.type === 'bill' && datum.transaction !== 'return') || ((datum.type === 'purchase' && datum.transaction === 'return')) ? (datum.amount ? datum.amount : '-') : '-',
            expense: (datum.type === 'purchase' && datum.transaction !== 'return') || ((datum.type === 'bill' && datum.transaction === 'return')) ? (datum.amount ? datum.amount : '-') : '-',
        }

        records.push(obj);
        totalIncome += (datum.type === 'bill' && datum.transaction !== 'return') ? datum.amount ?? 0 : 0
        totalSalesReturn += (datum.type === 'bill' && datum.transaction === 'return') ? datum.amount ?? 0 : 0
        totalExpense += (datum.type === 'purchase' && datum.transaction !== 'return') ? datum.amount ?? 0 : 0
        totalPurchaseReturn += (datum.type === 'purchase' && datum.transaction === 'return') ? datum.amount ?? 0 : 0
    }

    let report: any = {
        records: records,
        aggregates: {
            'Total Sales': addComma(totalIncome),
            'Total Sales Return': addComma(totalSalesReturn),
            'Total Purchase': addComma(totalExpense),
            'Total Purchase Return': addComma(totalPurchaseReturn),
        }
    }
    return report
}

function getCollectionReportData(reportData: any) {

    let records = [];
    let sNo: number = 0
    let bill: any = {}
    for (const dataKey in reportData.data) {
        bill = reportData.data[dataKey]
        let obj = {
            sno: ++sNo,
            billNo: dataKey.indexOf('RT-NO-') > -1 ? bill.returnNo : bill.billNo,
            patientName: bill.customerName,
            doctorName: bill.doctorName,
            billDate: moment(bill.createdAt).format('DD-MM-YYYY'),
            paymentDate: moment(bill.paymentDate).format('DD-MM-YYYY'),
            amount: dataKey.indexOf('RT-NO-') > -1 ? Number(bill.returnAmount) : Number(bill.totalAmount),
            cash: bill.cash,
            card: bill.card,
            totalpaid: bill.cash + bill.card,
            uhid: bill.uhid || '-',
            date: new Date(bill.paymentDate),
        };
        records.push(obj);
    }

    records.sort((a: any,b: any)=> ((a.date > b.date) ? 1 : -1));
    records.forEach((record: any, index: number) => {
        record.sno = index + 1;
    })

    let {
        cardPayment,
        cashPayment,
        totalAmount,
        totalReturnAmount,
        collectionCash,
        collectionCashReturn,
        collectionCard,
        collectionCardReturn,
        recordedCashPayment,
        recordedCardPayment
    } = reportData.metrics
    cardPayment = cardPayment ?? 0
    cashPayment = cashPayment ?? 0
    totalAmount = totalAmount ?? 0
    totalReturnAmount = totalReturnAmount ?? 0

    let report: any = {
        records: records,
        aggregates: {
            'Total Bill Amount': Math.round(totalAmount),
            'Total Payment': Math.round(collectionCash + collectionCard),
            'Cash payment': Math.round(collectionCash),
            'Card payment': Math.round(collectionCard),
            'Recorded payment': Math.round(recordedCashPayment + recordedCardPayment),
            'Total Credit': Math.round((totalAmount - totalReturnAmount) - (cashPayment + cardPayment)),
            'Total Return Amount': Math.round(totalReturnAmount),
            'Return Payments': Math.round(collectionCashReturn + collectionCardReturn),
            'Cash Register': Math.round(collectionCash + collectionCashReturn + recordedCashPayment),
            'Card Register': Math.round(collectionCard + collectionCardReturn + recordedCardPayment),
            'Total Income': Math.round(totalAmount - totalReturnAmount),
        }
    }
    return report
}

function getCreditorsData(reportData: any) {
    //'creditors' : ['sno','name','type','balance']
    let sno = 0;
    let totalcredit = 0;

    let records = [];
    for (let val in reportData.data) {
        let obj = {
            sno: ++sno,
            name: reportData.data[val].name,
            id: reportData.data[val].id || '-',
            type: reportData.data[val].type,
            balance: addComma(reportData.data[val].balance),
        };
        totalcredit += reportData.data[val].balance
        records.push(obj);
    }
    let report: any = {
        records: records,
        aggregates: {
            'Total Creditors': sno,
            'Total Credit': addComma(totalcredit)
        }
    }
    return report
}

function getDebtorsData(reportData: any) {
    let sno = 0;
    let totaldebit = 0;

    let records = [];
    for (let val in reportData.data) {
        let obj = {
            sno: ++sno,
            name: reportData.data[val].name,
            id: reportData.data[val].id || '-',
            type: reportData.data[val].type,
            balance: addComma(reportData.data[val].balance),
        };
        totaldebit += reportData.data[val].balance
        records.push(obj);
    }
    let report: any = {
        records: records,
        aggregates: {
            'Total Debtors': sno,
            'Total Debit': addComma(totaldebit)
        }
    }
    return report
}

function getExpiryDrugData(reportData: any) {

    let report: any = {
        records: reportData.data,
    }
    return report
}

function getScheduledData(reportData: any) {
    let report: any = {
        records: reportData.data,
    }
    return report
}

function getMinimumStocksData(reportData: any) {

    let report: any = {
        records: reportData.data,
        aggregates: reportData.aggregates
    }
    return report
}

function getStockReportData(reportData: any) {

    let report: any = {
        records: reportData.data,
    }
    return report
}

function getStockHistoryData(reportData: any) {

    let report: any = {
        records: reportData.data,
    }
    return report
}

function getStockChangeReportData(reportData: any) {

    let report: any = {
        records: reportData.data,
    }
    return report
}

function getPurchaseBookData(reportData: any) {
    let totalTax = 0;
    let totalValue = 0;
    let records = [];
    for (let val in reportData.data) {
        let obj = {
            sno: Number(val) + 1,
            grnNo: reportData.data[val].grnNo,
            invoiceNo: reportData.data[val].invoiceNumber,
            vendorName: reportData.data[val].vendor.name,
            invoiceDate: new Date(reportData.data[val].invoiceDate).toLocaleDateString(),
            totalValue: addComma(reportData.data[val].totalPurchaseValue),
            totalTax: addComma(reportData.data[val].totalTax),
        };
        records.push(obj);
        totalTax += reportData.data[val].totalTax ? reportData.data[val].totalTax : 0
        totalValue += reportData.data[val].totalPurchaseValue ? reportData.data[val].totalPurchaseValue : 0
    }
    let report: any = {
        records: records,
        aggregates: {
            'Total Tax': Math.round(totalTax),
            'Total Value': Math.round(totalValue)
        }
    }
    return report
}

function getVendorSummaryData(reportData: any) {
    let results: any = [];
    let vendorReport = {} as any;
    let bill: any = {}
    let totalSummaryValue = 0;
    let totalSummaryPaid = 0;
    for (const dataKey in reportData.data) {
        bill = reportData.data[dataKey]
        if (!vendorReport[bill.vendor.name]) {
            vendorReport[bill.vendor.name] = [];
            vendorReport[bill.vendor.name].push(bill);
        } else {
            vendorReport[bill.vendor.name].push(bill);
        }
    }
    const vendor = Object.keys(vendorReport);
    vendor.forEach((vendor: any, index: any) => {
        const amounts = {
            totalValue: 0,
            totalTax: 0,
            totalPaid: 0
        };
        vendorReport[vendor].forEach((purchaseBill: any) => {
            const purchaseAmount = purchaseBill?.payments.filter((payment: any) => { return payment.isReturn === false })
            amounts.totalPaid += Number(purchaseAmount[0]?.amount || 0)
            amounts.totalTax += Number(purchaseBill.totalTax);
            amounts.totalValue += Number(purchaseBill.totalPurchaseValue);
        });
        results.push({
            sno: Number(index) + 1,
            vendorName: vendor,
            purchaseCount: vendorReport[vendor].length,
            totalValue: addComma(Number(amounts.totalValue).toFixed(0)),
            totalPaid: addComma(Number(amounts.totalPaid).toFixed(0)),
            totalTax: addComma(Number(amounts.totalTax).toFixed(0)),
        })
        totalSummaryValue += Number(amounts.totalValue);
        totalSummaryPaid += Number(amounts.totalPaid);

    });
    let report: any = {
        records: results,
        aggregates: {
            'Total Value': Math.round(totalSummaryValue),
            'Total Paid': Math.round(totalSummaryPaid),
        }
    }
    return report
}

function getVendorPurchaseSummaryData(reportData: any) {
    let report: any = {
        records: reportData.data,
        aggregates: reportData.aggregates
    }
    return report
}


function getPurchaseHistoryData(reportData: any) {
    //'purchase-history' : ['grnNo','invoiceNo','vendorName','purchaseDate','medicineName','quantity','freeQuantity','expiry','batch','purchaseValue','sellingPrice']
    let records = [];
    let sno: number = 0
    for (let val in reportData.data) {
        for (let purchases in reportData.data[val].purchaseItems) {
            let obj = {
                sno: ++sno,
                grnNo: reportData.data[val].grnNo,
                invoiceNo: reportData.data[val].invoiceNumber,
                vendorName: reportData.data[val].vendor.name,
                invoiceDate: new Date(reportData.data[val].invoiceDate).toLocaleDateString(),
                purchaseDate: new Date(reportData.data[val].createdAt).toLocaleDateString(),
                medicineName: reportData.data[val].purchaseItems[purchases].drug?.name || '-',
                quantity: addComma(reportData.data[val].purchaseItems[purchases].purchaseQuantity),
                freeQuantity: reportData.data[val].purchaseItems[purchases].freeQuantity ? reportData.data[val].purchaseItems[purchases].freeQuantity : '-',
                expiry: new Date(reportData.data[val].purchaseItems[purchases].expiry).toLocaleDateString(),
                batch: reportData.data[val].purchaseItems[purchases].batch,
                purchaseValue: addComma(reportData.data[val].purchaseItems[purchases].purchaseRate),
                sellingPrice: addComma(reportData.data[val].purchaseItems[purchases].sellingPrice),
            };
            records.push(obj);
        }
    }
    let report: any = {
        records: records,
    }
    return report;
}

function getPurchaseReportData(reportData: any) {
    let records = [];
    for (let [index, report] of reportData.data?.entries()) {
        let obj = {
            sno: index + 1,
            grnNo: report?.grnNo ?? '-',
            gstNo: report?.gstNo ?? '-',
            vendorName: report?.vendorName ?? '-',
            invoiceNumber: report?.invoiceNumber ?? '-',
            invoiceDate: report?.invoiceDate ? new Date(report?.invoiceDate).toLocaleDateString() : '-',
            invoiceValue: report?.totalPurchaseValue ?? '-',
            hsnCode: report?.hsnCode ?? '-',
            drugName: report?.drugName ?? '-',
            purchaseDate: new Date(report?.createdAt).toLocaleDateString(),
            taxableValue: report?.taxableValue ? Number(report?.taxableValue).toLocaleString('en-IN') : 0,
            cgstPercentage: report?.cgstPercentage ?? 0,
            cgstAmount: report?.cgstAmount ? Number(report?.cgstAmount).toLocaleString('en-IN') : 0,
            sgstPercentage: report?.sgstPercentage ?? 0,
            sgstAmount: report?.sgstAmount ? Number(report?.sgstAmount).toLocaleString('en-IN') : 0,
            totalValue: report?.totalValue ? Number(report?.totalValue).toLocaleString('en-IN') : 0,
        };
        records.push(obj);
    }
    let report: any = {
        records,
    }
    return report;
}

function getBillReportData(reportData: any) {
    let records = [];
    for (let [index, report] of reportData.data?.entries()) {
        let taxExcemptValue = roundAndAddComma((Number(report.total) - ((Number(report.taxFive) + Number(report.taxValueFive)) + (Number(report.taxTwelve) + Number(report.taxValueTwelve)) + (Number(report.taxEighteen) + Number(report.taxValueEighteen)))) ,2)
        let obj = {
            sno: index + 1,
            billNo: report?.billNo ?? '-',
            date: moment(report?.createdAt).format('DD-MM-YYYY'),
            name: report?.name ?? '-',
            preTaxValue: roundAndAddComma(((Number(report.total)) - (Number(report.taxFive) + Number(report.taxTwelve) + Number(report.taxEighteen))), 2),
            taxFive: roundAndAddComma(Number(report.taxFive), 2),
            taxFiveSplit: report?.taxFive ? roundAndAddComma((Number(report?.taxFive) / 2), 2) : 0,
            taxTwelve: roundAndAddComma(Number(report.taxTwelve), 2),
            taxTwelveSplit: report?.taxTwelve ? roundAndAddComma((Number(report?.taxTwelve) / 2), 2) : 0,
            taxEighteen: roundAndAddComma(Number(report.taxEighteen), 2),
            taxEighteenSplit: report?.taxEighteen ? roundAndAddComma((Number(report?.taxEighteen) / 2), 2) : 0,
            totalAmount: roundAndAddComma(Number(report?.total), 2),
            uhid: report.uhid || '-',
            billValueFive: roundAndAddComma(Number(report.taxValueFive), 2),
            billValueTwelve: roundAndAddComma(Number(report.taxValueTwelve), 2),
            billValueEighteen: roundAndAddComma(Number(report.taxValueEighteen), 2),
            taxExempt: Math.abs(Number(taxExcemptValue)) < 1 ? 0 : taxExcemptValue,
        }
        records.push(obj);
    }
    let report: any = {
        records,
    }
    return report;
}

function getIpBillSummary(reportData: any) {
    let report: any = {
        records: reportData.data,
        aggregates: reportData.aggregates
    }
    return report
}

function getIpReportData(reportData: any) {
    let report: any = {
        records: reportData.data,
        aggregates: reportData.aggregates
    }
    return report
}

function getBillPaymentsReportData(reportData: any) {

    let records = [];
    let sNo: number = 0
    let bill: any = {}
    for (const dataKey in reportData.data) {
        bill = reportData.data[dataKey]
        let obj = {
            sno: ++sNo,
            billNo: dataKey.indexOf('RT-NO-') > -1 ? bill.returnNo : bill.billNo || '-',
            patientName: bill.customerName,
            doctorName: bill.doctorName || '-',
            billDate: bill.createdAt ? moment(bill.createdAt).format('DD-MM-YYYY') : '-',
            paymentDate: moment(bill.paymentDate).format('DD-MM-YYYY'),
            amount: dataKey.indexOf('RT-NO-') > -1 ? Number(bill.returnAmount) : Number(bill.totalAmount),
            cash: bill.cash,
            card: bill.card,
            totalPaid: bill.cash + bill.card,
            uhid: bill.uhid || '-',
        };
        records.push(obj);
    }

    records.sort((a: any,b: any)=> ((a.paymentDate > b.paymentDate) ? 1 : -1));
    records.forEach((record: any, index: number) => {
        record.sno = index + 1;
    })

    let {
        cardPayment,
        cashPayment,
        totalAmount,
        totalReturnAmount,
        collectionCash,
        collectionCashReturn,
        collectionCard,
        collectionCardReturn,
        recordedCashPayment,
        recordedCardPayment
    } = reportData.metrics
    cardPayment = cardPayment ?? 0
    cashPayment = cashPayment ?? 0
    totalAmount = totalAmount ?? 0
    totalReturnAmount = totalReturnAmount ?? 0

    let report: any = {
        records: records,
        aggregates: {
            'Total Payment': Math.round(collectionCash + collectionCard + recordedCashPayment + recordedCardPayment),
            'Cash payment': Math.round(collectionCash),
            'Card payment': Math.round(collectionCard),
            'Recorded payment': Math.round(recordedCashPayment + recordedCardPayment),
            'Total Return Amount': Math.round(totalReturnAmount),
            'Return Payments': Math.round(collectionCashReturn + collectionCardReturn),
            'Cash Register': Math.round(collectionCash + collectionCashReturn + recordedCashPayment),
            'Card Register': Math.round(collectionCard + collectionCardReturn + recordedCardPayment),
        }
    }
    return report
}

export default {
    analyticsReportDecider,
    getProcessedData,
    analyticsTableHeader,
    analyticsTableValues,
    analyticsReportHeader
};
