function Printpositivehistorycard(props: any) {
  const {title} = props;
  return <div className="body-container">
    <h6>{title}</h6>
    <div className="div-col-full graybox">
      {props.children}
    </div>
  </div>
}

export default Printpositivehistorycard;
