import * as React from "react";
import {Col, Row} from "react-bootstrap";
import moment from "moment";

const Nameprefix = (patient: any) => {
  if (['miss.', 'mrs.', 'mr.', 'baby.'].some(v => patient.name.toLowerCase().includes(v))) {
    return '';
  }

  if ((patient && patient.age && patient.age.years && patient.age.years <= 3) || (!patient.age!.years && patient.age!.months)) {
    return 'Baby. '
  }

  return patient && patient.gender === 'male' ? 'Mr. ' : patient.gender === 'female' ? 'Ms. ' : '';
}

export function RadiologyReport(props: any) {
  const {report, print} = props;
  const {patientInfo, reportInfo, radiologyInfo} = report;
  const patientObj = patientInfo;

  console.log(report);
  React.useEffect(() => {
    if (print) {
      window.print();
    }
  }, []);

 

  return  <div className="body-container radiology-report">
    <h3 className={'center-text page-header'}>{reportInfo?.modality} Report</h3>
    <br/>
    <Row>
      <Col xs={6}>
        <div className={'header-with-background '}>
          <h6>Patient Information</h6>
        </div>
        <div className={'info'}>
          <table>
            <tr>
              <td>
                <div className={'info-record'}>
                  <div className={'info-label'}>
                    Patient Name
                  </div>
                  <div className={'info-value'}>
                     {patientInfo.name}
                  </div>
                </div>
              </td>
              <td>
                <div className={'info-record'}>
                  <div className={'info-label'}>
                    UHID
                  </div>
                  <div className={'info-value'}>
                    {patientInfo.uhid}
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className={'info-record'}>
                  <div className={'info-label'}>
                    Age {patientInfo.gender ? <> / sex</> : ''}
                  </div>
                  <div className={'info-value'}>
                    {patientObj.age && patientObj.age.years ? <>{patientObj.age.years} Y</> : ''} {patientObj.age && patientObj.age.months ? <>{patientObj.age.months} M</> : ''}
                    {patientObj.gender ? <span style={{ textTransform: 'capitalize' }}> / {patientObj.gender || '-'}</span> : ''}
                  </div>
                </div>
              </td>

            </tr>

          </table>
        </div>
      </Col>
      <Col xs={6}>
        <div className={'header-with-background '}>
          <h6>Medical Team Details</h6>
        </div>
        <div className={'info'}>
          <table>
            <tr>
              <td>
                <div className={'info-record'}>
                  <div className={'info-label'}>
                   Doctor
                  </div>
                  <div className={'info-value'}>
                    {radiologyInfo.assignedTo || '-'}
                  </div>
                </div>
              </td>
              <td>
                <div className={'info-record'}>
                  <div className={'info-label'}>
                    Date of study
                  </div>
                  <div className={'info-value'}>
                    {patientObj.dateOfStudy ? patientObj.dateOfStudy : '-'}
                  </div>

                </div>
              </td>
              {/*<td>*/}
              {/*  <div className={'info-record'}>*/}
              {/*    <div className={'info-label'}>*/}
              {/*      Other Consultants*/}
              {/*    </div>*/}
              {/*    <div className={'info-value'}>*/}
              {/*      {medicalTeam.otherConsultants || '-'}*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</td>*/}
            </tr>
            <tr>

              <td colSpan={2}>
                <div className={'info-record'}>
                  <div className={'info-label'}>
                    Report generation Date & Time
                  </div>
                  <div className={'info-value'}>
                    {report.completed_at ? moment(new Date(report.completed_at)).format('DD-MM-YYYY HH:mm a') : '-'}
                  </div>
                </div>
              </td>
            </tr>

          </table>
        </div>

      </Col>

    </Row>
    <br/>
    <div dangerouslySetInnerHTML={{__html: report.reportInfo.content}} style={{color:'#000'}} className={'radiology-content'}/>

    { radiologyInfo.signatureUrl ? <div className={'radiology-signature'}> <div>Radiologist Signature</div> <br/><img src={radiologyInfo.signatureUrl} alt={'signature url'}/> </div> : null}
    { !radiologyInfo.signatureUrl ? <div className={'radiology-signature'}><br/> <div>Radiologist Signature</div> <br/> </div> : null}

  </div>

}

export default RadiologyReport;
