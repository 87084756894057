import * as React from "react";
import {Table} from "react-bootstrap";

export const CardioVascularPreview = (props: any)=>{
  const {record, updateCb, fieldIndex, recordIndex} = props;

  const {
    heartSounds,
    murmur,
    murmurComment,
    addedSounds,
    addedSoundsComment,
    jvpElevated,
    comments,
  } = record;

  return <div className={'general-examination general-examination-preview'}>
    <div className={'field-row-preview-row'}>
      <table>
        <tr>
          <td>
           <span>Heart sounds : S1 S2</span>
            <br/>
            {heartSounds || '-'}
          </td>
          <td>
           <span>Murmur</span>
            <br/>
            {murmur || '-'}
          </td>
          {murmur.toLowerCase() === 'yes' &&  murmurComment ?
            <td colSpan={2}>
             <span>Murmur</span>
              <br/>
              {murmur || '-'}
            </td>
            : null
          }

        </tr>
        <tr>
          <td>
           <span> Added sounds</span>
            <br/>
            {addedSounds || '-'}
          </td>
          { addedSounds.toLowerCase() === 'yes' && addedSoundsComment ?  <td>
              <span> Added comments</span>
              <br/>
              {addedSoundsComment || '-'}
          </td> :
          null}
          <td>
            <span>JVP: Elevateds</span>
            <br/>
            {jvpElevated || '-'}
          </td>
        </tr>
        <tr>
          <td colSpan={4}>
            <span>Comments</span>
            <br/>
            {comments || '-'}
          </td>
        </tr>
      </table>
    </div>


  </div>

}
