import beWellLogo from "../images/BeWell.svg"
import {useEffect} from 'react';
import PharmaAnalyticsTitleCard from "./pharmaAnalyticsTitleCard"
import reportDecider from './pharmaAnalyticsCommons'
import moment from "moment";

function PharmaAnalyticsTable(props: any) {
  const {reportData,reportSubType,startDate,endDate} = props;
  let report = reportDecider.getProcessedData(reportSubType,reportData);

    return  <div className="body-container">
              <PharmaAnalyticsTitleCard aggregates={report.aggregates} reportSubType={reportSubType}></PharmaAnalyticsTitleCard>
              <div className="pa-date-range">
                <div className="pa-printed-on">Report Printed On   : {new Date().toLocaleString()}</div>
                {(startDate && endDate)?<div className="pa-printed-range">Date Range   : {moment(startDate).format("DD-MMM-YY")} - {moment(endDate).format("DD-MMM-YY")}</div>: null}
              </div>
              <table className={'pa-table'}>
                <thead>
                <tr>
                  {
                    reportDecider.analyticsTableHeader[reportSubType].map((key: string, index: number) => {
                        return <th key={"td-"+index}>{key}</th>
                    })
                  }
                </tr>
                </thead>
                <tbody>
                  {
                    report.records.map(( dat: any, index: number) =>{
                      return <tr key={"tr-"+index}>
                        {
                          reportDecider.analyticsTableValues[reportSubType].map((key: string, index: number) => {
                              return <td key={"td-"+index}>{dat[key]}</td>
                          })
                        }
                      </tr>
                      }
                    )
                  }
                </tbody>
              </table>
            
              <div style={{float: 'right',marginTop: '0.3cm', marginBottom: '0.3cm'}}>Pharmacist Signature</div>
    </div>
  }

  export default PharmaAnalyticsTable;
