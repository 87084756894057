import {Row, Col} from 'react-bootstrap';
import moment from 'moment';

function PrescriptionPatientInfo(props: any) {
  const {patientInfo} = props;
  let patientObj = patientInfo;
  let fullName = patientObj.fullName;
  let address = [];
  if(patientObj.address)
      address.push(patientObj.address)
  if(patientObj.state)
      address.push(patientObj.state)

  let age = patientObj.age && (patientObj.age.years || patientObj.age.months) ? ((patientObj.age.years || '-') + ' Y ' + (patientObj.age.months ? patientObj.age.months + ' M' : ''))  : '-';
  let gender = patientObj.gender;
  let patientId = patientObj.uhid;

  const {visit} = props;
  let visitobj = visit;
  console.log(visitobj)
  let docName = visitobj.docName;
  let date = moment(new Date(visitobj.creation_date)).format('DD-MM-YYYY');

  return  <div className="body-container">
                  <h1> Prescription </h1>
                    <Row>
                       <Col xs={6}>
                         <div className={'flex-container'}>
                           <div>
                             <h6>Patient Information</h6>
                           </div>
                           <div className={'gray-parent graybox'}>
                             <div className={'flex-div'}>
                               <p><div className="label-question">Name </div>: {fullName}</p>
                               <p><div className="label-question">Age </div>: {age}</p>
                             </div>
                             <div className={' flex-div'}>
                                <p><div className="label-question">Gender </div>: {gender}</p>
                                <p><div className="label-question">UHID </div>: {patientId}</p>
                             </div>
                           </div>
                         </div>
                       </Col>
                       <Col xs={6}>
                         <div className={'flex-container'}>
                           <div>
                             <h6>Doctor Information </h6>
                           </div>
                           <div className={'gray-parent graybox'}>
                              <div className={'flex-div '}>
                                <p><div className="label-question">Doctor </div>: {docName}</p>
                                <p><div className="label-question">Date </div>: {date}</p>
                             </div>
                           </div>
                         </div>
                       </Col>
                     </Row>
            </div> 
}
  
export default PrescriptionPatientInfo;