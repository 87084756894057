import React, { useEffect, useState } from 'react';
import axios from "axios";
import './App.css';
import PdfHeader from "./commons/header";
import Pdffooter from "./commons/footer";
import Prescription from "./prescription/prescription";
import PrescriptionPatientInfo from "./prescription/prescriptionPatientInfo";
import ReceiptInfo from "./bills/receiptInfo";
import Summary from "./visitSummary/summary";
import getUrlParams from "./getUrlParams";
import LabReport from './LabReport/LabReport';
import { Container, Spinner } from 'react-bootstrap';
import DischargeSummary from "./DischargeSummary/DischargeSummary";
import RadiologyReport from "./RadiologyReports/RadiologyReport";
import RadiologyReportv2 from "./RadiologyReports/RadiologyReportv2";
import TotalCollectionReport from "./billingAnalytics/TotalCollectionReport";
import DepartmentReport from "./billingAnalytics/DepartmentReport";
import DischargeSummaryv2 from './DischargeSummary/DischargeSummaryv2';
import NalamInfo from './bills/NalamInfo';
import moment from 'moment';
import LabIPReport from './LabReport/LabIPReport';

import { useReactToPrint } from 'react-to-print';
import LabReportv2 from './LabReport/LabReportv2';
import BillingItemizedReport from './billingAnalytics/BillingItemizedReport';


let url = '';
if (window.location.href.indexOf('pdf.bewelldigital.com') > -1) {
  url = 'https://q1lsdba6f6.execute-api.ap-south-1.amazonaws.com/prod';
} else if (window.location.href.indexOf('pdf-dev.bewelldigital.com') > -1) {
  url = 'https://4440i72tm2.execute-api.us-west-2.amazonaws.com/prod';
} else {
  url = 'https://q1lsdba6f6.execute-api.ap-south-1.amazonaws.com/prod';
  // url = 'https://4440i72tm2.execute-api.us-west-2.amazonaws.com/prod';
}

function getHospitalInfo(medicalCenterId: any) {
  return axios.get(`${url}/medicalcenter/` + medicalCenterId)
}

function getPatientInfo(medicalCenterId: any, patientId: string) {
  return axios.get(`${url}/patients/patient/` + medicalCenterId + '/' + patientId)
}

function getVisit(visitId: any, patientId: any) {
  return axios.get(`${url}/visits/visit/` + visitId + '/' + patientId)
}

function getBillInfo(billId: any, patientId: any) {
  return axios.get(`${url}/bills/bill/` + patientId + '/' + billId)
}

function getVisitBills(patientId: any, medicalCenterId: any, visitId: any) {
  return axios.get(`${url}/v2/visit-bills/${medicalCenterId}/${patientId}/${visitId}`)
}

function getWalkInBills(patientId: any, medicalCenterId: any) {
  return axios.get(`${url}/v2/walk-in-bills/${medicalCenterId}/${patientId}`)
}

function getReport(centerId: any, reportId: any) {
  return axios.get(`${url}/radiology-reports/by-report-id/` + centerId + '/' + reportId)
}

function getBillingTotalCollectionReport(centerId: any, startDate: any, endDate: any, visitType: any) {
  let queryUrl = `/bills/v2/filter-bills-by-date-v1/${centerId}/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`;

  if (visitType) {
    queryUrl = `/bills/v2/filter-bills-by-type-v1/${centerId}/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}/${visitType}`;
  }
  return axios.get(`${url}${queryUrl}`);
}

function getBillingDepartmentReport(centerId: any, startDate: any, endDate: any) {
  return axios.get(`${url}/bills/v2/filter-bills-by-date-v1/${centerId}/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`);
}

function getBillingItemizedReport(centerId: any, startDate: any, endDate: any, visitFilter: any) {
  const hasVisitType = visitFilter.indexOf('all') === -1;
  let queryUrl = `/bills/v2/filter-bills-by-date-v1/${centerId}/${startDate.toISOString()}/${endDate.toISOString()}`;
  if(hasVisitType){
    queryUrl = `/bills/v2/filter-bills-by-type-v1/${centerId}/${startDate.toISOString()}/${endDate.toISOString()}/${visitFilter}`;
  }
  return axios.get(url + queryUrl);
}

function getlabOrder(orderId: any, patientId: any) {
  return axios.get(`${url}/labtests/labtest/` + patientId + '/' + orderId)
}

function getAllIPLabOrder(patientId: any) {
  return axios.post(`${url}/labtests/filter/patients`, [patientId]);
}

function getDoctors(centerId: any) {
  return axios.get(`${url}/doctors/${centerId}`)
}

function getMedicalHistory(centerId: any, patientId: any) {
  return axios.get(`${url}/history/patient/${centerId}/${patientId}`)
}


function getbillHeaderInfo(medicalCenterInfo: any, reportType: string) {
  // in case discharge summary with images is passed on
  if (reportType?.indexOf('discharge-summary') > -1 || reportType?.indexOf('discharge-summary-v2') > -1) {
    reportType = 'discharge-summary';
  }

  if (reportType?.indexOf('investigations') > -1) {
    reportType = 'lab';
  }

  let reportHeaders = medicalCenterInfo[0]?.reportHeaders;
  let footerMessage = medicalCenterInfo[0]?.footerText;
  let matchedHeaderInfo = reportHeaders?.find((element: any) => element.type === reportType);
  matchedHeaderInfo = matchedHeaderInfo ? matchedHeaderInfo : medicalCenterInfo[0];
  matchedHeaderInfo.footerText = footerMessage;
  return matchedHeaderInfo;
}

function MedicalCenter() {
  let myRef = React.useRef(null);
  const [state, setState] = useState({
    patientInfo: null as any,
    billInfo: null as any,
    visitBills: null as any,
    medicalCenterInfo: null as any,
    centerid: null as any,
    visitInfo: null as any,
    reportType: null as any,
    labtest: null as any,
    headerInfo: null as any,
    doctors: null as any,
    medicalHistory: null as any,
    letterhead: null as any,
    printtype: null as any
  } as any);

  const [loading, setLoading] = useState(true);

  const handlePrint = useReactToPrint({
    content: () => myRef.current,
  });




  useEffect(() => {
    const params = getUrlParams(window.location.href);
    const { centerid, patientid, billid, visitid, visitbillid, allwalkinbills, labtestid, reporttype, startdate, enddate, visittype, header, radreportid, print, letterhead, radiologyv2, instanceid, authorized, radreportingshare, sharedpdf, risbill, departmentfilter, visitfilter } = params;
    let printtype = centerid === '633af9ce-582d-46fe-b867-38a7e3e5ad1b' || centerid === '5f18a526-a5b7-4380-9486-621b5382ba60' || centerid === '6a62f109-40a7-4596-b6f1-86e4b5082f71';
    let labReportType = centerid === '633af9ce-582d-46fe-b867-38a7e3e5ad1b' || centerid === '6a62f109-40a7-4596-b6f1-86e4b5082f71';
    (async () => {
      const promises = [];
      if (centerid) {
        promises.push(await getHospitalInfo(centerid));
        promises.push(await getDoctors((centerid)));

      }
      if (patientid) {
        promises.push(await getPatientInfo(centerid, patientid));
        promises.push(await getMedicalHistory(centerid, patientid));
      }

      (Promise.all(promises)).then(async (response) => {
        const [medicalCenterResponse, doctors, patientInfoResponse, medicalHistory] = response;
        const medicalCenterInfo = medicalCenterResponse.data;
        const patientInfo = patientInfoResponse?.data;
        let visitInfo: any = null;
        let billInfo: any = null;
        let visitBills: any = null;
        let walkInBills: any = null;
        let labTest: any = null;
        let headerInfo: any = null;
        let radiologyReport: any = null;
        let billingTotalCollectionReport: any = null;
        let billingDepartmentReport: any = null;
        let billingItemizedReport: any = null;
        let alliplaborders: any = null;
        let letterhead = null;

        if (visitid) {
          const visitResponse = await getVisit(visitid, patientid);
          visitInfo = visitResponse.data;
        }

        if (billid) {
          const billResponse = await getBillInfo(billid, patientid);
          billInfo = billResponse.data;
        }

        if (allwalkinbills) {
          const getBills = await getWalkInBills(patientid, centerid);
          walkInBills = getBills.data;
        }

        if (visitbillid) {
          const getBills = await getVisitBills(patientid, centerid, visitbillid);
          visitBills = getBills.data;
        }

        if (labtestid) {
          //  b1718b80-af62-4667-a3cf-09b193e10d13
          const labReponse = await getlabOrder(labtestid, patientid);
          labTest = labReponse.data;

          if (!labTest.isInvestigations) {
            labTest.tests.forEach((lab: any) => {
              lab.fields = lab.fields.filter((field: any) => field.selected);
            });
          }
        }
        if (radreportid || radiologyv2) {
          radiologyReport = radiologyv2 ? await axios.get(`${url}/radiology-reporting-v2/` + centerid + '/' + instanceid) : await getReport(centerid, radreportid);
          try {
            letterhead = medicalCenterInfo[0]?.radiologyLetterHeads?.find((letterHead: any) => letterHead.isDefault)?.id;
          } catch (e) {
            console.log(e);
          }

        }

        if (reporttype === 'analytics-billing-total') {
          billingTotalCollectionReport = await getBillingTotalCollectionReport(centerid, startdate, enddate, visittype);
          console.log(billingTotalCollectionReport);
          console.log('###');
        }

        if (reporttype === 'analytics-billing-department') {
          billingDepartmentReport = await getBillingDepartmentReport(centerid, startdate, enddate);
          console.log(billingDepartmentReport);
          console.log('###');
        }

        if (reporttype === 'analytics-billing-itemized') {
          billingItemizedReport = await getBillingItemizedReport(centerid, new Date(startdate), new Date(enddate), visitfilter);
          console.log(billingItemizedReport);
          console.log('###');
        }

        if (reporttype === 'alliplaborders') {
          const alliplaborders = await getAllIPLabOrder(patientid);
          labTest = alliplaborders.data.labtests[0].Items
        }
        ////header attribute is used here to decide if it should load default header rather empty header
        headerInfo = getbillHeaderInfo(medicalCenterInfo, header ? "" : reporttype);


        setState(Object.assign({}, state, {
          letterhead,
          patientInfo,
          centerid,
          medicalCenterInfo,
          visitInfo,
          billInfo,
          visitBills,
          walkInBills,
          reportType: reporttype,
          startDate: startdate,
          endDate: enddate,
          visitType: visittype,
          labTest,
          headerInfo,
          doctors: doctors?.data,
          medicalHistory: medicalHistory?.data,
          radiologyReport: radiologyReport?.data?.reverse(),
          billingTotalCollectionReport: billingTotalCollectionReport?.data,
          billingDepartmentReport: billingDepartmentReport?.data,
          billingItemizedReport: billingItemizedReport?.data,
          print,
          printtype,
          radiologyv2,
          instanceid,
          authorized,
          radreportingshare,
          sharedpdf,
          risbill,
          labReportType,
          departmentfilter,
          visitfilter
        }));
        setLoading(false);
      });
    })();
  }, [])

  // useEffect(() => {
  //   handlePrint();
  // }, [state.radreportingshare])



  if (loading) {
    return <div style={{ height: '200px', width: '100%', marginTop: '100px', textAlign: 'center', fontSize: '13px' }}><Spinner animation="border" role="status"></Spinner><br /> <br /> {'Please wait ...'}</div>
  }

  const { medicalCenterInfo, centerid, letterhead, patientInfo, visitInfo, billInfo, visitBills, walkInBills, reportType, startDate, endDate, visitType, labTest, headerInfo, medicalHistory, doctors, radiologyReport, billingTotalCollectionReport, billingDepartmentReport, billingItemizedReport, print, printtype, radiologyv2, authorized, radreportingshare, sharedpdf, risbill, labReportType, departmentfilter, visitfilter } = state;
  let headerHeight = '32mm';
  let headerBottom = '1.5cm';

  try {
    if (letterhead) {
      let config = medicalCenterInfo[0]?.radiologyLetterHeads?.find((item: any) => item.id === letterhead);
      if (config.top) {
        headerHeight = config.top;
      } else if (sharedpdf) {
        headerHeight = '0.5cm';
      }

      if (config.bottom ) {
        headerBottom  = config.bottom;
      } else if (sharedpdf) {
        headerBottom = '0.5cm';
      }

    }
  } catch(e) {
    console.log(e);
  } 
  
  return (
    <div ref={myRef}>
      {
        radiologyv2>=0 && !radreportingshare ? <style>{` @page { 
    margin-top:  ${headerHeight};
    margin-bottom: ${headerBottom};}`}</style> : radiologyv2 >=0 && radreportingshare ? <style>{` 
    @page { 
      
    margin-top: 0mm;
  }

  @media print {
    .footer-image-radiology {
      display: block;
    }
  }

  .rad-patient-info > tr > td > span {
    font-size: 13pt!important;
    }

     thead {
  display: table-header-group;
  position: abolsoute;
    top: 0;
    height: ${headerHeight}
}

  tfoot {
  display: table-footer-group;
  position: fixed;
    // bottom: 10px;
     bottom: 5px 
}

   `}</style> : null
      }
      <table className="App" >
        <PdfHeader radreportingshare={radreportingshare} letterhead={letterhead} letterHead={letterhead ? medicalCenterInfo[0]?.radiologyLetterHeads?.find((item: any) => item.id === letterhead) : {}} radiologyReport={radiologyReport} hospitalInfo={medicalCenterInfo} patientInfo={patientInfo} headerInfo={headerInfo} report={radiologyReport} radiologyv2={Number(radiologyv2)} />
        <tbody>
          <tr>
            <td className="outer-table">
              {patientInfo && visitInfo && reportType === 'prescription' ? <PrescriptionPatientInfo visit={visitInfo} patientInfo={patientInfo} /> : null}
              {patientInfo && visitInfo && (reportType === 'discharge-summary' || reportType === 'discharge-summary-with-image') ? <DischargeSummary headerInfo={headerInfo} reportType={reportType} visit={visitInfo} patientInfo={patientInfo} doctors={doctors} /> : null}
              {patientInfo && visitInfo && (reportType === 'discharge-summary-v2') ? <DischargeSummaryv2 headerInfo={headerInfo} reportType={reportType} visit={visitInfo} patientInfo={patientInfo} doctors={doctors} /> : null}
              {patientInfo && visitInfo && reportType === 'visit-summary' ? <Summary visit={visitInfo} patientInfo={patientInfo} medicalHistory={medicalHistory} doctors={doctors} /> : null}
              {visitInfo && (reportType === 'prescription') ? <Prescription visit={visitInfo} /> : null}
              {reportType === 'analytics-billing-total' ? <TotalCollectionReport medicalCenterId={centerid} startDate={startDate} endDate={endDate} visitType={visitType} report={billingTotalCollectionReport} print={print} /> : null}
              {reportType === 'analytics-billing-department' ? <DepartmentReport medicalCenterInfo={medicalCenterInfo} startDate={startDate} endDate={endDate} report={billingDepartmentReport} print={print} departmentfilter={departmentfilter?.split('%20').join(' ')} /> : null}
              {reportType === 'analytics-billing-itemized' ? <BillingItemizedReport medicalCenterInfo={medicalCenterInfo} startDate={startDate} endDate={endDate} report={billingItemizedReport} print={print} departmentfilter={departmentfilter?.split('%20').join(' ')} /> : null}
              {patientInfo && billInfo && reportType === 'bill' && printtype ? <NalamInfo patientInfo={patientInfo} billInfo={billInfo} medicalCenterInfo={medicalCenterInfo} /> : null}
              {patientInfo && billInfo && reportType === 'bill' && !printtype ? <ReceiptInfo patientInfo={patientInfo} billInfo={billInfo} medicalCenterInfo={medicalCenterInfo} risBill={risbill}/> : null}
              {patientInfo && visitBills && reportType === 'bill' && printtype ? <NalamInfo patientInfo={patientInfo} billInfo={visitBills} medicalCenterInfo={medicalCenterInfo} /> : null}
              {patientInfo && visitBills && reportType === 'bill' && !printtype ? <ReceiptInfo patientInfo={patientInfo} billInfo={visitBills} medicalCenterInfo={medicalCenterInfo} risBill={risbill}/> : null}
              {patientInfo && walkInBills && reportType === 'bill' && printtype ? <NalamInfo patientInfo={patientInfo} billInfo={walkInBills} medicalCenterInfo={medicalCenterInfo} /> : null}
              {patientInfo && walkInBills && reportType === 'bill' && !printtype ? <ReceiptInfo patientInfo={patientInfo} billInfo={walkInBills} medicalCenterInfo={medicalCenterInfo} risBill={risbill}/> : null}
              {patientInfo && labTest && labReportType && (reportType === 'lab' || reportType === 'investigations') ? <LabReportv2 patientInfo={patientInfo} labTest={labTest} emptyHeader={headerInfo?.emptyheader} /> : null }
              {patientInfo && labTest && !labReportType && (reportType === 'lab' || reportType === 'investigations') ? <LabReport patientInfo={patientInfo} labTest={labTest} emptyHeader={headerInfo?.emptyheader} /> : null }
              {patientInfo && labTest && (reportType === 'lab' || reportType === 'alliplaborders') ? <LabIPReport patientInfo={patientInfo} labTest={labTest} emptyHeader={headerInfo?.emptyheader} /> : null}
              {radiologyReport && !radiologyv2 ? <RadiologyReport report={radiologyReport} print={print} /> : null}
              {radiologyReport && radiologyv2 ? <RadiologyReportv2 letterHead={letterhead ? medicalCenterInfo[0]?.radiologyLetterHeads?.find((item: any) => item.id === letterhead) : {}} radreportingshare={radreportingshare} centerid={centerid} report={radiologyReport} print={print} authorized={radiologyReport[radiologyv2]?.authorized} radiologyv2={Number(radiologyv2)} /> : null}
            </td>
          </tr>
        </tbody>
        <Pdffooter radreportingshare={radreportingshare} letterhead={letterhead} print={print} radiologyv2={radiologyv2} radiologyReport={radiologyReport} headerInfo={headerInfo} reportType={reportType} />
      </table>
      {
        medicalCenterInfo[0]?.radiologyLetterHeads?.find((item: any) => item.id === letterhead)?.disclaimerImg && radreportingshare ? <div style={{ pageBreakBefore: 'always', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src={medicalCenterInfo[0]?.radiologyLetterHeads?.find((item: any) => item.id === letterhead)?.disclaimerImg} alt={'disclaimer img'} /></div> : null
      }
    </div>


  );
}

export default MedicalCenter;