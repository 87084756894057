const PrescriptionForm = [
  {label: "Tablet", type: 'decimal', value: 'tablet', unit: 'Nos'},
  {label: "Capsule", type: 'whole', value: 'capsule', unit: 'Nos'},
  {label: "Syrup", type: 'decimal', value: 'decimal', unit: 'ML'},
  {label: "Dry Syrup", type: 'decimal', value: 'dry-syrup', unit: 'ML'},
  {label: "Injection", type: 'decimal', value: 'injection', unit: 'ML'},
  {label: "Vial", type: 'whole', value: 'vial'},
  {label: "Ampoule", type: 'whole', value: 'ampoule', unit: 'ML'},
  {label: "Pre-filled", type: 'whole', value: 'pre-filled'},
  {label: "Cream", type: 'tick', value: 'cream'},
  {label: "Lotion", type: 'tick', value: 'lotion'},
  {label: "Ointment", type: 'tick', value: 'ointment'},
  {label: "Gel", type: 'tick', value: 'gel'},
  {label: "Spray", type: 'tick', value: 'spray'},
  {label: "Drops", type: 'decimal', value: 'drop',  unit: 'ML'},
  {label: "Dusting powder", type: 'tick', value: 'dusting-powder'},
];

function Prescription(props: any) {
    const {visit, currentMedications = null, fromSummary = false} = props;
    // let visitobj = visit;
    let records =   visit.records;
    // comes from discharge summary;
    const isCurrentMedication = currentMedications && currentMedications.length;
    let prescriptions:any = fromSummary ? currentMedications : [];

    if (!fromSummary) {
      const prescriptionRecord =  records.find((record: any) => record.id == "manage-prescriptions");
      prescriptions = prescriptionRecord && prescriptionRecord.items ? prescriptionRecord.items : [];
    }

    return  <div className= {fromSummary ? '' : "body-container"}>
                        {fromSummary ? null :<h6>Prescription</h6>}
                        <table style={{borderCollapse:'collapse', marginTop:'2%'}}> 
                            <tbody>
                              <th style={{border:'none', backgroundColor:'#FFFFFF'}}>Medicine</th>
                              <th style={{border:'none', backgroundColor:'#FFFFFF'}}>Dosage</th>
                              <th style={{border:'none', backgroundColor:'#FFFFFF'}}>Timing - Freq. - Duration</th>
                            
                            {
                                prescriptions.map((prescription:any, index: number) => {
                                  const tabletType = PrescriptionForm.find((form) =>  form.value === (prescription.type ? prescription.type : ''));
                                  return <>
                                    <tr key={index} style={{borderBottom:'1px solid black'}}>
                                      <td style={{border:'none'}}>{index + 1}) <span style={{marginLeft:'10px'}} className={'full-caps'}>{prescription.name}</span></td>
                                      <td style={{border:'none'}}>{prescription.morning || '0' } - {prescription.afternoon || '0'} - {prescription.evening || '0'} - {prescription.night || '0'}  </td>
                                      <td style={{border:'none'}}>{prescription.intake}  -  {prescription.days} Days</td>
                                  </tr>
                                    {prescription.commentSelected || prescription.comments ?  <tr><td colSpan={7}>{prescription.comments}</td></tr> : null}

                                  </>
                                })
                            }
                        </tbody>
                        </table>
                        {fromSummary ? null :
                        <div style={{marginTop:'5%', display:'flex', flexDirection:'row', justifyContent:'end'}}>{visit.docName}</div> } 
            </div>
  }

export default Prescription;
