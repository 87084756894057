import * as React from "react";

export const GeneralexaminationPreview = (props: any) => {
    const { record, updateCb, fieldIndex, recordIndex, examination, op } = props;

    const {
        buildBodyWeight,
        buildHeight,
        buildBodyType,
        comfortableAtRest,
        comfortableAtRestComments,
        levelsOfConsciousness,
        mentalStatus,
        temperature,
        pallor,
        icterus,
        cyanosis,
        cynosisType,
        clubbing,
        generalizedLymphadenopathy,
        pedalEdema,
        pedalEdemaType1,
        pedalEdemaType2,
        thyroid,
        thyroidComments,
        comments,
    } = record;

    const AddtdOnlyFilledTd = (props: { value: any, title: string, colspan?: number }) => {
        const { title, value, colspan = 1 } = props;
        return value ? <td  colSpan={colspan} style={{border: 'none'}}><span style={{fontSize: '14px'}}>{title}<br/>{value}</span></td> : null
    }

    let showTitle = true;

    if (!buildBodyWeight && !buildHeight && !buildBodyType && !comfortableAtRest
        && !comfortableAtRestComments && !levelsOfConsciousness && !mentalStatus
        && !temperature && !pallor && !icterus && !cyanosis && !cynosisType && !clubbing
        && !generalizedLymphadenopathy && !pedalEdema && !pedalEdemaType1
        && !pedalEdemaType2 && !thyroid && !thyroidComments &&
        !comments) {

        showTitle = false;
    }

    return <div className={'general-examination general-examination-preview'} >
        <div className={'field-row-preview-row'}>
            <br />

            <table style={{background: 'inherit'}}>

                {op ? null : <tr >
                    <AddtdOnlyFilledTd title={'Nourishment'} value={buildBodyWeight} />
                    <AddtdOnlyFilledTd title={'Height'} value={buildHeight} />
                    <AddtdOnlyFilledTd title={'Body Build'} value={buildBodyType} />
                    <AddtdOnlyFilledTd title={'Comfortable at rest'} value={comfortableAtRest} />
                </tr>}
                {comfortableAtRest?.toLowerCase() === 'no' && comfortableAtRestComments ? <tr >
                    <AddtdOnlyFilledTd title={'Reason for not comfortable at rest'} value={comfortableAtRestComments} colspan={4} />
                </tr> : null}
                {op ? null : <tr >
                    <AddtdOnlyFilledTd title={'Levels of Consciousness'} value={levelsOfConsciousness} />
                    <AddtdOnlyFilledTd title={'Mental status'} value={mentalStatus} />
                    <AddtdOnlyFilledTd title={'Temperature'} value={temperature} />
                    <AddtdOnlyFilledTd title={'Pallor'} value={pallor} />
                </tr>}
                <tr >
                    {op ? <AddtdOnlyFilledTd title={'Pallor'} value={pallor} /> : null}
                    <AddtdOnlyFilledTd title={'Icterus'} value={icterus} />
                    <AddtdOnlyFilledTd title={'Cyanosis'} value={cyanosis} />
                    <AddtdOnlyFilledTd title={'Cyanosis type'} value={cynosisType} />
                    <AddtdOnlyFilledTd title={'Clubbing'} value={clubbing} />
                </tr>
                <tr >
                    <AddtdOnlyFilledTd title={'Generalized Lymphadenopathy'} value={generalizedLymphadenopathy} />
                    <AddtdOnlyFilledTd title={'Pedal edema'} value={pedalEdema} />
                    <AddtdOnlyFilledTd title={'Thyroid - goitre'} value={thyroid} />
                    <AddtdOnlyFilledTd title={'Thyroid - comments'} value={thyroidComments} />
                </tr>
                {pedalEdema?.toLowerCase() === 'yes' ? <tr >
                    <AddtdOnlyFilledTd title={'Pedal edema - Yes'} value={pedalEdemaType1} colspan={2} />
                    <AddtdOnlyFilledTd title={'Pedal edema - Yes'} value={pedalEdemaType2} colspan={2} />
                </tr> : null}

                {comments ? <tr><AddtdOnlyFilledTd title={'comments'} value={comments} colspan={4} /></tr> : null}

            </table>
        </div>
    </div>
}
