import beWellLogo from "../images/BeWell.svg"
import { useEffect } from 'react';


function Pdffooter(props: any) {
  const { headerInfo, reportType, radiologyReport, print, letterhead, radiologyv2, radreportingshare } = props;
  let footerHeight = 100;

  let empty = headerInfo?.emptyheader
  if (empty && headerInfo.footerHeight) {
    footerHeight = headerInfo.footerHeight;
  }
  let footerText = headerInfo?.footerText;
  useEffect(() => {

    if (empty && !radreportingshare && !radiologyReport) {
      window.print();
    }
  }, [])

  if (letterhead) {
    const letterHederIndex = headerInfo.radiologyLetterHeads?.findIndex((item: any) => item.id === letterhead);
    if (letterHederIndex > -1) {

      if (radreportingshare && headerInfo.radiologyLetterHeads[letterHederIndex]?.footerMessage) {
        return <tfoot> <tr><th className="outer-table"><div className="empty-footer" dangerouslySetInnerHTML={{ __html: headerInfo.radiologyLetterHeads[letterHederIndex]?.footerMessage }} style={{ height: headerInfo.radiologyLetterHeads[letterHederIndex].bottom }} />  
          
        
         </th></tr></tfoot>
      } 

      if (radreportingshare && headerInfo.radiologyLetterHeads[letterHederIndex]?.footerImg) {
        return <tfoot> <tr><th className="outer-table"><img className={'footer-image-radiology'} style={{width: '100%'}} src={headerInfo.radiologyLetterHeads[letterHederIndex]?.footerImg} alt={'footer-msg'}/>

        </th></tr></tfoot>
      }
      return <> <tfoot>
        <tr>
          <th className="outer-table"><div className="empty-footer" style={{ height: headerInfo.radiologyLetterHeads[letterHederIndex].bottom }} /> </th></tr></tfoot></>
    }

  }

  return <> <tfoot>
    <tr>
      <th className="outer-table">
        {empty ? null :
          <div className="footer">
            <div className="footer-message">{footerText}
            </div>
            <div className="footer-doctor">
              {/*Authorised Signature*/}
            </div>
            <div className="footer-bewell">
              <div style={{ float: "left" }}>
                <img onLoad={() => reportType === 'discharge-summary-with-image' || reportType === 'discharge-summary-v2' || radiologyv2 ? null : window.print()} src={beWellLogo} height="40px" />
              </div>
              <div>
                Powered by<h6 style={{ lineHeight: "20px" }}>Bewell Digital, Inc</h6>
              </div>
            </div>
          </div>
        }
      </th>
    </tr>
  </tfoot>
    {reportType === 'discharge-summary-with-image' || reportType === 'discharge-summary-v2' ? <a href="#" className="float" onClick={() => {
      const res = window.confirm('Make sure all images are loaded before printing');
      if (res) {
        window.print()
      }

    }}>
      <i className="fa fa-print my-float" />
    </a> : null}
  </>
}

export default Pdffooter;
