
import {Row, Col} from 'react-bootstrap';
import * as React from "react";
import getUrlParams from "../getUrlParams";
import {addComma, reduceArrayAdd, validateNumber, AddDrPrefix, handleSelectedInvoiceConsolidated,  calculateBalance, NC, getBillTotalAmount} from './utils';
import {GenerateTableBody, GenerateTableHeader} from "./TableHelper";
import moment from "moment";

const getAllInvoices = (listOfBills:any) => {
  const invoices = [] as any;
  listOfBills.forEach((bill: any) => {
    bill.invoices?.forEach((invoice: any) => {
      invoices.push(invoice);
    });
  });
  return invoices;
}

function ReceiptInfo(props: any) {
    const {invoiceindex, filter} = getUrlParams(window.location.href);
    const {patientInfo, medicalCenterInfo, billInfo, aggregateInfo, risBill} = props;
    let patientObj = patientInfo;
    let fullName = patientObj.fullName;
    let age = patientObj.age && patientObj.age.years || patientObj.age.months ? ( (patientObj.age.years || '0') + ' Y ' + (patientObj.age.months ?  patientObj.age.months +' M' : '' ))  : '-';
    let gender = patientObj.gender?.toUpperCase();
    let patientId = patientObj.uhid?.toUpperCase();
    let phone = patientObj.phone;
    let date = (filter === 'consolidated-itemized' || filter === 'consolidated-bills') ? moment(new Date()).format('DD/MM/YYYY') : moment(new Date(billInfo.updated_date?billInfo.updated_date:billInfo.creation_date)).format('DD/MM/YYYY');
    let invoices = (filter === 'consolidated-itemized' || filter === 'consolidated-bills') ? getAllInvoices(billInfo) : Number(invoiceindex) > -1 ?  [billInfo.invoices[Number(invoiceindex)]] :   billInfo.invoices
    let serialNumber = 0;
    let totalCost = 0;
    let grandTotal = 0;
    let [totalPaid, totalTax, grandCost, totalDiscount, totalDiscountPercentage, cashPayment, cardPayment, netAmount, roundOff] = getBillTotalAmount(invoices);

    return  <div className="body-container discharge-summary" >
      <h1 className={'center-text page-header'}>Invoice</h1>
      <div className={''} style={{padding: '10px', border: '1px solid #d5d5d5', borderRadius: '0px'}}>
        <Row>
          <Col xs={7}>
            <div className={'flex-container'}>
              <div>
                <h6>Patient Information</h6>
              </div>
              <div className={'gray-parent graybox'}>
                <div className={'flex-div'}>
                  <p><div className="label-question">Name </div><div>: {fullName}</div></p>
                  <p><div className="label-question">UHID </div>: {patientId}</p>
                  <p><div className="label-question">Age & Gender </div>&nbsp; : {age || '-'} & {gender || '-'}</p>
                  {!Array.isArray(billInfo) && billInfo.admissionDate  ? <p>  <div className="label-question"> Admission Date {billInfo.admissionTime ? <> & time </> : null} </div> &nbsp; : { moment((billInfo.admissionDate)).format('DD MMM YYYY')} {billInfo.admissionTime ? <> & {billInfo.admissionTime} </> : null}</p> : null}
                  {!Array.isArray(billInfo) && billInfo.dischargeDate  ? <p>  <div className="label-question"> Discharge Date {billInfo.dischargeTime ? <> & time </> : null} </div> &nbsp; : { moment((billInfo.dischargeDate)).format('DD MMM YYYY')} {billInfo.dischargeTime ? <> & {billInfo.dischargeTime} </> : null}</p> : null}
                </div>
              </div>
            </div>
          </Col>
          <Col xs={5}>
            <div className={'flex-container'}>
              <div>
                <h6>Bill Details</h6>
              </div>
              <div className={'gray-parent graybox'}>
                <div className={'flex-div '}>
                  {!Array.isArray(billInfo) ? <p><div className="label-question">Bill No. </div><div> { risBill === 'true' ?  ( billInfo?.billNum ? billInfo?.billNum : billInfo?.billId )  : (billInfo.billNo ? billInfo.billNo : billInfo.billId) }</div></p> : null}
                  <p><div className="label-question">Bill Dt. </div>: {date}</p>
                  <p><div className="label-question">Doctor Name</div>: {AddDrPrefix(invoices[0].doctorName || billInfo?.doctorName) || '-'}</p>
                  {medicalCenterInfo[0].location?.city ?  <p><div className="label-question">Place of supply </div>: {medicalCenterInfo[0].placeOfSupply || medicalCenterInfo[0].location?.city}</p> : null}
                  {medicalCenterInfo[0]?.gst ? <p><div className="label-question">GST No</div>: {medicalCenterInfo[0]?.gst || '-'}</p> : null}
                  {medicalCenterInfo[0]?.panNumber ? <p><div className="label-question">PAN</div>: {medicalCenterInfo[0]?.panNumber|| '-'}</p> : null}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      { !invoiceindex  ?
        // Consolidated invoice
        <>
          <div style={{marginTop: '0.5cm'}}>
            <table className={'pharma-bill-table'}>
              {
                <GenerateTableHeader filter={filter}/>
              }
              <tbody>
              {
                handleSelectedInvoiceConsolidated(billInfo, filter).map((invoice: any, index: number) => {
                  // totalTax = invoice.totalTax;
                  // grandTotal = invoice.grandTotal;
                  // totalCost = invoice.totalCost;
                  // totalDiscount = invoice.totalDiscount;

                  return <GenerateTableBody invoice={invoice} index={index} filter={filter}/>
                })
              }
              </tbody>
            </table>
          </div>


        </>
        :
        <>
          <div style={{marginTop: '0.5cm'}}>
            <table className={'pharma-bill-table'}>
              <thead>
              <tr>
                <th>S.no</th>
                <th>Date</th>
                <th>Item</th>
                <th>Qty</th>
                <th>Amount &#8377;</th>
                <th>Discount &#8377;</th>
                <th>Tax %</th>
                <th className={'align-right'}>Total &#8377;</th>
              </tr>
              </thead>
              <tbody>
              {
                invoices.map((invoice:any) => {
                  let items = invoice.items;
                  totalPaid = (totalPaid + Number(invoice.totalPaid));
                  let date = moment(new Date(invoice.date)).format('DD/MM/YYYY');

                  if (invoice.isOnlyPayment) {
                    totalPaid += validateNumber(invoice.totalPaid);

                    serialNumber +=1;
                    return <tr>
                      <td>{serialNumber}</td>
                      <td style={{minWidth: '100px'}}>{date}</td>
                      <td>Payment/Advance</td>
                      <td>-</td>
                      <td>{validateNumber(invoice.totalPaid)}</td>
                      <td>-</td>
                      <td>-</td>
                      <td>{validateNumber(invoice.totalPaid)}</td>
                    </tr>
                  } else {
                    return items.map((item:any, index: number) =>{
                      const cost = item.cost * item.unit;
                      const discountedCost = Number(item.discount);
                      const tax = (item.tax * (item.cost-discountedCost)) / 100;
                      totalCost += validateNumber(cost);
                      // totalTax += validateNumber(tax);
                      // totalDiscount += validateNumber(discountedCost);
                      grandTotal += (((validateNumber(cost) - validateNumber(discountedCost)) + validateNumber(tax)));
                      serialNumber +=1;
                      return <tr>
                        <td>{serialNumber}</td>
                        <td style={{minWidth: '100px'}}>{((date))}</td>
                        <td>{item.name}</td>
                        <td>{item.unit}</td>
                        <td>{item.cost}</td>
                        <td>{item.discount || '-'}</td>
                        <td>{item.tax || '-'}</td>
                        <td className={'align-right'}>{addComma(((cost - (isNaN(discountedCost) ? 0 : discountedCost)) + (isNaN(tax) ? 0 : tax)))}</td>
                      </tr>
                    })
                  }

                })
              }
              </tbody>
            </table>
          </div>


        </>
      }
      <div style={{marginTop: '0.5cm', padding: '10px'}}>
        <Row>
          <Col xs={7}>
            { <Row>
              <Col xs={8}>
                <div className={'color-blue font-16'}>
                  Bill Details
                </div>
                <div style={{marginTop: '0.2cm'}}>
                  <Row>
                    <Col xs={8} className={' billing-table-td'}><span className="  ">Total Amount</span></Col>
                    <Col xs={4} className={' billing-table-td'}>&#8377; {validateNumber(grandCost)}</Col>
                  </Row>
                  <Row>
                    <Col xs={8} className={' billing-table-td'}><span className="  ">Discount</span></Col>
                    <Col xs={4} className={' billing-table-td'}> {(totalDiscount) ? <>&#8377; {validateNumber(totalDiscount)}</> : '-'} </Col>
                  </Row>
                  <Row>
                    <Col xs={8} className={' billing-table-td'}><span className="  ">Tax</span></Col>
                    <Col xs={4} className={' billing-table-td'}> {(totalTax) ? <>&#8377; {validateNumber(totalTax || 0)}</> : '-'} </Col>
                  </Row>

                  <Row>
                    <Col xs={8} className={' billing-table-td'}><span className="  ">Net Amount </span></Col>
                    <Col xs={4} className={' billing-table-td'}>&#8377; {validateNumber(netAmount).toFixed(2)}</Col>
                  </Row>
                  {/* <Row>
                    <Col xs={8} className={' billing-table-td'}><span className="  ">Round off </span></Col>
                    <Col xs={4} className={' billing-table-td'}>&#8377; {validateNumber(roundOff).toFixed(2)}</Col>
                  </Row> */}

                </div>
              </Col>
            </Row> }
          </Col>
          <Col xs={5}>
            <div className={'color-blue font-16'}>
              Payment Details
            </div>
            <div style={{marginTop: '0.2cm'}}>
              {
                cashPayment > 0 ?
                  <Row>
                    <Col xs={8} className={' billing-table-td'}><span className="  ">Total Paid (Cash) </span></Col>
                    <Col xs={4} className={' billing-table-td'}> &#8377; {NC(cashPayment) || '-'}</Col>
                  </Row>
                  : null
              }
              {
                cardPayment > 0  ?
                  <Row>
                    <Col xs={8} className={' billing-table-td'}><span className="  ">Total Paid (Card) </span></Col>
                    <Col xs={4} className={' billing-table-td'}> &#8377; {NC(cardPayment) || '-'}</Col>
                  </Row>
                  : null
              }

              <Row>
                <Col xs={12}><div className={'border-bottom-divider'} style={{height: '10px'}}/></Col>
              </Row>
              {
                cashPayment > 0 || cardPayment > 0 ?
                  <Row style={{marginTop: '0.1cm'}}>
                    <Col xs={8} className={' billing-table-td'}><span className="  ">Grand Total </span></Col>
                    <Col xs={4} className={' billing-table-td'}> &#8377; { (NC(cardPayment + cashPayment)) || '-'}</Col>
                  </Row>
                  :
                  null
              }
              {

                <Row style={{marginTop: '0.1cm'}}>
                  <Col xs={8} className={' billing-table-td'}><span className="  ">Balance </span></Col>
                  <Col xs={4} className={' billing-table-td'}> &#8377; { calculateBalance(invoices) || []}</Col>
                </Row>

              }


            </div>
          </Col>
        </Row>
      </div>
    </div>
  }

  export default ReceiptInfo;
