import * as React from "react";
import {Col, Row} from "react-bootstrap";
import moment from "moment";
import { addComma, AddDrPrefix, validateNumber } from "../bills/utils";

const Nameprefix = (patient: any) => {
  if (['miss.', 'mrs.', 'mr.', 'baby.'].some(v => patient.name.toLowerCase().includes(v))) {
    return '';
  }

  if ((patient && patient.age && patient.age.years && patient.age.years <= 3) || (!patient.age!.years && patient.age!.months)) {
    return 'Baby. '
  }

  return patient && patient.gender === 'male' ? 'Mr. ' : patient.gender === 'female' ? 'Ms. ' : '';
}

export function DepartmentReport(props: any) {
  const {medicalCenterInfo, startDate, endDate, report, print, departmentfilter} = props;
  const {patientInfo, reportInfo, radiologyInfo} = report;
  const patientObj = patientInfo;
  const [reports, setReport] = React.useState([] as any);
  const [billingGroups, setBillingGroups] = React.useState({} as any);
  const [billCount, setCount] = React.useState({
    cash: 0,
    card: 0,
    cheque: 0,
    rtgs: 0,
    totalDiscount: 0,
    totalDue: 0,
    totalAdvance: 0,
    totalAmount: 0,
    totalReturns: 0,
  } as any);

  console.log(report);
  React.useEffect(() => {
    const types: any = [];
        const patiensList = [];
        let uniqueCount = 0;
        let billingGroups: any = {};
    const amounts = {
        cash: 0,
        card: 0,
        cheque: 0,
        credit: 0,
        rtgs: 0,
        totalDiscount: 0,
        totalDue: 0,
        totalAdvance: 0,
        totalAmount: 0,
        totalPaid: 0,
        totalReturns: 0,
      };

      setCount({ ...amounts });
      const results: any = [];

    let filteredItems = [] as any;
    if (departmentfilter && departmentfilter !== 'all department') {
      report.forEach((bill: any, index: number) => {

        bill.invoices.forEach((invoice: any) => {
          const filteredList = invoice.items.filter((item:any)=>{
            return item.type?.toUpperCase() === departmentfilter?.toUpperCase()
          })
          if(filteredList.length){
            invoice.items = filteredList;
            filteredItems.push(bill);
          }
        })
      })
    } else{
      filteredItems = report;
    }
    filteredItems.forEach((bill: any, index: number) => {
        bill.invoices.forEach((invoice: any, iIndex: number) => {
            const localTypes: any = [];
            let totalAmount: any = 0;
            let cost = 0, discountedCost = 0, tax = 0, totalCost = 0, paid = 0;
            paid = Number(invoice.totalPaid || 0);

            amounts.cash += validateNumber(invoice.cash);
            amounts.card += validateNumber(invoice.card);
            amounts.credit += validateNumber(invoice.totalAmount);


            invoice.items.forEach((item: any) => {
              const type = item.type || 'Others';
              if (!item.discount) {
                item.discount = 0;
              } 

              if (!item.tax) {
                item.tax = 0;
              }
              cost = item.cost * item.unit;
              discountedCost = Number(item.discount);
              tax = (item.tax * (cost - discountedCost)) / 100;
              totalCost = (((cost - discountedCost) + tax));
              totalAmount += totalCost;
              types.push(item.type?.toUpperCase() || 'Others');
                if (!localTypes[item.type || 'Others']) {
                  if (!localTypes[item.type?.toUpperCase() || 'Others']) {
                    localTypes[item.type || 'Others'] = 0;
                    localTypes[item.type?.toUpperCase() || 'Others'] = 0;
                  }
                }

              // Item level department aggregation Lame right .. wish dynamo was not lame 
              localTypes[item.type?.toUpperCase() || 'Others'] += (((cost - discountedCost) + tax)) > 0 ? (((cost - discountedCost) + tax)) : 0;
              // Hospital level aggregation
            });

            totalAmount -= Number(invoice.totalBillDiscount || 0);

            amounts.totalAmount += totalAmount;
            amounts.totalDiscount += discountedCost;
            amounts.totalPaid += paid;
            const credit = validateNumber(invoice.totalPaid) - (validateNumber(invoice.cash) + validateNumber(invoice.card));
            let billObj: any = {
              patientId: bill.patientId,
              id: bill.uhid,
              name: bill.fullName,
              docName: invoice.visitDepartment ? invoice.visitDepartment : AddDrPrefix(invoice.doctorName || bill.doctorName),
              billDate: moment(bill.createdAt).format('DD-MM-YYYY HH:mm'),
              billId: (bill.billNo || bill.billId) + "-" + (iIndex + 1),
              billAmount: totalAmount ? ` ${(totalAmount).toFixed(2)}` : '-',
              amountInNumber: totalAmount || 0,
              paid: invoice.totalPaid ? ` ${validateNumber(invoice.totalPaid).toFixed(2)}` : '-',
              // cash: method === 'Cash' || method === 'Cash & Card' ? validateNumber(invoice.cash).toFixed(2) : '-',
              // card: method === 'Card' || method === 'Cash & Card' ? validateNumber(invoice.card).toFixed(2) : '-',
              // credit: method === 'Credit' && credit > 0 ? credit : '-',
              billUid: bill.billId,
              billIndex: iIndex,
              // others: method !== 'Cash' && method !== 'Credit' &&  method !== 'Card' ? YesText : '-'
            };

            // Grouping dynamically based on department
            try {
              Object.keys((localTypes)).forEach((key: any, index: number) => {
                if (!billingGroups[key]) {
                  billingGroups[key] = 0;
                }
                
                billingGroups[key] += localTypes[key];
                billObj[key] = !localTypes[key] ?'-':localTypes[key].toFixed(2);
              });
            } catch(e) {
              console.log(e);
            }
            

            console.log(billObj);
            results.push(billObj);
          });
      });
      // }


      // Not a great way of doing have to find a better way to do it
      
      setBillingGroups(billingGroups);
      setCount({ ...amounts });
      setReport(results);
    if (print) {
      window.print();
    }
  }, []);

 

  return  <>
    <div className="pa-title-card">
        <div className="pa-report-name">{'Billing Department-level report'}</div>
    </div>
    <div className="pa-stat-card" style={{flexWrap:'wrap'}}>
        {Object.keys(billingGroups).length ?
            <>
            {Object.keys(billingGroups).map((key: string, index: number) => {
          return <div className="ba-stat" key={index}>
            {key?.toUpperCase()}
            <p className="pa-center-text"><span className="pa-stat-number"> {billingGroups[key].toFixed(2)}</span></p>
          </div>
        })}

      </>
      : null}
    </div>
    <div className="pa-date-range">
                <div className="pa-printed-on">Report Printed On   : {new Date().toLocaleString()}</div>
                {(startDate && endDate)?<div className="pa-printed-range" style={{paddingRight:'20px'}}>Date Range   : {startDate} - {endDate}</div>: null}
              </div>
    <div className={'report-stats'} style={{ background: '#FFF', justifyContent: 'center' }}>
    
    </div>
    <br/>
    <br/>
    <div>
    <>
    <div className={'reports-main-container'}>

      <div className={'reports-table-container'}>

      <table className={'pa-table'}>
                <thead>
                <tr>
                  <th key={"td-1"}>S.No</th>
                  <th key={"td-2"}>UHID</th>
                  <th key={"td-3"}>Name</th>
                  <th key={"td-4"}>Doctor Name</th>
                  <th key={"td-5"}>Bill Date</th>
                  <th key={"td-6"}>Bill ID</th>
                  <th key={"td-9"}>Amount</th>
                  <th key={"td-10"}>Paid</th>
                  {Object.keys(billingGroups).map((key: string, index: number) => {
                    return <th key={`td-${10+index}`}>{key}</th> })}
                </tr>
                </thead>
                <tbody>
                  {
                    reports.map(( bill: any, index: number) =>{
                      return <tr key={"tr-"+index}>
                        <td>{index+1}</td>
                        <td>{bill.id}</td>
                        <td>{bill.name}</td>
                        <td>{AddDrPrefix(bill.docName) || '-'}</td>
                        <td>{bill.billDate}</td>
                        <td>{bill.billId}</td>
                        <td>{bill.billAmount}</td>
                        <td>{bill.paid}</td>
                        {Object.keys(billingGroups).map((key: string, index: number) => {
                            return <td key={`td-${10+index}`}>{bill[key] || '-'}</td> })}
                      </tr>
                      }
                    )
                  }
                </tbody>
              </table>
      </div>
    </div>
  </>
  </div> </>

}

export default DepartmentReport;
