import React, {useEffect, useState} from 'react';
import axios from "axios";
import './App.css';

import getUrlParams from "./getUrlParams";
import MedicalCenter from "./medicalCenter";
import Pharmacy from "./pharmacy";
import PharmaSelfConsumption from "./pharmaSelfConsumption";
import WordReport from './wordReport';

function App() {
  const params = getUrlParams(window.location.href);
  const {centerid, pharmaid, reporttype, wordreportid} = params;
  

  if(centerid && wordreportid){
    return <WordReport/>;
  }
  else if (centerid) {
    return <MedicalCenter/>
  }
  else if (pharmaid && reporttype === 'pharma-self-consumption-bill') {
    return <PharmaSelfConsumption/>;
  }
  else if(pharmaid){
    return <Pharmacy />;
  }
  else {
    return null;
  }

}
export default App;
