import * as React from "react";
import packageImage from "../images/package_icon.svg";
const textAlignRight = {

}


export function GenerateTableHeader(props: any) {
  const {filter, tdStyle  = {}} = props;
  let TableHeader = null;

  switch(filter) {
    case 'itemized': {
      TableHeader = <thead>
      <tr>
        <th style={tdStyle}>S.no</th>
        <th style={tdStyle}>Name</th>
        <th style={tdStyle}>Qty</th>
        <th style={tdStyle} className='align-right'>Amount &#8377;</th>
        <th style={tdStyle} className='align-right'>Discount &#8377;</th>
        <th style={tdStyle} className='align-right'>Tax</th>
        <th style={tdStyle} className='align-right'>Total &#8377;</th>
      </tr>
      </thead>
      break;
    }
    case 'consolidated-itemized': {
      TableHeader = <thead>
      <tr>
        <th style={tdStyle}>S.no</th>
        <th style={tdStyle}>Name</th>
        {/* <th style={tdStyle}>Date and Time</th> */}
        {/* <th style={tdStyle}>Discount %</th> */}
        {/* <th style={tdStyle}>Discount &#8377;</th> */}
        {/* <th style={tdStyle}>Tax</th> */}
        <th style={tdStyle} className='align-right'>Amount &#8377;</th>
        {/* <th style={{textAlign: 'right'}}>Paid &#8377;</th> */}
      </tr>
      </thead>
      break;
    }
    case 'transaction-date': {
      //{ dataField: "sno", text: "S. No", sort: true },
      //   { dataField: "date", text: "Transaction Date", sort: true  },
      //   { dataField: "group", text: "Service/group", sort: true  },
      //   { dataField: "amount", text: "Amount", sort: true  },
      //   { dataField: "paid", text: "Paid", sort: true  },
      TableHeader = <thead>
      <tr>
        <th style={tdStyle}>S.no</th>
        <th style={tdStyle}>Transaction Date</th>
        <th style={tdStyle}>Service/group</th>
        <th style={tdStyle}>Amount &#8377;</th>
        {/* <th style={{textAlign: 'right'}}>Paid &#8377;</th> */}
      </tr>
      </thead>
      break;
    }
    case 'service-group': {
      TableHeader = <thead>
      <tr>
        <th style={tdStyle}>S.no</th>
        <th style={tdStyle}>Type</th>
        <th style={tdStyle}>Charge</th>
      </tr>
      </thead>
      break;
    }
    default: {
      TableHeader = <thead>
      <tr>
        <th style={tdStyle}>S.no</th>
        <th style={tdStyle}>Type of Bill</th>
        <th style={tdStyle}>Doctor Name</th>
        <th style={tdStyle}>Bill Id</th>
        <th style={tdStyle}>Date</th>
        <th style={tdStyle}>Amount &#8377;</th>
        <th style={tdStyle}>Paid &#8377;</th>
        <th style={tdStyle}>Mode</th>
        {/* <th style={{textAlign: 'right'}}>Paid &#8377;</th> */}
      </tr>
      </thead>
      break;
    }
  }

  return TableHeader;
}

export function GenerateTableBody(props: any) {
  const {filter, invoice, index, tdStyle = {}} = props;
  let TableBody = null;


  switch(filter) {
    case 'itemized': {
      TableBody = <><tr key={'consolidated-'+index}>
        <td style={tdStyle}>{invoice.sno}</td>
        <td style={tdStyle}>{invoice.name} { invoice.isPackage ? <img style={{marginLeft: '5px'}} src={packageImage} alt={""} height={"15px"}/> : null}</td>
        <td style={tdStyle}>{invoice.quantity}</td>
        <td style={tdStyle} className='align-right'>{invoice.rate}</td>
        <td style={tdStyle} className='align-right'>{invoice.discount || '-'}</td>
        <td style={tdStyle} className='align-right'>{invoice.tax || '-'}</td>
        <td style={tdStyle} className='align-right'>{invoice.amount}</td>
      </tr>
      {
        invoice.isPackage ? <> {
          invoice.packages.map((subItem: any, sIndex: number) => {
            return <tr key={'consolidated-' + index + '-' + sIndex}>
              <td style={tdStyle}>{' '}</td>
              <td colSpan={2} style={{ display:'flex', flexDirection:'row', justifyContent:'space-between', marginLeft:'15px', fontSize: '12px'}}><div>{subItem.name}</div><div>{' '}</div><div>{'Rs. ' + subItem.cost}</div></td>
            </tr>
          })
        } </> : null
      }
      </>
      break;
    }
    case 'consolidated-itemized': {
      TableBody = <><tr key={'consolidated-'+index}>
        <td style={tdStyle}>{invoice.sno}</td>
        <td style={tdStyle}>{invoice.name} { invoice.isPackage ? <img style={{marginLeft: '5px'}} src={packageImage} alt={""} height={"15px"}/> : null}</td>
        {/* <td style={tdStyle}>{invoice.dateTime}</td> */}
        {/* <td style={tdStyle}>{invoice.totalDiscount}</td> */}
        {/* <td style={tdStyle}>{invoice.discount}</td> */}
        {/* <td style={tdStyle}>{invoice.tax}</td> */}
        <td style={tdStyle} className='align-right'>{invoice.amount}</td>
        {/* <td style={{textAlign: 'right'}}>{invoice.totalPaid || '-'}</td> */}
      </tr>
      {
        invoice.isPackage ? <> {
          invoice.packages.map((subItem: any, sIndex: number) => {
            return <tr key={'consolidated-' + index + '-' + sIndex}>
              <td style={tdStyle}>{' '}</td>
              <td colSpan={2} style={{ display:'flex', flexDirection:'row', justifyContent:'space-between', marginLeft:'15px', fontSize: '12px'}}><div>{subItem.name}</div><div>{' '}</div><div>{'Rs. ' + subItem.cost}</div></td>
            </tr>
          })
        } </> : null
      }
      </>
      break;
    }
    case 'transaction-date':  {
      TableBody = <tr key={'consolidated-'+index}>
        <td style={tdStyle}>{invoice.sno}</td>
        {/* <td style={tdStyle}>{invoice.date}</td> */}
        <td style={tdStyle}>{invoice.group}</td>
        <td style={tdStyle} className='align-right'>{invoice.amount}</td>
        {/* <td style={{textAlign: 'right'}}>{invoice.totalPaid || '-'}</td> */}
      </tr>
      break;
    }
    case 'service-group': {
      TableBody = <tr>
        <td style={tdStyle}>{invoice.sno}</td>
        <td style={tdStyle}>{invoice.type}</td>
        <td style={tdStyle}>{invoice.cost}</td>
      </tr>

      break;
    }
    case 'consolidated-bills': {
      TableBody = <tr key={'consolidated-'+index}>
        <td style={tdStyle}>{invoice.sno}</td>
        <td style={tdStyle}>{invoice.typeOfBill}</td>
        <td style={tdStyle}>{invoice.doctorName}</td>
        <td style={tdStyle}>{invoice.billId}</td>
        <td style={tdStyle}>{invoice.date || '-'}</td>
        <td style={tdStyle}>{invoice.total}</td>
        <td style={tdStyle}>{invoice.payment}</td>
        <td style={tdStyle}>{invoice.paymentMethod}</td>
        {/* <td style={{textAlign: 'right'}}>{invoice.totalPaid}</td> */}
      </tr>
      break;
    }
    default: {
      TableBody = <tr key={'consolidated-'+index}>
        <td style={tdStyle}>{invoice.sno}</td>
        <td style={tdStyle}>{invoice.typeOfBill}</td>
        <td style={tdStyle}>{invoice.doctorName}</td>
        <td style={tdStyle}>{invoice.billId}</td>
        <td style={tdStyle}>{invoice.date || '-'}</td>
        <td style={tdStyle}>{invoice.total}</td>
        <td style={tdStyle}>{invoice.payment}</td>
        <td style={tdStyle}>{invoice.paymentMethod}</td>
        {/* <td style={{textAlign: 'right'}}>{invoice.totalPaid}</td> */}
      </tr>
      break;
    }
  }

  return TableBody;
}
