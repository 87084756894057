import { Row, Col } from 'react-bootstrap';
import Prescription from "../prescription/prescription";
import moment from 'moment';
import Renderhistory from "../renderHistory/Renderhistory";
import { GeneralexaminationPreview } from './Previewcomponents/GeneralexaminationPreview';
import { CardioVascularPreview } from './Previewcomponents/CardiovascularsystemPreview';
import { RespiratorysystemPreview } from './Previewcomponents/RespiratorysystemPreview';
import { AbdomentexaminationPreview } from './Previewcomponents/AbdomentexaminationPreview';
import { CentralNervousSystemPreview } from './Previewcomponents/CentralNervousSystemPreview';
import { ProvisonalDiagnosisPreview } from './Previewcomponents/ProvisonalDiagnosisPreview';


function NewlineText(props: { text: string }) {
  let text = props.text;
  if (!text) {
    return <td>-</td>;
  }

  text = text.replaceAll(/\u21B5/g, '');
  text = text.replaceAll('\n', '')
  return <td><div dangerouslySetInnerHTML={{ __html: text }}></div></td>;
}

const AddtdOnlyFilledTd = (props: { value: any, title: string, colspan?: number }) => {
  const { title, value, colspan = 1 } = props;
  return value ? <td colSpan={colspan} style={{ border: 'none' }}><span style={{ fontSize: '14px' }}>{title}<br />{value}</span></td> : null
}

const Nameprefix = (patient: any) => {


  if (['miss.', 'mrs.', 'mr.', 'baby.'].some(v => patient.fullName.toLowerCase().includes(v))) {
    return '';
  }

  if ((patient && patient.age && patient.age.years && patient.age.years <= 3) || (!patient.age!.years && patient.age!.months)) {
    return 'Baby. '
  }

  return patient && patient.gender === 'male' ? 'Mr. ' : patient.gender === 'female' ? 'Ms. ' : '';
}

function Summary(props: any) {

  const { patientInfo, visit, doctors, medicalHistory } = props;
  let patientObj = patientInfo;
  let fullName = patientObj.fullName;

  // JsBarcode(".barcode").init();
  let address = [];
  if (patientObj.address)
    address.push(patientObj.address)
  if (patientObj.state)
    address.push(patientObj.state)

  let age = patientObj.age && patientObj.age.years || patientObj.age.months ? ((patientObj.age.years || '-') + ' Y ' + (patientObj.age.months ? (patientObj.age.months + ' M') : '')) : '-';
  let gender = patientObj.gender;
  let patientId = patientObj.uhid;
  let doctor = doctors.find((doctor: any) => doctor.name === visit.docName);

  let visitobj = visit;
  let docName = doctor && doctor.name ? doctor.name : visit.docName;
  let records = visitobj.records;
  let department = visitobj.department;
  let prescriptions: any;
  let vitals: any;
  let labOrders: any;
  let clinicalNotes: any;
  let treatmentPlan: any;
  let completedProcedures: any;
  let visitNotes: any;
  let investigationResults: any;
  let nextFollowUp: any;
  let examination: any;
  let inhaleUsage: any;
  let visitDiagnosis: any;
  let date = moment(new Date(visitobj.creation_date)).format('DD-MM-YYYY');


  records.map((record: any) => {
    switch (record.id) {
      case "manage-prescriptions":
        prescriptions = record.items;
        break;
      case "add-vitals":
        vitals = record;
        break;
      case "lab-orders":
        labOrders = record.items;
        break;
      case "add-clinic-notes":
        clinicalNotes = record;
        break;
      case "add-treatment-plan":
        treatmentPlan = record;
        break;
      case "completed-procedures":
        completedProcedures = record.items;
        break;
      case "visit-notes":
        visitNotes = record;
        break;
      case "add_diagnosis":
        visitDiagnosis = record;
        break;
      case "add_inhale_usage":
        inhaleUsage = record;
        break;
      case "add-investigation-results":
        investigationResults = record.comments;
        break;
      case "add-dmo": {
        let CardioDom = null;
        let RespiratoryDom = null;
        examination = record;
        examination?.records?.forEach((eItem: any, rIndex: number) => {
          eItem?.fields?.forEach((item: any) => {
            item.noValueCount = 0;
            item.fields.forEach((field: any, fieldIndex: number) => {
              switch (field.id) {
                case 'local-examination':
                case 'provisional-diagnosis':
                case 'treatment-plan': {
                  const { comments } = field;
                  if (!comments) {
                    item.noValue = true;
                  }

                  item.renderDom = <div key={rIndex}> <ProvisonalDiagnosisPreview op={true} examination={examination} record={field} fieldIndex={fieldIndex} recordIndex={rIndex} /></div>;

                  break;
                }
                case 'general': {
                  const { icterus,
                    cyanosis,
                    cynosisType,
                    clubbing,
                    generalizedLymphadenopathy,
                    pedalEdema,
                    pedalEdemaType1,
                    pedalEdemaType2,
                    thyroid,
                    thyroidComments,
                    comments } = field;

                  if (!icterus && !cyanosis && !clubbing && !generalizedLymphadenopathy && !pedalEdema && !pedalEdemaType1 && !pedalEdemaType2 && !thyroid && !thyroidComments && !comments) {
                    item.noValue = true;
                  }

                  item.renderDom = <div key={rIndex}> <GeneralexaminationPreview op={true} examination={examination} record={field} fieldIndex={fieldIndex} recordIndex={rIndex} /></div>;

                  break;
                }
                case 'cardio':
                case 'respiratory':
                case 'abdomen':
                case 'central-nervous-system': {
                  if (field.id === 'cardio') {
                    const {
                      heartSounds,
                      murmur,
                      murmurComment,
                      addedSounds,
                      addedSoundsComment,
                      jvpElevated,
                      comments,
                    } = field;
                    if (!heartSounds && !murmur && !addedSounds && !jvpElevated && !comments) {
                      field.noValue = true;
                      item.noValueCount += 1;
                    }
                  }
                  if (field.id === 'respiratory') {
                    const {
                      chestWallMovement,
                      chestWallComments,
                      bilateralAirentry,
                      bilateralAirentryComments,
                      breathSound,
                      breathsoundComments,
                      addedSounds,
                      addedSoundsComments,
                      trachealPosition,
                      trachealPositionComments,
                      comments,
                    } = field;
                    if (!chestWallMovement && !bilateralAirentry && !breathSound && !trachealPosition && !comments) {
                      field.noValue = true;
                      item.noValueCount += 1;
                    }
                  }
                  if (field.id === 'abdomen') {
                    const {
                      feel,
                      superficialPalpation,
                      superficialPalpationComments,
                      deepPalpation,
                      deepPalpationComments,
                      hernialOrifices,
                      bowelSounds,
                      bowelSoundsType,
                      abnormalSounds,
                      abnormalSoundsComments,
                      comments,
                    } = field;
                    if (!feel && !superficialPalpation && !deepPalpation && !hernialOrifices && !bowelSounds && !abnormalSounds && !comments) {
                      field.noValue = true;
                      item.noValueCount += 1;
                    }
                  }
                  if (field.id === 'central-nervous-system') {
                    const {
                      comments,
                      centralNervousSystem,
                      centralNervousSystemComments,
                    } = field;
                    if (!centralNervousSystem && !comments) {
                      field.noValue = true;
                      item.noValueCount += 1;
                    }
                  }

                  item.renderDom = null;
                  break;
                }
                default: {
                  item.noValue = true;
                  item.renderDom = null;
                  break;
                }
              }
            });
            if (item.title === 'Systemic examination' && item.noValueCount === 4) {
              item.noValue = true;
              item.renderDom = null;
            }


          })
        })
        break;
      }
      case "add-next-follow-up":
        nextFollowUp = record;
        break;
    }

  });
  

  const hasAllViatals = vitals ? Object.keys(vitals)?.map((key: any) => {
    if (vitals[key]) {
      return "TRUE"
    } else {
      return "";
    }
  }) : [];

  return <div><div className="body-container">
    <h1> {department?.toUpperCase()} Summary </h1>
    <Row>
      <Col xs={6}>
        <div className={'flex-container'}>
          <div>
            <h6>Patient Information</h6>
          </div>
          <div className={'gray-parent graybox'}>
            <table className='doctor-table'>
              <tr>
                <td>
                  Name
                </td>
                <td>:</td>
                <td > {Nameprefix(patientObj)} {fullName} </td>
              </tr>
              {gender ? <tr>
                <td>
                  Gender
                </td>
                <td>:</td>
                <td style={{ textTransform: 'capitalize' }}>  {gender || '-'} </td>
              </tr> : null}
              <tr>
                <td>
                  Age
                </td>
                <td>:</td>
                <td>  {age} </td>
              </tr>
              <tr>
                <td>
                  UHID
                </td>
                <td>:</td>
                <td>  {patientId} </td>
              </tr>
            </table>
          </div>
        </div>
      </Col>
      <Col xs={6}>
        <div className={'flex-container'}>
          <div>
            <h6>Doctor Information </h6>
          </div>
          <div className={'gray-parent graybox'}>
            <table className='doctor-table'>
              <tr>
                <td>
                  Doctor Name
                </td>
                <td>:</td>
                <td >  {docName} </td>
              </tr>
              {doctor && doctor.specialization ? <tr>
                <td>
                  Specialization
                </td>
                <td>:</td>
                <td>  {doctor.specialization} </td>
              </tr> : null}
              <tr>
                <td>
                  Visit Date
                </td>
                <td>:</td>
                <td>  {date} </td>
              </tr>
            </table>
          </div>
        </div>
      </Col>
      <Col xs={12}><h5> </h5></Col>
    </Row>
  </div>
    {clinicalNotes && clinicalNotes.complaints ? <div className="body-container">
      <h6>Chief Complaints</h6>
      <div className="div-col-full graybox">
        {/*<div className="div-col-full">*/}
        <table className='notes-table'>
          <tr>
            <NewlineText text={clinicalNotes.complaints} />
          </tr>
        </table>
      </div>
    </div>
      :
      null
    }

    {clinicalNotes && clinicalNotes.presentIllness ? <div className="body-container">
      <h6>History of presenting illness</h6>
      <div className=" div-col-full graybox">
        {/*<div className="div-col-full">*/}
        <table className='notes-table'>
          <tr>
            <NewlineText text={clinicalNotes.presentIllness} />
          </tr>
        </table>
      </div>
    </div>
      :
      null
    }
    {clinicalNotes && clinicalNotes.historyNotes ? <div className="body-container">
      <h6>Past Medical History</h6>
      <div className="div-col-full graybox">
        {/*<div className="div-col-full">*/}
        <table className='notes-table'>
          <tr>
            <NewlineText text={clinicalNotes.historyNotes} />
          </tr>
        </table>
      </div>
    </div>
      :
      null
    }
    {clinicalNotes && clinicalNotes.surgicalHistory ? <div className="body-container">
      <h6>Surgical History</h6>
      <div className="div-col-full graybox">
        {/*<div className="div-col-full">*/}
        <table className='notes-table'>
          <tr>
            <NewlineText text={clinicalNotes.surgicalHistory} />
          </tr>
        </table>
      </div>
    </div>
      :
      null
    }
    
    {clinicalNotes && ( clinicalNotes.covid || clinicalNotes.influenza || clinicalNotes.pneumococcal ) ?
      <>
        <div className="body-container">
          <h6>Vaccination Status</h6>
          <div className="graybox">
            <div className={'general-examination general-examination-preview'} >
              <div className={'field-row-preview-row'}>
                <br />
                <table style={{ background: 'inherit' }}>
                  <tr >
                   {clinicalNotes?.covid ? <AddtdOnlyFilledTd title={'Covid'} value={clinicalNotes?.covid} /> : null}
                   {clinicalNotes?.influenza ? <AddtdOnlyFilledTd title={'Influenza'} value={clinicalNotes?.influenza} /> : null}
                   {clinicalNotes?.pneumococcal ? <AddtdOnlyFilledTd title={'Pneumococcal'} value={clinicalNotes?.pneumococcal} /> : null}
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
      :
      null
    }

    {clinicalNotes && clinicalNotes.comorbidCondition ? <div className="body-container">
      <h6>Comorbid Condition</h6>
      <div className="div-col-full graybox">
        {/*<div className="div-col-full">*/}
        <table className='notes-table'>
          <tr>
            <NewlineText text={clinicalNotes.comorbidCondition} />
          </tr>
        </table>
      </div>
    </div>
      :
      null
    }
    {clinicalNotes && clinicalNotes.personalNotes ? <div className="body-container">
      <h6>Personal History</h6>
      <div className="div-col-full graybox">
        {/*<div className="div-col-full">*/}
        <table className='notes-table'>
          <tr>
            <NewlineText text={clinicalNotes.personalNotes} />
          </tr>
        </table>
      </div>
    </div>
      :
      null
    }
    {clinicalNotes && clinicalNotes.familyNotes ? <div className="body-container">
      <h6>Family History</h6>
      <div className="div-col-full graybox">
        {/*<div className="div-col-full">*/}
        <table className='notes-table'>
          <tr>
            <NewlineText text={clinicalNotes.familyNotes} />
          </tr>
        </table>
      </div>
    </div>
      :
      null
    }
    {clinicalNotes && clinicalNotes.occupationNotes ? <div className="body-container">
      <h6>Occupation History</h6>
      <div className="div-col-full graybox">
        {/*<div className="div-col-full">*/}
        <table className='notes-table'>
          <tr>
            <NewlineText text={clinicalNotes.occupationNotes} />
          </tr>
        </table>
      </div>
    </div>
      :
      null
    }
    {clinicalNotes && clinicalNotes.physicalExamination ? <div className="body-container">
      <h6>Physical Examination</h6>
      <div className="div-col-full graybox">
        {/*<div className="div-col-full">*/}
        <table className='notes-table'>
          <tr>
            <NewlineText text={clinicalNotes.physicalExamination} />
          </tr>
        </table>
      </div>
    </div>
      :
      null
    }
    {investigationResults ?
      <div className="body-container">
        <h6>Investigation Results</h6>
        <div className={'div-col-full graybox'}>
          <table className='notes-table'>
            <tr>
              <NewlineText text={investigationResults} />
            </tr>
          </table>
        </div>
      </div> : null}
    {medicalHistory ? <Renderhistory medicalHistory={medicalHistory} /> : null}
    {vitals && hasAllViatals?.join('').length ? <div className="body-container">
      <h6>Vitals</h6>
      <div className="div-col graybox text-transform-none">
        <div className="div-col-left">
          {vitals.weight ? <p><span className="label-question-large">Weight</span> : {vitals.weight ? <>&nbsp; {vitals.weight} kg</> : <>&nbsp;  -</>}</p> : null}
          {vitals.height ? <p><span className="label-question-large">Height</span> : {vitals.height ? <>&nbsp; {vitals.height} cm</> : <>&nbsp;  -</>}</p> : null}
          {vitals.weight && vitals.height ? <p><span className="label-question-large">BMI</span> : &nbsp; {((Number(vitals.weight) / (Math.pow(Number(vitals.height), 2))) * 10000).toFixed(1)} </p> : null}
          {vitals.bsa ? <p><span className="label-question-large">BSA</span> : &nbsp; {vitals.bsa} m<sup>2</sup></p> : null}
          {vitals.heartRate ? <p><span className="label-question-large">Heart Rate</span> : {vitals.heartRate ? <>&nbsp; {vitals.heartRate}</> : <>&nbsp;  -</>}</p> : null}
          {vitals.respRate ? <p><span className="label-question-large">Resp rate</span> : {vitals.respRate ? <>&nbsp; {vitals.respRate}</> : <>&nbsp;  -</>}</p> : null}
          {vitals.notes ? <p><span className="label-question-large">Notes</span> : {vitals.notes ? <>&nbsp; {vitals.notes.replaceAll(/\u21B5/g, '<br/>')}</> : <>&nbsp;  -</>}</p> : null}

        </div>
        <div className="div-col-right text-transform-none">
          {vitals.saturation ? <p><span className="label-question-large">SPO2 % </span> : &nbsp; {vitals.saturation}</p> : null}
          {vitals.rupperLSaturation ? <p><span className="label-question-large">Right Upper Limb Saturation</span> : &nbsp; {vitals.rupperLSaturation}%</p> : null}
          {vitals.rlowerLSaturation ? <p><span className="label-question-large">Right Upper Limb Saturation</span> : &nbsp;  {vitals.rlowerLSaturation}%</p> : null}
          {vitals.lupperLSaturation ? <p><span className="label-question-large">Right Upper Limb Saturation</span> : &nbsp; {vitals.lupperLSaturation}%</p> : null}
          {vitals.llowerLSaturation ? <p><span className="label-question-large">Right Lower Limb Saturation</span> : &nbsp;  {vitals.llowerLSaturation}%</p> : null}
          {vitals.temperature ? <p><span className="label-question-large">Temperature </span> : &nbsp;  {vitals.temperature}</p> : null}
          {vitals.bp?.high || vitals.bp?.low ? <p><span className="label-question-large">Bp (high/low)</span> : &nbsp; {vitals.bp?.high || '-'} / {vitals.bp?.low || '-'}</p> : null}
          {vitals.painAssessment ? <p><span className="label-question-large">Pain Assessment</span> : &nbsp; {vitals.painAssessment}</p> : null}
          {/*<p><span className="label-question-large">Blood Sugar</span> : &nbsp; {vitals.bloodSugar}</p>*/}
        </div>
      </div>
    </div> : null}
    {clinicalNotes && (clinicalNotes?.historyNotes) ?
      <div className="body-container">
        <h6>History</h6>
        <div className="div-col graybox">
          <div className="div-col-full">
            <table className='notes-table'>
              {clinicalNotes?.historyNotes ? <tr>
                <NewlineText text={clinicalNotes?.historyNotes} />
              </tr> : null}
            </table>
          </div>
        </div>
      </div> : null}
    {clinicalNotes && (clinicalNotes.diagnosis || clinicalNotes.observations) ?
      <div className="body-container">
        <h6>Clinical Notes & Diagnosis</h6>
        <div className="div-col graybox">
          <div className="div-col-full">
            <table className='notes-table'>
              {clinicalNotes.observation ? <tr>
                <td>
                  <span className="label-question-large">Findings</span> :
                </td>
                <NewlineText text={clinicalNotes.observations} />
              </tr> : null}
              {clinicalNotes.diagnosis ? <tr>
                <NewlineText text={clinicalNotes.diagnosis} />

              </tr> : null}

            </table>
          </div>
        </div>
      </div> : null}

    {
      examination ?
        examination?.records?.map((eItem: any, rIndex: number) =>
          <>
            {eItem.fields.map((item: any, rindex: number) => {
              return item.noValue ? null : <div className="body-container">
                <h6>{item.title}</h6>
                {item.fields.map((field: any, fieldIndex: number) => {
                  let renderThis = item.renderDom;
                  switch (field.id) {
                    case 'cardio': {
                      if (!field.noValue) {
                        renderThis = <div key={fieldIndex}> <span className='systemic-examination-title'>{field.title}</span> <CardioVascularPreview record={field} fieldIndex={fieldIndex} recordIndex={rIndex} /></div>;
                      }
                      break;
                    }
                    case 'respiratory': {
                      if (!field.noValue) {
                        renderThis = <div key={fieldIndex}> <span className='systemic-examination-title'>{field.title}</span> <RespiratorysystemPreview record={field} fieldIndex={fieldIndex} recordIndex={rIndex} /></div>;
                      }
                      break;
                    }
                    case 'abdomen': {
                      if (!field.noValue) {
                        renderThis = <div key={fieldIndex}> <span className='systemic-examination-title'>{field.title}</span> <AbdomentexaminationPreview record={field} fieldIndex={fieldIndex} recordIndex={rIndex} /></div>;
                      }
                      break;
                    }
                    case 'central-nervous-system': {
                      if (!field.noValue) {
                        renderThis = <div key={fieldIndex}> <span className='systemic-examination-title'>{field.title}</span> <CentralNervousSystemPreview record={field} fieldIndex={fieldIndex} recordIndex={rIndex} /></div>;
                      }
                      break;
                    }

                  }
                  return item.noValue ? null : <div className="div-col graybox">{
                    renderThis
                  } </div>
                })}
              </div>

            })}
          </>
        )
        : null
    }
    {visitDiagnosis ? <div className="body-container">
      <h6>Diagnosis</h6>
      <div className="div-col-full graybox">
        <table className='notes-table'>
          <tr>
            <NewlineText text={visitDiagnosis.notes} />
          </tr>
        </table>
      </div>
    </div> : null}


    {treatmentPlan ?
      <div className="body-container">
        <h6>Plan</h6>
        <div className={'div-col-full graybox'}>
          <table className='notes-table'>
            <tr>
              {/*<td>*/}
              {/*  <span className="label-question-large">Treatment plan </span> :*/}
              {/*</td>*/}
              <NewlineText text={treatmentPlan.notes} />
            </tr>
          </table>
        </div>
      </div> : null}
    {completedProcedures ? <div className="body-container">
      <h6>Procedures Completed</h6>
      <table>
        <tr>
          <th>Name</th>
          <th>cost</th>
          <th>Discount %</th>
          <th>total</th>
        </tr>
        {
          completedProcedures.map((procedure: any) => {
            return <tr>
              <td>{procedure.name}</td>
              <td>{procedure.cost}</td>
              <td>{procedure.discount}</td>
              <td>{procedure.cost - (procedure.cost * procedure.discount / 100)}</td>
            </tr>
          })
        }
      </table>
    </div> : null}
    {inhaleUsage && ( inhaleUsage?.deviceTechnique || inhaleUsage?.properInhalation || inhaleUsage?.breathHold || inhaleUsage?.gargling ) ?
      <>
        <div className="body-container">
          <h6>Inhale Usage</h6>
          <div className="graybox">
            <div className={'general-examination general-examination-preview'} >
              <div className={'field-row-preview-row'}>
                <br />
                <table style={{ background: 'inherit' }}>
                  <tr >
                   {inhaleUsage?.deviceTechnique ? <AddtdOnlyFilledTd title={'Device Technique'} value={inhaleUsage?.deviceTechnique} /> : null}
                   {inhaleUsage?.properInhalation ? <AddtdOnlyFilledTd title={'Proper Inhalation'} value={inhaleUsage?.properInhalation} /> : null}
                   {inhaleUsage?.breathHold ? <AddtdOnlyFilledTd title={'Breath Hold'} value={inhaleUsage?.breathHold} /> : null}
                   {inhaleUsage?.gargling ? <AddtdOnlyFilledTd title={'Gargling'} value={inhaleUsage?.gargling} /> : null}

                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
      :
      null
    }
    {prescriptions ? <Prescription visit={visitobj}></Prescription> : null}
    {visitNotes ? <div className="body-container">
      <h6>Visit Notes</h6>
      <div className="div-col-full graybox">
        <table className='notes-table'>
          <tr>
            <NewlineText text={visitNotes.notes} />
          </tr>
        </table>
      </div>
    </div> : null}
    {nextFollowUp && nextFollowUp.date ? <div className="body-container">
      <h6>Next Follow-up</h6>
      <div className="div-col-full graybox">
        <table className='notes-table'>
          <tr>
            <td>Next Followup on  :</td>
            <NewlineText text={moment(new Date(nextFollowUp.date)).format('Do MMMM YYYY').toString()} />
          </tr>
          {nextFollowUp.comments ? <tr>
            <td>comments:</td>
            <NewlineText text={nextFollowUp.comments} />
          </tr> : null}
        </table>
      </div>
    </div> : null}
  </div>
}

export default Summary;
