import * as React from "react";

function PaymentItem(props: any) {
  const {label, value} = props;

  return <div className={'flex-row-space-between'}>
    <div className="label-question-120 flex-row-space-between">
      <span className={'dot-matrix-large-text-bold'}>{label}</span>
      <span>:</span>
    </div>
    <span className={'dot-matrix-large-text-light'}>{value || '-'}</span>
  </div>
}

export default PaymentItem;
